import {call, put} from 'redux-saga/effects';
import * as RNFS from 'react-native-fs';
import {getAppIconEncoded} from 'react-native-multiprocess-preferences';
import {ACTIVE_ENV} from '../configs/active-env';
import {getKioskConfig} from '../mock/intfMock';
import {logd, logi, logw} from 'features/logging';
import Images from '../static/images';
import {isWeb} from 'features/platformSpecific';

// action types
const STORE_KIOSK_APPS = 'STORE_KIOSK_APPS';
const STORE_KIOSK_CONFIG_AVAIL = 'STORE_KIOSK_CONFIG_AVAIL';

const DEFAULT_STATE = {
  kioskApps: [],
  kioskConfigAvailable: false,
  kioskWarningMessage: undefined,
};

// reducer
export default function (state = DEFAULT_STATE, action) {
  switch (action.type) {
    case STORE_KIOSK_APPS:
      return {...state, kioskApps: action.payload};
    case STORE_KIOSK_CONFIG_AVAIL:
      return {...state, kioskConfigAvailable: action.payload.isAvailable, kioskWarningMessage: action.payload.message};
    default:
      return state;
  }
}

// action creators
export const storeKioskAppsAction = apps => ({type: STORE_KIOSK_APPS, payload: apps});
export const storeConfigAvailInfoAction = (isAvailable, message) => ({
  type: STORE_KIOSK_CONFIG_AVAIL,
  payload: {isAvailable, message},
});

// selector
export const getKioskConfigAvailableSelector = state => state.kiosk.kioskConfigAvailable;
export const getKioskWarningMessageSelector = state => state.kiosk.kioskWarningMessage;


// Flows
export function* loadKioskApps() {
  if (isWeb) return;
  if (ACTIVE_ENV.mockKioskFile) {
    const apps = yield call(getAppsFromJson, getKioskConfig());
    yield put(storeKioskAppsAction(apps));
  } else {
    try {
      let jsonAppsFilePath = RNFS.ExternalDirectoryPath;
      jsonAppsFilePath = jsonAppsFilePath.substr(0, jsonAppsFilePath.lastIndexOf('Android')) + 'Download/';
      logi('Looking for kiosk file in ' + jsonAppsFilePath);
      const result = yield call(RNFS.readDir, jsonAppsFilePath);
      const json = result.filter(x => x.isFile()).filter(x => x.name.includes('kiosk_config.json'));
      if (json[0]) {
        logi('Reading kiosk apps from ' + json[0].path);
      } else {
        yield put(storeKioskAppsAction([]));
        yield put(storeConfigAvailInfoAction(false, 'errors.kioskConfigEmpty'));
        return;
      }
      const kioskConfigJson = yield call(RNFS.readFile, json[0].path, 'utf8');
      yield put(storeConfigAvailInfoAction(true, undefined));
      const apps = yield call(getAppsFromJson, JSON.parse(kioskConfigJson.trim()));
      if (apps && apps.length === 0) {
        yield put(storeConfigAvailInfoAction(false, 'errors.kioskConfigEmpty'));
      }
      yield put(storeKioskAppsAction(apps));
    } catch (e) {
      logw('Error reading kiosk file: ', e);
      yield put(storeConfigAvailInfoAction(false, 'errors.kioskConfigMissing'));
      yield put(storeKioskAppsAction([]));
    }
  }
}

async function getAppsFromJson(kioskConfigJson) {
  const result = [];
  if (kioskConfigJson.applications && Array.isArray(kioskConfigJson.applications)) {
    for (const apps of kioskConfigJson.applications) {
      if (apps.emergency_apps && apps.emergency_apps.length) {
        for (const app of apps.emergency_apps) {
          const formatted = await getFormattedApp(app, true);
          result.push(formatted);
        }
      }
      if (apps.normal_apps && apps.normal_apps.length) {
        for (const app of apps.normal_apps) {
          const formatted = await getFormattedApp(app, false);
          result.push(formatted);
        }
      }
    }
  }
  logi(`Loaded definition of ${result.length} kiosk apps`);
  return result;
}

async function getFormattedApp(app, isEmergencyApp) {
  let image = app.url;
  if (app.url && !app.url.startsWith('http')) {
    try {
      const iconURI = await getAppIconEncoded(app.url);
      image = 'data:image/png;base64,' + iconURI;
    } catch (e) {
      logi(`Can't get app icon for ${app.name} using ${app.url}`);
      image = null;
    }
    //logd(`iconURI`, iconURI);
  }
  if (app.web_app) {
    image = Images.browserIcon;
  }
  return {
    key: app.id,
    name: app.name,
    url: app.url,
    image,
    linkEnable: true,
    order: app.order,
    webApp: app.web_app,
    group: app.group,
    isEmergencyApp: isEmergencyApp,
  };
}
