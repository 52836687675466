import {call, select} from 'redux-saga/effects';
import {apiCall} from '../../utils/Oauth';
import {logd, logi, logw} from '../logging';
import {saveValue} from '../sharedPrefs';
// eslint-disable-next-line no-unused-vars
import {extractIdsOfGivenTypeFromDl, logObject} from '../../utils/Utils';
import I18n from 'features/I18n';
import {ACTIVE_ENV} from '../../configs/active-env';
import {getSelectedUserGroup} from '../../flows/auth';
import {showToast} from 'features/platformSpecific';

const INIT_DATA_KEY = 'bbs';
const GET_BBS_INIT_DATA_URL = `${ACTIVE_ENV.gatewayUrl}/opsmgt/v1/initwatch/logics/facilities/`;

function* loadInitData(user, roles) {
  logi('Fetching Bbs init data');
  // extract all data limitation ids from all roles
  const facilityIds = extractIdsOfGivenTypeFromDl(roles, 'Facility');
  const selectedUserGroup = yield select(getSelectedUserGroup);
  const langCode = user.language || 'en';
  if (facilityIds && facilityIds.length && user.login) {
    let url = `${GET_BBS_INIT_DATA_URL}${facilityIds[0]}/users/${user.login}/bbs?language_code=${langCode}`;
    if (selectedUserGroup) {
      url = `${url}&user_group=${selectedUserGroup}`;
    }
    try {
      const headers = {'X-messagename': 'OPSINIT', 'x-direction': 'umv2'};
      const response = yield call(apiCall, {url, headers});
      //logd('Fetched Bbs init data ', logObject(response));
      if (
        response.reportingLocations &&
        response.observationLocations &&
        response.safetyCodesAndCategories &&
        response.safetyConversations &&
        response.reportingLocations.error === undefined &&
        response.observationLocations.error === undefined &&
        response.safetyCodesAndCategories.error === undefined &&
        response.safetyConversations.error === undefined
      ) {
        yield call(saveValue, INIT_DATA_KEY, JSON.stringify(response));
      } else {
        showToast(I18n.t('errors.bbsDataFailed'));
      }
    } catch (e) {
      logw('Error loading Bbs init data', e);
      showToast(I18n.t('errors.bbsDataFailed'));
    }
  } else {
    showToast(I18n.t('errors.bbsDataFailed'));
  }
}

export default {
  label: 'bbs', // in UM
  title: 'Bbs',
  restartKey: 'restart_bbs',
  activeTaskKey: 'active_task_bbs', // {isActive: true/false,...}
  needInitData: true,
  initDataKey: INIT_DATA_KEY,
  urlPath: GET_BBS_INIT_DATA_URL.substring(GET_BBS_INIT_DATA_URL.indexOf('opsmgt') + 6),
  validationMessageKey: 'validation_bbs',
  loadInitData,
};
