import {all} from 'redux-saga/effects';
import {startScanningWatcher} from './flows/scanner';
import {allLoginFlows} from './flows/login';
import {allAuthFlows, showSelectUserGroupScreenWatcher} from './flows/auth';
import {allAppFlows} from './flows/app';
import {favoriteAppsWatcher, runningAppsStatusWatcher} from './flows/apps';
import {prepareWmsSsoFileForSharingWatcher} from './features/wmsSso';
import {umChangeNotificationValuesWatcher} from './flows/notification';
import applicationNotificationFlows from './flows/applicationNotification';

export function* allFlows() {
  // just for login testing
  //yield call(clearUserDataFromRedux);
  //yield call(clearUserDataFromSharedPrefs);

  yield all([
    allAppFlows(),
    allLoginFlows(),
    allAuthFlows(),
    startScanningWatcher(),
    favoriteAppsWatcher(),
    prepareWmsSsoFileForSharingWatcher(),
    showSelectUserGroupScreenWatcher(),
    runningAppsStatusWatcher(),
    umChangeNotificationValuesWatcher(),
    applicationNotificationFlows()
  ]);
}
