import {
  OAUTH_GRANT_TOKEN_URL,
  OAUTH_REVOKE_TOKEN_URL,
  ROOT_DHLLINK,
  UI_ATTRIBUTE_TABLET,
  UI_ATTRIBUTE_WEARABLE,
} from '../config';
import {all, call, put, select, take, fork, takeLatest} from 'redux-saga/effects';
import {
  REST_RESPONSE_BAD_REQUEST,
  REST_RESPONSE_BAD_RESPONSE,
  REST_RESPONSE_CONNECTION_ERROR,
  REST_RESPONSE_OK,
} from '../actions/types';
import {postParamsWithJSONResponseToUM} from './rest';
import {
  clearUserDataFromRedux,
  clearUserDataFromSharedPrefs,
  filterAllAppsByUserApps,
  getAuthType,
  getSelectedUserGroup,
  getUserDetailsFromGqlUm,
  getUserGroups,
  saveIsLoginProcessCompleted,
  saveTokensToSharedPrefs,
  saveUserAppsToSharedPrefs,
  saveUserToSharedPrefs,
  setGroupIsUpdatingAction,
  setIsAllowedToShowBottomBarMenuAction,
  setLogicsEmployeeIdAction,
  setSmartScanCountry,
  setUserGroupsAction,
  setVelocityScanCountry,
  showPrivacyNotificationScreen,
  storeAuthTypeAction,
  storeSelectedUserGroupAction,
  storeTokensAction,
  storeUserAction,
  storeUserAppsAction,
  storeUserPasswordToEncryptedStorage,
  USER_GROUP_CONTINUE_LOGIN,
} from './auth';
import I18n from 'features/I18n';
import {notThatVerboseLog, revokeTokenFromUm} from '../utils/Oauth';
import {
  clearAppsInitDataFromSharedPrefs,
  fetchAndStoreInitData,
  getAppsDetailsFromUm,
  removeWmsFilesIfTheyExist,
  resetAllAppStatuses,
  saveAppsDetailsToSharedPrefs,
  saveFavoritesAppsKeysToSharedPrefs,
  setRestartFlagsToTrue,
  storeAppsDetailsAction,
  storeFavoriteAppsKeysAction,
} from './apps';
import {
  KEY_API_GW_URL,
  KEY_API_GW_URL_CLEAN,
  KEY_BR_SWITCH_SCREEN,
  KEY_BUSINESS_RELEASE,
  KEY_CLIENT_ID,
  KEY_CLIENT_SECRET,
  KEY_IS_ALLOWED_TO_SHOW_BOTTOM_BAR_MENU,
  KEY_LAST_LOGIN,
  KEY_LOCKSCREEN_DATA,
  KEY_LOCKSCREEN_LOCKED,
  KEY_LOGICS_EMPLOYEE_ID,
  KEY_LOGIN,
  KEY_METRICS_PARENT_VERSION,
  KEY_USER_BY_LOGIN_DATA,
  KEY_USER_GROUPS,
  KEY_USER_JSON_RESP,
  loadValue,
  saveValue,
  SELECTED_USER_GROUP_OBJECT,
} from 'features/sharedPrefs';
import {initParentLogging, logd, logi, logw} from 'features/logging';
import {
  checkDeviceRestartedAsync,
  downloadTranslationsForUserLanguage,
  getBusinessReleaseBySelectedGroupId,
  getPersistedUserLanguage,
  leaveEmergencyAction,
  saveIsTabletFromReduxToSharedPrefs,
  setShowSelectGroupModalAction,
  setWasChangePinModalShown,
  storeFolderAction,
  storeIsTabletFromUserSettingOrUsingDetectedValueAction,
  storeShowEmergencyPromptAction,
  updateLanguageWatcher,
} from './app';
import {getAccessTokenSelector, getUserSelector} from 'features/selectors';
import {loadKioskApps} from './kiosk';
import {convertUserAppsFromUmFormatToSharedPrefsFormat} from '../utils/Utils';
import {handleCdmFiles} from 'features/fileTransfer';
import {fetchAndStoreAppSettings, fetchAndStoreWmsSso} from 'features/wmsSso';
import {orderBy} from 'lodash';
import {loadAppSettingsNotificationValues, loadAppSettingsUmChangeNotificationValues} from './notification';
import {randomString} from 'features/security';
import md5 from 'md5';
import * as SendIntentAndroid from 'react-native-send-intent';
import {validate} from 'jsonschema';
import {isWeb, showToast} from 'features/platformSpecific';
import {
  navigationPushAction,
  navigationReplaceAction,
  navigationReloadWithPropsAction,
  navigationResetAction,
} from 'smartops-shared';
import {ACTIVE_ENV} from '../configs/active-env';
import {toast} from 'react-toastify';
import {SMART_SCAN, VELOCITY_SCAN} from 'features/wmsSso';
import {fetch} from '@react-native-community/netinfo';
import {storeScannedValue} from './scanner';
import {switchBRVersion, switchBRVersionToDefault} from '../App';
import {NativeModules} from 'react-native';
import DeviceInfo from 'react-native-device-info';
import {getIMEI} from 'react-native-multiprocess-preferences';
import Metrics from 'features/metrics';
import {appVersion} from '../configs/version';
import {getAttributeValueNames} from 'features/attrValues';
import {clearAllValidationMessages, runAllAppsValidationsAndPersistErrorMessage} from '../features/appStartValidation';
import {
  clearApplicationNotifications,
  loadApplicationsNotifications,
  setStateValidityForApplicationsNotifications,
} from './applicationNotification';

// actions
const LOGIN_ATTEMPT = 'LOGIN_ATTEMPT';
const LOGOUT_ATTEMPT = 'LOGOUT_ATTEMPT';
const STORE__SELECTED_USER_GROUP_OBJECT = 'STORE__SELECTED_USER_GROUP_OBJECT';
const PASSWORD_CHANGE_RESULT = 'PASSWORD_CHANGE_RESULT';
const START_LOCK_SCREEN = 'START_LOCK_SCREEN';

// action creators
export const loginAttemptAction = (login, password, attemptNumber) => ({
  type: LOGIN_ATTEMPT,
  payload: {login, password, attemptNumber},
});
export const reinitializationAttemptAction = selectedGroupId => ({
  type: LOGIN_ATTEMPT,
  payload: {selectedGroupId},
});
export const continueLoginAfterBRChangeAction = isReinit => ({
  type: LOGIN_ATTEMPT,
  payload: {doingBRChange: true, isReinit},
});

export const logoutAttemptAction = optionalMessage => ({
  type: LOGOUT_ATTEMPT,
  payload: optionalMessage,
});
export const storeSelectedUserGroupObjectAction = payload => ({
  type: STORE__SELECTED_USER_GROUP_OBJECT,
  payload,
});
export const passwordChangeResultAction = payload => ({
  type: PASSWORD_CHANGE_RESULT,
  payload,
});
export const startLockScreenAction = optionalMessage => ({
  type: START_LOCK_SCREEN,
  payload: optionalMessage,
});

let glbLockScreenLastOpen;
const REPEATED_LOCKSCREEN_ATTEMPT_IGNORE_MS = 0;

export function* allLoginFlows() {
  yield all([
    takeLatest(LOGIN_ATTEMPT, loginAttemptFlow),
    takeLatest(LOGOUT_ATTEMPT, logoutAttemptFlow),
    takeLatest(START_LOCK_SCREEN, startLockScreenGener),
    takeLatest(STORE__SELECTED_USER_GROUP_OBJECT, storeSelectedUserGroupObjectFlow),
    startLockScreenGener('allLoginFlows'),
  ]);
}

function findIsTabletValueInUserData(userJson) {
  const {authorizations} = userJson;
  let isTablet;
  if (authorizations && authorizations.length) {
    for (const auth of authorizations) {
      if (!auth.role && !auth.application) {
        // so this is user attribute
        if (auth.attributes && auth.attributes.length) {
          for (const attr of auth.attributes) {
            if (attr.attribute && attr.attribute.label && attr.attribute.label === 'ui_layout') {
              switch (attr.value) {
                case UI_ATTRIBUTE_WEARABLE:
                  isTablet = false;
                  break;
                case UI_ATTRIBUTE_TABLET:
                  isTablet = true;
                  break;
                default:
                  logw('Unexpected ui_layout attribute value', attr.value);
              }
            }
          }
        }
      }
    }
  }
  return isTablet;
}

function findIsUserUmAdmin(userJson) {
  const {authorizations} = userJson;
  let isUserAdmin = false;
  if (authorizations && authorizations.length) {
    for (const auth of authorizations) {
      if (auth.role && auth.role.label && auth.role.application) {
        if (auth.role.label === 'user_management_admin' && auth.role.application.label === 'user_management') {
          isUserAdmin = true;
        }
      }
    }
  }
  return isUserAdmin;
}

export function* loginAttemptFlow({
  payload: {login, password, attemptNumber, selectedGroupId, doingBRChange = false, isReinit = false},
}) {
  logi(
    `Login attempt as ${login} , selectedGroupId: ${selectedGroupId}, doingBRChange: ${doingBRChange}, isReinit: ${isReinit}`,
  );
  let errorMessage;
  let isReinitialization = isReinit;
  yield call(clearAllValidationMessages);
  if (login == null && selectedGroupId != null) {
    yield put(setGroupIsUpdatingAction(true));
    isReinitialization = true;
    const user = yield select(getUserSelector);
    login = user.login;
  }
  if (isWeb && !doingBRChange && !isReinitialization) {
    const url = window.location.toString();
    const defaultBR = url.substring(url.lastIndexOf('parent') + 7).split('/')[0];
    logi(`saving KEY_BUSINESS_RELEASE defaultBR: ${defaultBR}`);
    yield call(saveValue, KEY_BUSINESS_RELEASE, defaultBR);
  }
  try {
    let tokenResponse = {};
    if (!doingBRChange) {
      // Try to load existing translations
      const language = yield call(getPersistedUserLanguage);
      const updateLanguageRes = yield call(updateLanguageWatcher, {payload: language});
      logi(`existing language loaded: ${language} updateLanguageRes 1 ${updateLanguageRes}`);

      tokenResponse = isReinitialization ? {} : yield call(grantTokensUsingLoginAndPassword, login, password);
    }
    let userJson, isUserUmAdmin, isPinOrPwdAuth;
    if (doingBRChange || isReinitialization || tokenResponse.result === REST_RESPONSE_OK) {
      if (tokenResponse.result === REST_RESPONSE_OK) {
        yield call(saveValue, KEY_LOGIN, login); //Save login for weblock
        let deviceData = {};
        if (!isWeb) {
          const manufacturer = yield call(DeviceInfo.getManufacturer);
          const imeiRaw = yield getIMEI();
          const imei = imeiRaw.length !== 0 && imeiRaw[0] != null ? imeiRaw.join(', ') : null;
          const macRaw = yield DeviceInfo.getMacAddress();
          const mac = macRaw !== '02:00:00:00:00:00' ? macRaw : null;
          deviceData = {
            name: manufacturer + ' ' + DeviceInfo.getModel(),
            androidVersion: DeviceInfo.getSystemVersion(),
            imei: imei,
            mac: mac,
          };
        } else {
          deviceData.browserInfo = window.navigator.userAgent;
        }
        logi(`Device Info: `, deviceData);

        if (tokenResponse.body.session_id) {
          yield call(Metrics.storeSessionId, tokenResponse.body.session_id);
        }
        const version = !isWeb ? DeviceInfo.getVersion() : appVersion;
        yield call(saveValue, KEY_METRICS_PARENT_VERSION, version);
      }
      if (isWeb && !isReinitialization && tokenResponse.result === REST_RESPONSE_OK) {
        // Update weblock username value
        const event = new CustomEvent('user_logged_in', {
          detail: {
            username: login,
            userLoggedIn: true,
          },
        });
        window.dispatchEvent(event);
      }
      let umResponse;
      if (!doingBRChange) {
        logi(`Auth: login succeeded in UM for user ${login}, fetching UM details...`);
        // store tokens to redux
        if (!isReinitialization) {
          yield put(storeTokensAction(tokenResponse.body.access_token, tokenResponse.body.refresh_token));
        }
        umResponse = yield call(getUserDetailsFromGqlUm, login);
      }
      if (doingBRChange || (umResponse && !umResponse.error)) {
        if (!doingBRChange) {
          if (!isReinitialization) {
            yield call(saveIsLoginProcessCompleted, false);
          }
          if (umResponse.data.userByLogin == null) {
            logi('umResponse.data.userByLogin is NULL');
          }
          userJson = umResponse.data.userByLogin;
          isWeb && (yield call(saveValue, KEY_USER_BY_LOGIN_DATA, JSON.stringify(userJson)));
          yield call(saveValue, KEY_USER_JSON_RESP, JSON.stringify(umResponse));

          yield call(handleDirectAuthorizations, userJson.authorizations);
          if (userJson?.passwordType === 'PIN') {
            //SO-3213
            yield put(storeAuthTypeAction('authenticationType.pinAuth'));
          } else if (userJson?.passwordType === 'BASIC') {
            if (userJson?.authType === 'DB') {
              yield put(storeAuthTypeAction('authenticationType.pwdAuth'));
            } else if (userJson?.authType !== 'DB') {
              yield put(storeAuthTypeAction('authenticationType.ldapAuth'));
            }
          }

          const userGroupIds =
            userJson && userJson.assignedGroups && userJson.assignedGroups.length
              ? userJson.assignedGroups.map(ag => Number(ag.group.id)).filter(it => it)
              : [];

          //Get group & direct attribute value names
          try {
            yield call(getAttributeValueNames, userJson.assignedGroups || [], userJson.authorizations);
          } catch (e) {
            logw('Error while calling getAttributeValueNames');
          }
          // store tokens to shared prefs
          yield call(saveTokensToSharedPrefs);
          // is user admin
          isUserUmAdmin = findIsUserUmAdmin(userJson);
          // store details and apps from UM
          const user = {
            login: userJson.login,
            firstName: userJson.userDetail.firstName,
            lastName: userJson.userDetail.lastName,
            language: userJson.userDetail.language.code,
            countryCode: userJson.userDetail.country.code,
            email: userJson.userDetail.email,
            isAdmin: isUserUmAdmin,
            userGroupIds,
          };
          const {changePasswordAtNextLogin} = userJson;
          // store user to redux
          yield put(storeUserAction(user));

          const translationRes = yield call(downloadTranslationsForUserLanguage);
          logi(`translationRes ${translationRes}`);

          //Reset the user language after translations download SO-3508
          const updateLanguageRes = yield call(updateLanguageWatcher, {
            payload: user.language,
          });
          logi(`updateLanguageRes ${updateLanguageRes}`);

          if (!isReinitialization) {
            // Save data for lock screen, must be AFTER auth and language are determined
            yield call(saveDataForLockscreen, userJson.userDetail, password);
          }

          //Privacy notification must be AFTER language is set
          const privacyAccepted = isReinitialization ? true : yield call(showPrivacyNotificationScreen);
          logi(`loginAttemptFlow privacyAccepted ${privacyAccepted}`);
          if (!privacyAccepted) {
            yield put(logoutAttemptAction());
            return;
          }

          if (!isReinitialization) {
            yield call(storeUserPasswordToEncryptedStorage, password);
          }
          //Handle change group failure
          if (
            isReinitialization &&
            userJson.assignedGroups?.find(ag => ag.group.id === selectedGroupId) === undefined
          ) {
            const newUgs = setUserGroups(userJson);
            const previouslySelectedUgId = yield select(getSelectedUserGroup);
            const previousUgs = yield select(getUserGroups);
            const previouslySelectedUg = previousUgs.find(ug => ug.id === previouslySelectedUgId);
            if (selectedGroupId === previouslySelectedUgId && previouslySelectedUg) {
              newUgs.push(previouslySelectedUg); //If selected group is already active, it will not be removed from the list of all groups
            }
            yield put(setUserGroupsAction(newUgs)); //reload list of groups
            showToast(I18n.t('errors.groupNoLongerAvailable'));
            yield put(setGroupIsUpdatingAction(false));
            yield call(saveValue, KEY_USER_GROUPS, JSON.stringify(newUgs));
            return;
          }

          // Reset all active task keys for apps
          yield call(resetAllAppStatuses);

          const authType = yield select(getAuthType);
          isPinOrPwdAuth = authType === 'authenticationType.pinAuth' || authType === 'authenticationType.pwdAuth';
          if (!isReinitialization && changePasswordAtNextLogin && !isUserUmAdmin && isPinOrPwdAuth) {
            yield put(
              navigationPushAction('setPassScreen', {
                oldPassword: password,
                previousScreen: 'pageLogin',
              }),
            );
            yield take(PASSWORD_CHANGE_RESULT);
          }
          //1st fetch of App Settings and config for 'UM Change' notifications
          yield call(fetchAndStoreAppSettings);
          yield call(loadAppSettingsUmChangeNotificationValues);

          if (userJson.assignedGroups && userJson.assignedGroups.length) {
            //Handle zero groups
            const userGroups = setUserGroups(userJson);
            yield put(setUserGroupsAction(userGroups));
            const numberOfUserGroups = userGroups.length;
            if (numberOfUserGroups > 1 && !isReinitialization) {
              yield put(storeSelectedUserGroupAction(userGroups.find(g => g.isDefault)?.id || userGroups[0].id));
              yield put(setShowSelectGroupModalAction(true));
              const result = yield take(USER_GROUP_CONTINUE_LOGIN);
              const selectedUserGroupId = yield select(getSelectedUserGroup);
              yield put(storeSelectedUserGroupObjectAction(userGroups.find(ug => ug.id === selectedUserGroupId)));
              yield call(Metrics.trackEvent, Metrics.EventType.UserGroupSwitch, {usergroup_id: selectedUserGroupId});
              yield call(Metrics.storeUserGroupId, selectedUserGroupId);
              logi('USER_GROUP_CONTINUE_LOGIN result: ', result.payload);
              if (result.payload === false) {
                logi('logging out');
                yield put(logoutAttemptAction());
                return;
              }
            } else if (numberOfUserGroups === 1) {
              yield call(Metrics.trackEvent, Metrics.EventType.UserGroupSwitch, {usergroup_id: userGroups[0].id});
              yield call(Metrics.storeUserGroupId, userGroups[0].id);
              yield put(storeSelectedUserGroupAction(userGroups[0].id));
              yield put(storeSelectedUserGroupObjectAction(userGroups[0]));
            } else if (isReinitialization) {
              if (userGroups.find(ug => ug.id === selectedGroupId) != null) {
                yield put(storeSelectedUserGroupAction(selectedGroupId));
                yield call(Metrics.storeUserGroupId, selectedGroupId);
              }
            }
          }
          //store user to redux & shared prefs
          yield call(saveUserToSharedPrefs);
          if (isWeb) {
            const res = yield call(getBusinessReleaseBySelectedGroupId);
            logi(`getBusinessReleaseBySelectedGroupId res: `, res);
            if (res) {
              logi(`switchBRVersion called`);
              yield call(switchBRVersion, res.userUrl, res.currentBR);
              return; // Stop login process here, it will continue after BR switch
            } else if (res === false) {
              //Don't attempt BR change
              logd(`No BR switch`);
            } else if (isReinitialization) {
              //Switch to default, which may be the current one, but we don't know that
              logd(`Switching to default, initialization is stopping`);
              yield call(switchBRVersionToDefault);
              return; // Stop login process here, it will continue after BR switch
            }
            logi('No BR switch, the default BR should be used');
          }
        }
        if (doingBRChange && isWeb) {
          logd(`>> doingBRChange && isWeb`);
          const str = yield call(loadValue, KEY_USER_BY_LOGIN_DATA);
          userJson = str ? JSON.parse(str) : null;
          // is user admin
          isUserUmAdmin = findIsUserUmAdmin(userJson);
          const authType = yield select(getAuthType);
          isPinOrPwdAuth = authType === 'authenticationType.pinAuth' || authType === 'authenticationType.pwdAuth';
        }
        //Continue here after BR change
        const {showPasswordWillExpireNotification, daysToPasswordExpiration} = userJson;
        const selectedUserGroup = yield select(getSelectedUserGroup);
        // store user apps to redux & shared prefs
        const groupAuthorizations =
          userJson.assignedGroups && userJson.assignedGroups.length && selectedUserGroup
            ? userJson.assignedGroups.find(ug => ug.group.id === selectedUserGroup).group.authorizations || []
            : [];

        const {roles, rolesInFormatThatMakesSense} = convertUserAppsFromUmFormatToSharedPrefsFormat(
          groupAuthorizations.length !== 0 ? groupAuthorizations : userJson.authorizations,
        );
        //logd(`rolesInFormatThatMakesSense: `, rolesInFormatThatMakesSense);
        yield put(storeUserAppsAction(rolesInFormatThatMakesSense));
        yield call(getWmsSsoCountries, rolesInFormatThatMakesSense);
        yield call(fetchAndStoreAppSettings);
        // refresh logging parameters
        if (!isWeb) {
          NativeModules.ParentNativeTools.refreshLogSettings();
        }
        yield call(initParentLogging); // refresh log sizes for js code (trimming) SO-5619
        yield call(saveUserAppsToSharedPrefs, roles, rolesInFormatThatMakesSense);

        // download translations for user language, apps init data and apps details
        const [allApps] = yield all([
          call(getAppsDetailsFromUm),
          call(fetchAndStoreInitData),
          isWeb
            ? null
            : call(handleCdmFiles, userJson.login, selectedUserGroup != null ? [parseInt(selectedUserGroup, 10)] : []),
          isWeb ? null : call(fetchAndStoreWmsSso, userJson.login),
        ]);

        yield call(setRestartFlagsToTrue);
        yield call(saveValue, KEY_LAST_LOGIN, new Date().toISOString());
        yield call(saveValue, KEY_API_GW_URL, ROOT_DHLLINK);
        yield call(saveValue, KEY_API_GW_URL_CLEAN, ACTIVE_ENV.newGatewayUrl);
        yield call(saveValue, KEY_CLIENT_ID, ACTIVE_ENV.newClientId);
        yield call(saveValue, KEY_CLIENT_SECRET, ACTIVE_ENV.newClientSecret);

        // handle apps details
        const userAppsForRedux = yield call(filterAllAppsByUserApps, allApps);
        yield put(storeAppsDetailsAction(userAppsForRedux));
        yield put(storeFavoriteAppsKeysAction(userAppsForRedux.map(app => app.key)));
        yield call(saveAppsDetailsToSharedPrefs);
        yield call(saveFavoritesAppsKeysToSharedPrefs);

        // Load notification values from app settings
        yield call(loadAppSettingsNotificationValues);
        yield call(loadAppSettingsUmChangeNotificationValues);

        yield call(setStateValidityForApplicationsNotifications);

        // is tablet
        const isTablet = findIsTabletValueInUserData(userJson);
        yield put(storeIsTabletFromUserSettingOrUsingDetectedValueAction(isTablet));
        yield call(saveIsTabletFromReduxToSharedPrefs);

        // reset emergency mode
        yield put(storeShowEmergencyPromptAction(false));
        yield put(leaveEmergencyAction());

        // reload kiosk apps
        yield call(loadKioskApps);

        // load init data
        yield put(storeFolderAction(null));

        yield call(saveIsLoginProcessCompleted, true);

        // Validate all app data and save error messages
        yield call(runAllAppsValidationsAndPersistErrorMessage);

        // do navigation
        const showPasswordWillExpireNotif = showPasswordWillExpireNotification && isPinOrPwdAuth && !isUserUmAdmin;
        yield put(setGroupIsUpdatingAction(false));
        if (!isReinitialization) {
          yield put(
            navigationReplaceAction('pageMain', {
              showPasswordWillExpireNotification: showPasswordWillExpireNotif,
              daysToPasswordExpiration,
            }),
          );
          yield put(storeScannedValue('')); // Clear scanned value
          yield call(startLockScreenGener, 'loginEnd');
        }

        yield put(setIsAllowedToShowBottomBarMenuAction(true));
        yield call(saveValue, KEY_IS_ALLOWED_TO_SHOW_BOTTOM_BAR_MENU, 'true');
        yield call(clearApplicationNotifications);
        // async not blocking calls
        yield fork(loadApplicationsNotifications, true);
      } else if (umResponse && umResponse.error) {
        if (isReinitialization) {
          yield put(setGroupIsUpdatingAction(false));
          logi(
            `UM response error (probably expired RT), isReinit=${isReinitialization}, umResponse.message: `,
            umResponse.message,
          );
          yield put(logoutAttemptAction(I18n.t('errors.authExpired')));
        } else {
          errorMessage = umResponse.message || I18n.t('errors.authExpired');
        }
      }
    } else if (tokenResponse.result === REST_RESPONSE_CONNECTION_ERROR) {
      // timeout or no connection - show internet not available
      errorMessage = I18n.t('main.connectionError');
      yield put(setGroupIsUpdatingAction(false));
    } else {
      yield put(setGroupIsUpdatingAction(false));
      logi('Auth: login failed in UM ' + tokenResponse.result);
      if (tokenResponse.result === REST_RESPONSE_BAD_REQUEST) {
        errorMessage = I18n.t('login.wrongCredentials');
      } else {
        //Show EM popup for all except for wrong credentials error SO-3636
        errorMessage = I18n.t('main.serverError');
        !isWeb && (yield put(storeShowEmergencyPromptAction(true)));
        // reload kiosk apps
        yield call(loadKioskApps);
      }
    }
  } catch (e) {
    //Exception in logging in
    if (e instanceof TypeError) {
      errorMessage = I18n.t('errors.authExpired');
    } else {
      errorMessage = I18n.t('errors.invalidResponse');
    }
    logi('Exception in logging in: ', e);
    yield put(setGroupIsUpdatingAction(false));
  }
  if (errorMessage) {
    yield call(saveIsLoginProcessCompleted, true);
    if (!isReinitialization) {
      yield call(clearUserDataFromRedux);
      yield call(removeWmsFilesIfTheyExist);
      yield call(clearUserDataFromSharedPrefs);
      yield call(clearAppsInitDataFromSharedPrefs);
      let showOpenWifiData = false; // SO-3643
      if (!isWeb && errorMessage === I18n.t('main.connectionError')) {
        const state = yield fetch();
        logi('Connection state: ', state);
        if (
          state.isConnected === false &&
          state.isWifiEnabled === false &&
          (state.type === 'unknown' || state.type === 'none')
        ) {
          showOpenWifiData = true;
          logi('showOpenWifiData: ', showOpenWifiData);
        }
      }
      yield put(
        navigationReloadWithPropsAction({
          clearFields: false,
          attemptNumber,
          error: showOpenWifiData ? I18n.t('errors.turnOnWifiOrData') : errorMessage,
        }),
      );
      if (
        errorMessage === I18n.t('main.connectionError') ||
        errorMessage === I18n.t('errors.authExpired') ||
        errorMessage === I18n.t('errors.invalidResponse') ||
        errorMessage === I18n.t('main.serverError')
      ) {
        // SO-2422
        !isWeb && (yield put(storeShowEmergencyPromptAction(true)));
        // reload kiosk apps
        yield call(loadKioskApps);
      }
    } else {
      yield call(resetAllAppStatuses);
    }
  } else {
    //SO-3523
    yield put(startLockScreenAction());
  }
  yield call(saveValue, KEY_BR_SWITCH_SCREEN, null); // BR switch is done, clear screen
}

export function* logoutAttemptFlow({payload}) {
  logi(`logoutAttemptFlow running`);
  isWeb && toast.clearWaitingQueue();
  isWeb && toast.dismiss();
  // Try to reset the GW values, the logout attempt is called during failed login attempts
  yield call(saveValue, KEY_API_GW_URL_CLEAN, ACTIVE_ENV.newGatewayUrl);
  yield call(saveValue, KEY_CLIENT_ID, ACTIVE_ENV.newClientId);
  yield call(saveValue, KEY_CLIENT_SECRET, ACTIVE_ENV.newClientSecret);
  yield put(
    navigationResetAction('pageLogin', {error: payload, clearFields: true, attemptNumber: getRandomInt(1000000)}),
  );
  yield call(saveValue, KEY_LOCKSCREEN_LOCKED, 'false');
  yield call(revokeUserAccessToken);
  yield call(clearUserDataFromRedux);
  yield call(removeWmsFilesIfTheyExist);
  yield call(clearUserDataFromSharedPrefs);
  yield call(clearAppsInitDataFromSharedPrefs);
  yield call(resetAllAppStatuses);
  yield put(leaveEmergencyAction());
  yield call(saveIsLoginProcessCompleted, true);
  yield put(setWasChangePinModalShown(false));
  yield put(setIsAllowedToShowBottomBarMenuAction(false));
  yield call(saveValue, KEY_IS_ALLOWED_TO_SHOW_BOTTOM_BAR_MENU, 'false');
  yield put(storeScannedValue('')); // Clear scanned value again
  yield call(clearAllValidationMessages);
  if (isWeb) {
    const currentUrl = window.location.toString();
    // Loop if this is not here
    if (currentUrl.endsWith('/logout')) {
      let url = currentUrl.split('/parent')[0];
      url = url + '/parent';
      logi(`logoutAttemptFlow replacing with new url: ${url}`);
      // @ts-ignore
      window.allowRedirect = true;
      window.location.assign(url);
    }
  }
}

export function* startLockScreenGener(source) {
  yield call(startLockScreenPlain, source);
}

export async function startLockScreenPlain(source) {
  // there must be some ignore period (2s) to not end up in loop activating parent and lock screen
  // This function is called from app activate watcher. It must be there for several reasons, the main which can't be workarounded is
  // Android "feature" which prevents starting some app (lockscreen) for 5 seconds after hitting home button taking user to launcher (parent)
  // In this scenario lockscreen detects it lost focus (in home press), immediately sends intent to start lockscreen again
  // but is ignored by Android - see e.g. https://stackoverflow.com/questions/8163399/reason-for-5-sec-delay-to-show-an-activity-on-pressing-the-home-button
  // So it must be handled by launcher (parent) itself, in onActivate handler it checks locked flag and starts lock screen again (without 5s delay)
  // however during unlocking (or if lock screen should not be shown) the focus goes from started lock screen immediately back to parent
  // which during onActivate would start lock screen again and end up in the loop between these 2 apps
  // so lock screen can be started only if it was not done some moment (2s) ago
  // 9.3.2022 - after next code improvements disabled by setting to 0ms
  if (isWeb) return;
  const wasDeviceRestarted = await checkDeviceRestartedAsync();
  if (wasDeviceRestarted) {
    logi(`LCDDBG device was restarted, skipping lockscreen start`);
    return;
  }
  const now = new Date();
  const msFromLastOpen = now.getTime() - (glbLockScreenLastOpen ? glbLockScreenLastOpen.getTime() : 0);
  const glbLockScreenLastOpenISOString = glbLockScreenLastOpen ? glbLockScreenLastOpen.toISOString() : 'undefined';
  logi(
    `LCDDBG: startLockScreen considering lock screen start from parent, glbLockScreenLastOpen ${glbLockScreenLastOpenISOString}, source: ${JSON.stringify(
      source,
    )} msFromLastOpen: ${msFromLastOpen}`,
  );
  if (msFromLastOpen > REPEATED_LOCKSCREEN_ATTEMPT_IGNORE_MS) {
    //Start Lockscreen
    SendIntentAndroid.openApp('com.dhl.smartops.lockscreen', {}).then(wasOpened =>
      logi(`LCDDBG: startLockScreen source: ${JSON.stringify(source)}, was opened ${wasOpened}`),
    );
    glbLockScreenLastOpen = new Date();
    logi(
      `LCDDBG: startLockScreen source: ${JSON.stringify(source)}, last open is now ${glbLockScreenLastOpenISOString}`,
    );
  } else {
    logi(
      `LCDDBG: startLockScreen Ignoring lockscreen open attempt - coming after: ${msFromLastOpen}ms which is sooner than limit ${REPEATED_LOCKSCREEN_ATTEMPT_IGNORE_MS}`,
    );
  }
}

function* storeSelectedUserGroupObjectFlow({payload}) {
  yield call(saveValue, SELECTED_USER_GROUP_OBJECT, JSON.stringify(payload));
}

function setUserGroups(userJson) {
  const defaultGroupId = userJson.assignedGroups.find(ag => {
    if (ag.attributes) {
      const attr = ag.attributes.find(att => att.value === 'true' && att.attribute.label === 'default_group');
      if (attr != null) {
        return ag;
      }
    }
    return null;
  })?.group?.id;
  logi('defaultGroupId: ', defaultGroupId);
  let userGroups = userJson.assignedGroups
    .filter(ag => ag.group.id != null)
    .map(g => ({
      id: g.group.id,
      name: g.group.name,
      description: g.group.description,
      authorizations: g.group.authorizations,
      isDefault: g.group.id === defaultGroupId,
    }));
  userGroups = orderBy(userGroups, [g => g.description.toLowerCase()]);
  notThatVerboseLog('userGroups to return: ', userGroups || []);
  return userGroups || [];
}

function* handleDirectAuthorizations(directAuthorizations) {
  //Get Logics Employee ID from Source Systems Identities
  if (directAuthorizations && Array.isArray(directAuthorizations) && directAuthorizations.length > 0) {
    const sourceSystemIds = directAuthorizations.filter(auth => auth.role === null && auth.application === null);
    if (sourceSystemIds && sourceSystemIds[0] && sourceSystemIds[0]?.attributes?.length > 0) {
      const logicsAttr = sourceSystemIds[0].attributes.filter(att => att?.attribute?.label === 'logics_employee_id');
      if (logicsAttr && logicsAttr[0] && logicsAttr[0].value) {
        //logd('KEY_LOGICS_EMPLOYEE_ID saved successfully!');
        yield put(setLogicsEmployeeIdAction(logicsAttr[0].value));
        yield call(saveValue, KEY_LOGICS_EMPLOYEE_ID, logicsAttr[0].value);
      }
    }
  }
}

function* getWmsSsoCountries(rolesInFormatThatMakesSense) {
  const appIds = [VELOCITY_SCAN, SMART_SCAN];
  for (const appId of appIds) {
    if (
      rolesInFormatThatMakesSense &&
      rolesInFormatThatMakesSense[appId] &&
      rolesInFormatThatMakesSense[appId][0].userDataLimitations
    ) {
      if (Array.isArray(rolesInFormatThatMakesSense[appId][0].userDataLimitations)) {
        for (const limitation of rolesInFormatThatMakesSense[appId][0].userDataLimitations) {
          if (limitation.type === 'Country' && limitation.objectId != null) {
            if (appId === appIds[0]) {
              //velocity_scan
              logi(`velocity_scan countryId: `, limitation.objectId);
              yield put(setVelocityScanCountry(limitation.objectId));
            } else {
              //smart_scan
              logi(`smart_scan countryId: `, limitation.objectId);
              yield put(setSmartScanCountry(limitation.objectId));
            }
          }
        }
      }
    }
  }
}

const getRandomInt = max => Math.floor(Math.random() * Math.floor(max));

export function* saveDataForLockscreen(userDetails, password) {
  const authType = yield select(getAuthType);
  const isPin = authType === 'authenticationType.pinAuth';
  const salt = randomString(10);
  const lockScreenData = {
    name: `${userDetails.firstName} ${userDetails.lastName}`,
    passwordHash: salt + md5(salt + password),
    isPin,
    messages: {
      loggedInAs: I18n.t('lockscreen.loggedInAs'),
      enterYourPin: I18n.t('login.placeholderPassword'),
      enterYourPassword: I18n.t('login.enterPassword'),
      unlock: I18n.t('lockscreen.unlock'),
      logout: I18n.t('lockscreen.logout'),
      queueDescription: I18n.t('common.queueDescription'),
      wrongPin: I18n.t('login.wrongLockScreen'),
      wrongPassword: I18n.t('login.wrongLockScreenPass'),
      inactivityWarning: I18n.t('lockscreen.inactivityWarning'),
      turnOnWifiOrData: I18n.t('errors.turnOnWifiOrData'),
      serverError: I18n.t('main.serverError'),
    },
  };
  //logd(`lockScreenData: `, lockScreenData);
  yield call(saveValue, KEY_LOCKSCREEN_DATA, JSON.stringify(lockScreenData));
}

function validateTokenResponse(response) {
  const schema = {
    type: 'object',
    properties: {
      access_token: {type: 'string'},
      expires_in: {type: 'number'},
      refresh_token: {type: 'string'},
      scope: {type: 'string'},
      token_type: {type: 'string'},
    },
    required: ['access_token', 'expires_in', 'refresh_token', 'scope', 'token_type'],
  };
  let res = validate(response.body, schema);
  if (res.errors && res.errors.length > 0) {
    logi('errors: ', res.errors);
  }
  return res.valid;
}

export function* grantTokensUsingLoginAndPassword(login, password) {
  const loginEventAttributes = yield call(Metrics.getLoginEventAttributes);
  let params = {
    username: login,
    password: password,
    ...loginEventAttributes,
  };
  const response = yield call(postParamsWithJSONResponseToUM, OAUTH_GRANT_TOKEN_URL, params);
  //logd(`response: `, response);
  if (response.result === REST_RESPONSE_OK && !validateTokenResponse(response)) {
    return {result: REST_RESPONSE_BAD_RESPONSE};
  }
  return response;
}

export function* revokeUserAccessToken() {
  const accessToken = yield select(getAccessTokenSelector);
  const sessionId = yield call(Metrics.getSessionId);
  if (accessToken == null) {
    yield call(Metrics.storeSessionId, undefined);
    return;
  }
  try {
    const username = yield call(loadValue, KEY_LOGIN);
    let url = `${OAUTH_REVOKE_TOKEN_URL}`;
    const date = new Date().toISOString();
    if (sessionId != null && username != null) {
      url = `${url}?session_id=${sessionId}&username=${username}&event_time=${date}`;
    }
    const response = yield call(revokeTokenFromUm, accessToken, url);
    logd(`Token revoked at UM, response: `, response);
    logd(`Token revoked at UM, response.status: `, response.status);
    if (!isWeb && response?.status !== 200) {
      // Add message to Queue if we are offline
      yield call(Metrics.trackEvent, Metrics.EventType.Logout, {event_time: date});
    }
    yield call(Metrics.storeSessionId, undefined);
  } catch (e) {
    logw('Error revoking access token at UM: ', e);
  }
}
