import {call, select} from 'redux-saga/effects';
import {apiCall} from '../../utils/Oauth';
import {logi, logw} from '../logging';
import {saveValue} from '../sharedPrefs';
import {extractIdsOfGivenTypeFromDl} from '../../utils/Utils';
import I18n from 'features/I18n';
import {ACTIVE_ENV} from '../../configs/active-env';
import {getAppSettingsValue} from '../../flows/apps';
import {getSelectedUserGroup} from '../../flows/auth';
import {showToast} from 'features/platformSpecific';

const INIT_DATA_KEY = 'checklist';
const GET_INIT_DATA_URL = `${ACTIVE_ENV.gatewayUrl}/opsmgt/v1/initwatch/checklist`;

function* loadInitData(user, roles) {
  logi('Preparing to fetch Checklist init data');
  // extract all data limitation ids from all roles
  const facilityIds = extractIdsOfGivenTypeFromDl(roles, 'Facility');
  const selectedUserGroup = yield select(getSelectedUserGroup);
  const timeoutInSeconds = yield call(getAppSettingsValue, 'checklist_init.timeout');
  const timeout = timeoutInSeconds ? timeoutInSeconds * 1000 : 60000;
  const langCode = user.language || 'en';
  const userLogin = user.login;
  if (facilityIds && facilityIds.length && langCode) {
    const params = `facility_id=${facilityIds[0]}&user=${userLogin}&language_code=${langCode}`;
    let url = `${GET_INIT_DATA_URL}?${params}`;
    logi(`Fetching Checklist init data from ${url}`);
    try {
      const headers = {};
      const response = yield call(apiCall, {url, headers, timeout: timeout});
      //logd('Fetched Checklist init data ', logObject(response));
      yield call(saveValue, INIT_DATA_KEY, JSON.stringify(response));
    } catch (e) {
      logw('Error loading Checklist init data', e);
      showToast(I18n.t('errors.checklistDataFailed'));
    }
  } else {
    showToast(I18n.t('errors.checklistDataFailed'));
  }
}

export default {
  label: 'checklist', // in UM
  title: 'Checklist',
  restartKey: 'restart_checklist',
  activeTaskKey: 'active_task_checklist', // {isActive: true/false,...}
  needInitData: true,
  initDataKey: INIT_DATA_KEY,
  urlPath: GET_INIT_DATA_URL.substring(GET_INIT_DATA_URL.indexOf('opsmgt') + 6),
  validationMessageKey: 'validation_checklist',
  loadInitData,
};
