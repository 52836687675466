import {call, select} from 'redux-saga/effects';
import {apiCall} from '../../utils/Oauth';
import {logi, logw} from '../logging';
import {saveValue} from '../sharedPrefs';
// eslint-disable-next-line no-unused-vars
import {extractIdsOfGivenTypeFromDl} from '../../utils/Utils';
import I18n from 'features/I18n';
import {ACTIVE_ENV} from '../../configs/active-env';
import {showToast} from 'features/platformSpecific';
import {getSelectedUserGroup} from '../../flows/auth';

const INIT_DATA_KEY = 'indApr';
const GET_IND_APR_INIT_DATA_URL = `${ACTIVE_ENV.gatewayUrl}/opsmgt/v1/initwatch/indapr/facilities/`;

function* loadInitData(user, roles) {
  logi('Fetching IndApr init data');
  // extract all data limitation ids from all roles
  const selectedUserGroup = yield select(getSelectedUserGroup);
  const facilityIds = extractIdsOfGivenTypeFromDl(roles, 'Facility');
  const warehouseIds = extractIdsOfGivenTypeFromDl(roles, 'WLM Warehouse');
  if (facilityIds && facilityIds.length && warehouseIds && warehouseIds.length) {
    let url = `${GET_IND_APR_INIT_DATA_URL}${facilityIds[0]}/warehouse/${warehouseIds[0]}`;
    if (user && user.login) {
      url = `${url}?smartops_user=${user.login}`;
      if (selectedUserGroup) {
        url = `${url}&smartops_user_group=${selectedUserGroup}`;
      }
    }
    try {
      const headers = {'X-messagename': 'OPSINIT', 'x-direction': 'umv2'};
      const response = yield call(apiCall, {url, headers, timeout: 60000});
      //logd('Fetched IndApr init data ', logObject(response));
      yield call(saveValue, INIT_DATA_KEY, JSON.stringify(response));
    } catch (e) {
      logw('Error loading IndApr init data', e);
      showToast(I18n.t('errors.indAprDataFailed'));
    }
  } else {
    showToast(I18n.t('errors.indAprDataFailed'));
  }
}

export default {
  label: 'indApr', // in UM
  title: 'Indirect Approvals',
  restartKey: 'restart_indApr',
  activeTaskKey: 'active_task_indApr', // {isActive: true/false,...}
  needInitData: true,
  initDataKey: INIT_DATA_KEY,
  urlPath: GET_IND_APR_INIT_DATA_URL.substring(GET_IND_APR_INIT_DATA_URL.indexOf('opsmgt') + 6),
  validationMessageKey: 'validation_indApr',
  loadInitData,
};
