import React, {PureComponent} from 'react';
import {View} from 'react-native';
import {dhlBlue} from './commonStyles';
import {ButtonGroup} from 'react-native-elements';
import {connect} from 'react-redux';
import I18n from 'features/I18n';
import {ScaledSheet} from 'react-native-size-matters';

class OneOfSelect extends PureComponent {
  updateIndex = selectedIndex => this.props.onPress(this.props.options[selectedIndex]);

  render() {
    const {containerStyle, selected, textStyle, options, buttonsStyle} = this.props;
    return (
      <View style={[styles.container, containerStyle]}>
        <ButtonGroup
          selectedButtonStyle={{backgroundColor: dhlBlue}}
          innerBorderStyle={{color: dhlBlue}}
          selectedTextStyle={{color: 'white', fontWeight: 'bold'}}
          textStyle={[styles.textStyle, textStyle]}
          onPress={this.updateIndex}
          selectedIndex={selected}
          buttons={options.map(o => I18n.t(o))}
          containerStyle={buttonsStyle}
        />
      </View>
    );
  }
}

const styles = ScaledSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  textStyle: {
    color: dhlBlue,
    fontSize: '20@ms0.2',
    textAlign: 'center',
  },
});

function mapStateToProps(state, props) {
  return {
    selected: props.indexOfSelected,
  };
}

export default connect(mapStateToProps, {})(OneOfSelect);
