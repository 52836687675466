import {Image, StyleSheet, Text, View} from 'react-native';
import {dhlBasicOrange, dhlBlack, dhlGrey, dhlRedButton} from 'smartops-shared';
import I18n from '../features/I18n';
import {useSelector} from 'react-redux';
import {getAppsDetailsSelector} from '../flows/apps';
import {App} from '../types';
import React from 'react';
import {ApplicationNotification, ApplicationNotifications} from '../utils/types';
import {getApplicationNotifications} from '../flows/applicationNotification';
import {makeGetSettingSelector} from '../flows/settings';
import AppOpener from '../features/AppOpener';
import FontAwesome5Pro from 'react-native-vector-icons/FontAwesome5Pro';

const APP_LABELS_WITH_NOTIFICATIONS = ['inc_mng', 'indApr', '306090'];

interface AppState {
  app: App;
  notification: ApplicationNotification | undefined;
}

const NotificationsScreen = () => {
  const appsDetails: App[] = useSelector(getAppsDetailsSelector);
  const applicationNotifications: ApplicationNotifications = useSelector(getApplicationNotifications);
  const appsToRender: AppState[] = appsDetails
    .filter(it => APP_LABELS_WITH_NOTIFICATIONS.includes(it.key))
    .map(it => {
      const unitedState: AppState = {
        app: it,
        // @ts-ignore
        notification: applicationNotifications[it.key],
      };
      return unitedState;
    })
    .filter(it => {
      const allowedZeroGroupVisibility = useSelector(
        makeGetSettingSelector(it.app.key + '.empty_notification_group_visibility'),
      );

      const isAllowedToDisplayZeroValueNotifications =
        it.notification?.countOfNotifications === 0 && allowedZeroGroupVisibility === 'true';

      const isValueBiggerThenZero = it.notification && it.notification.countOfNotifications > 0;
      return it.notification?.active && (isValueBiggerThenZero || isAllowedToDisplayZeroValueNotifications);
    });

  const renderApp = (item: AppState) => {
    return (
      <AppOpener app={item.app} key={item.app.key} style={styles.appWrapper}>
        <View style={styles.yellowBar} />
        <Image
          source={{uri: item.app.image}}
          style={{
            height: '100%',
            width: 40,
            justifyContent: 'center',
          }}
          resizeMode={'contain'}
        />
        <Text style={styles.notificationNumber}>
          {!item.notification?.loading ? item.notification?.countOfNotifications : 0}
        </Text>
        <Text numberOfLines={1} style={styles.notificationText}>
          {I18n.t(`notifications.notification_text_${item.app.key}`)}
        </Text>
      </AppOpener>
    );
  };
  return (
    <View style={styles.container}>
      {appsToRender.length != 0 ? (
        appsToRender.map(renderApp)
      ) : (
        <View>
          <View style={styles.bellIconWrapper}>
            <FontAwesome5Pro name={'bell'} size={100} color={dhlGrey} />
          </View>
          <Text style={styles.emptyNotificationsText}>{I18n.t('notifications.no_notifications')}</Text>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 10,
  },
  appWrapper: {
    flexDirection: 'row',
    height: 50,
    marginLeft: 5,
    margin: 10,
    alignItems: 'center',
  },
  yellowBar: {
    borderRadius: 10,
    height: '100%',
    width: 6,
    backgroundColor: dhlBasicOrange,
    marginRight: 10,
  },
  notificationNumber: {
    color: dhlRedButton,
    fontSize: 18,
    fontWeight: 'bold',
    marginHorizontal: 10,
  },
  notificationText: {
    color: dhlBlack,
    fontSize: 18,
    fontWeight: 'bold',
    flexWrap: 'wrap',
    textAlign: 'left',
    width: '72%',
  },
  emptyNotificationsText: {
    color: dhlBlack,
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  bellIconWrapper: {
    paddingTop: 30,
    paddingBottom: 20,
    alignItems: 'center',
  },
  bellIcon: {
    // @ts-ignore
    fontSize: '20@ms0.2',
  },
});

export default NotificationsScreen;
