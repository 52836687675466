import {createStore, compose, applyMiddleware, combineReducers} from 'redux';
import appsReducer from 'flows/apps';
import scannerReducer from 'flows/scanner';
import {allFlows} from 'flows';
import createSagaMiddleware from 'redux-saga';
import authReducer from 'flows/auth';
import appReducer from 'flows/app';
import kioskReducer from 'flows/kiosk';
import wmsSsoReducer from 'features/wmsSso';
import notificationReducer from 'flows/notification';
import {createNavigationReducer} from 'smartops-shared';
import {applicationNotificationReducer} from './flows/applicationNotification';
import {settingsReducer} from "./flows/settings";

export const reducers = combineReducers({
  auth: authReducer,
  apps: appsReducer,
  scanner: scannerReducer,
  app: appReducer,
  kiosk: kioskReducer,
  wmsSso: wmsSsoReducer,
  notification: notificationReducer,
  applicationNotification: applicationNotificationReducer, // child applications notifications
  navigation: createNavigationReducer('splashScreen'),
  settings: settingsReducer,
});

const sagaMiddleware = createSagaMiddleware();
const store = createStore(reducers, {}, compose(applyMiddleware(sagaMiddleware)));

sagaMiddleware.run(allFlows);

export default store;
