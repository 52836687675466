import {call, select} from 'redux-saga/effects';
import {apiCall} from '../../utils/Oauth';
import {logd, logi, logw} from '../logging';
import {saveValue} from '../sharedPrefs';
// eslint-disable-next-line no-unused-vars
import {extractIdsOfGivenTypeFromDl, logObject} from '../../utils/Utils';
import I18n from 'features/I18n';
import {getAppSettingsValue} from '../../flows/apps';
import {getSelectedUserGroup} from '../../flows/auth';
import {showToast} from 'features/platformSpecific';
import {ROOT_DHLLINK} from '../../config';

const INIT_DATA_KEY = 'mwlm';
const GET_MWLM_INIT_DATA_URL = `${ROOT_DHLLINK}/opsmgt/v1/initwatch/wlm/uomreferenceid/facilities/`;

function* loadInitData(user, roles) {
  logi('Fetching mWlm init data');
  // extract all data limitation ids from all roles
  const facilityIds = extractIdsOfGivenTypeFromDl(roles, 'Facility');
  const warehouseIds = extractIdsOfGivenTypeFromDl(roles, 'WLM Warehouse');
  const selectedUserGroup = yield select(getSelectedUserGroup);
  const timeoutInSeconds = yield call(getAppSettingsValue, 'mwlm_init.timeout');
  const timeout = timeoutInSeconds ? timeoutInSeconds * 1000 : 60000;
  //logd(`mwlm facilityIds`, facilityIds);
  //logd(`mwlm warehouses`, warehouseIds);
  if (facilityIds && facilityIds.length && warehouseIds && warehouseIds.length) {
    let url = `${GET_MWLM_INIT_DATA_URL}${facilityIds[0]}/warehouse/${warehouseIds[0]}`;
    if (user && user.login) {
      url = `${url}?smartops_user=${user.login}`;
    }
    if (selectedUserGroup) {
      url = `${url}&smartops_user_group=${selectedUserGroup}`;
    }
    try {
      const headers = {};
      const response = yield call(apiCall, {url, headers, timeout: timeout});
      //logd('Fetched mWlm init data ', logObject(response));
      yield call(saveValue, INIT_DATA_KEY, JSON.stringify(response));
    } catch (e) {
      logw('Error loading mWlm init data', e);
      showToast(I18n.t('errors.wlmDataFailed'));
    }
  } else {
    logw(`mWlm No facilities or warehouses configured for this user!`);
  }
}

export default {
  label: 'mWLM', // in UM
  title: 'mWlm',
  restartKey: 'restart_mwlm',
  activeTaskKey: 'active_task_mwlm', // {isActive: true/false,...}
  needInitData: true,
  initDataKey: INIT_DATA_KEY,
  urlPath: GET_MWLM_INIT_DATA_URL.substring(GET_MWLM_INIT_DATA_URL.indexOf('opsmgt') + 6),
  validationMessageKey: 'validation_mwlm',
  loadInitData,
};
