import {RNCamera} from 'react-native-camera';
import {AppState} from 'react-native';
import {isWeb} from 'features/platformSpecific';
import {Component} from 'react';

export class BetterCamera extends (isWeb ? Component : RNCamera) {
  constructor(props) {
    super(props);
    this.state = {
      appState: AppState.currentState,
    };
  }

  componentDidMount() {
    AppState.addEventListener('change', this._handleAppStateChange);
    if (typeof super.componentDidMount === 'function') {
      super.componentDidMount();
    }
  }

  componentWillUnmount() {
    if (typeof super.componentWillUnmount === 'function') {
      super.componentWillUnmount();
    }
    AppState.removeEventListener('change', this._handleAppStateChange);
  }

  _handleAppStateChange = nextAppState => {
    this.setState({appState: nextAppState});
  };

  render() {
    if (this.state.appState === 'active') {
      return super.render();
    } else {
      return null;
    }
  }
}
