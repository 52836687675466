import {logSendingFailedAction, sentLogCounterAddAction} from 'flows/app';
import {logoutAttemptAction} from 'flows/login';
import {KEY_APP_SETTINGS, KEY_LOGIN, loadJson, loadValue, saveValue} from './sharedPrefs';
import {getLogs, initAppLogging, log, Loggers} from 'smartops-shared';
import {call, put, all} from 'redux-saga/effects';
import {apiCall} from 'utils/Oauth';
import {currentScreen} from '../App';
import DeviceInfo from 'react-native-device-info';

//TODO: problem with TS
//@ts-ignore
import {getIMEI} from 'react-native-multiprocess-preferences';
import moment from 'moment';
import {fromByteArray} from 'base64-js';
import {isWeb, showToast} from './platformSpecific';
import I18n from './I18n';
import {POST_LOG_API_URL, TOKEN_REFRESH_FAILED_BROADCAST} from '../config';
import {DeviceEventEmitter, Dimensions, NativeModules} from 'react-native';
import {appVersion, buildDateTime} from 'configs/version';

// @ts-ignore
import pako from 'pako';

const logd = (...args: any) => log.debug(...args);
const logi = (...args: any) => {
  log.info(...args);
}
const logw = (...args: any) => log.warn(...args);

const initParentLogging = async () => {
  const appSettings = await loadJson(KEY_APP_SETTINGS);
  initAppLogging(Loggers.log_parent, appSettings?.portal);
};

const logO = (text: any, object: any) => logd(text, JSON.stringify(object, undefined, 2));
export {logi, logd, logw, logO, initParentLogging};

export const LOG_SSO = log.extend('WmsSso');

export function* sendLogApi(log: any) {
  yield call(isWeb ? sendLogApiWeb : sendLogApiAndroid, log);
}

function* sendLogApiWeb(log: any) {
  //@ts-ignore
  const selectedLog = log.payload.logger;

  //taken params
  //@ts-ignore
  const logData = yield call(getLogs, selectedLog);

  yield all(
    logData.virtualFiles.map((content: string) => {
      return call(function* () {
        yield sendContent(logData.name, log.payload.logger, log.payload.comment, content);
      });
    }),
  );
}

function* sendContent(fileName: string, logger: Loggers, comment: string, content: string) {
  const deviceData = `${JSON.stringify(Dimensions.get('window'))} ${appVersion} ${buildDateTime}\n`;
  let finalContent = '';
  if (logger === Loggers.log_parent) {
    finalContent = deviceData + content;
  } else {
    finalContent = content;
  }
  const fileZipped = pako.deflate(finalContent);
  const fileContent = fromByteArray(fileZipped);
  const incident = comment;

  //onplace params
  //@ts-ignore
  const username = yield call(loadValue, KEY_LOGIN);
  const sentTime = moment().format('YYYY-MM-DD HH:mm:ss'); //save this to localdb for display last sent
  yield call(saveValue, `last_sent_${logger}`, sentTime);
  const userAgent = window.navigator.userAgent;

  //request params
  const url = POST_LOG_API_URL;
  const headers = {'Content-Type': 'application/json'};
  const method = 'POST';
  const body = {
    user_agent: userAgent,
    user_login: username,
    sent: sentTime,
    incident: incident,
    file_name: fileName,
    file_content: fileContent,
  };

  try {
    //@ts-ignore
    yield call(apiCall, {url, headers, method, body: JSON.stringify(body), ignoreMessageLog: true});
  } catch (error) {
    logd(`sendLogApi error: `, error);
    showToast(I18n.t('log.filenameFailed', {filename: I18n.t(`log.${fileName}`)}));
    yield put(logSendingFailedAction({logName: fileName}));
    // @ts-ignore
    if (error?.error === I18n.t('errors.authExpired') && currentScreen() !== 'pageLogin') {
      yield put(logoutAttemptAction(I18n.t('errors.authExpired')));
    }
  }
  yield put(sentLogCounterAddAction());
}

function* sendLogApiAndroid(log: any) {
  //@ts-ignore
  const selectedLog = log.payload.logger;

  //taken params
  const deviceData = `${JSON.stringify(Dimensions.get('window'))} ${appVersion} ${buildDateTime}\n`;
  const incident = log.payload.comment;

  //onplace params
  //@ts-ignore
  const username = yield call(loadValue, KEY_LOGIN);
  const sentTime = moment().format('YYYY-MM-DD HH:mm:ss'); //save this to localdb for display last sent
  yield call(saveValue, `last_sent_${selectedLog}`, sentTime);

  const imei: string[] = yield call(getIMEI);
  const mac: string = yield call(DeviceInfo.getMacAddress);
  const manufacturer: string = yield call(DeviceInfo.getManufacturer);
  const body = {
        device_name: manufacturer + ' ' + DeviceInfo.getModel(),
        //@ts-ignore
        imei: (imei.length !== 0 && imei[0] != null) ? imei.join(', ') : "",
        mac: (mac !== '02:00:00:00:00:00') ? mac : "",
        user_login: username,
        sent: sentTime,
        incident: incident,
        user_agent: deviceData,
      };
  try {
    yield call(NativeModules.ParentNativeTools.sendLogs, selectedLog, JSON.stringify(body));
  } catch (error) {
    logd(`sendLogApi error: `, error);
    showToast(I18n.t('log.filenameFailed', {filename: I18n.t(`log.${selectedLog}`)}));
    yield put(logSendingFailedAction({logName: selectedLog}));
    // @ts-ignore
    if (error?.error === I18n.t('errors.authExpired') && currentScreen() !== 'pageLogin') {
      DeviceEventEmitter.emit(TOKEN_REFRESH_FAILED_BROADCAST);
    }
  }
  yield put(sentLogCounterAddAction());
}
