import {call} from 'redux-saga/effects';
import {apiCall} from '../../utils/Oauth';
import {logd, logi, logw} from '../logging';
import {saveValue} from '../sharedPrefs';
// eslint-disable-next-line no-unused-vars
import {extractIdsOfGivenTypeFromDl, logObject} from '../../utils/Utils';
import I18n from 'features/I18n';
import {ACTIVE_ENV} from '../../configs/active-env';
import {showToast} from 'features/platformSpecific';

const INIT_DATA_KEY = 'mpts';
const GET_PTS_INIT_DATA_URL = `${ACTIVE_ENV.gatewayUrl}/opsmgt/v1/initwatch/mpts/instance_name/`;

function* loadInitData(user, roles) {
  logi('Fetching mPts init data');
  // extract all data limitation ids from all roles
  const instanceIds = extractIdsOfGivenTypeFromDl(roles, 'PTS Instance');
  const sssIds = extractIdsOfGivenTypeFromDl(roles, 'Source System Set');
  if (instanceIds && instanceIds.length && sssIds && sssIds.length) {
    const url = `${GET_PTS_INIT_DATA_URL}${instanceIds[0]}/source_system_set_name/${sssIds[0]}`;
    try {
      const headers = {'X-messagename': 'OPSINIT', 'x-direction': 'umv2'};
      const response = yield call(apiCall, {url, headers});
      if (response.Error) {
        logi('Fetched mPts init data Error!!', response);
        throw new Error('PTS Data Error');
      }
      //logd('Fetched mPts init data ', logObject(response));
      yield call(saveValue, INIT_DATA_KEY, JSON.stringify(response));
    } catch (e) {
      logw('Error loading mPts init data', e);
      showToast(I18n.t('errors.ptsDataFailed'));
    }
  } else {
    showToast(I18n.t('errors.ptsInstNotFound'));
  }
}

export default {
  label: 'mPTS', // in UM
  title: 'MPts',
  restartKey: 'restart_mpts',
  activeTaskKey: 'active_task_mpts', // {isActive: true/false,...}
  needInitData: true,
  initDataKey: INIT_DATA_KEY,
  urlPath: GET_PTS_INIT_DATA_URL.substring(GET_PTS_INIT_DATA_URL.indexOf('opsmgt') + 6),
  validationMessageKey: 'validation_mpts',
  loadInitData,
};
