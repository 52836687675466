import {call, select} from 'redux-saga/effects';
import {apiCall} from '../../utils/Oauth';
import {logd, logi, logw} from '../logging';
import {saveValue} from '../sharedPrefs';
// eslint-disable-next-line no-unused-vars
import {extractIdsOfGivenTypeFromDl, logObject} from '../../utils/Utils';
import {getSelectedUserGroup} from '../../flows/auth';
import {getAppSettingsValue} from '../../flows/apps';
import {ROOT_DHLLINK} from '../../config';

const INIT_DATA_KEY = 'punches';
const GET_PUNCH_TYPES = `${ROOT_DHLLINK}/opsmgt/v1/initwatch/kronos/facilities/`;

function* loadInitData(user, roles) {
  logi('Fetching Kronos init data');
  // extract all data limitation ids from all roles
  const selectedUserGroup = yield select(getSelectedUserGroup);
  const facilityIds = extractIdsOfGivenTypeFromDl(roles, 'Facility');
  const kronosInstanceIds = extractIdsOfGivenTypeFromDl(roles, 'KRONOS Instance');
  const timeoutInSeconds = yield call(getAppSettingsValue, 'time_attendance_init.timeout');
  const timeout = timeoutInSeconds ? timeoutInSeconds * 1000 : 60000;
  //logd(`kronos InstanceIds: `, kronosInstanceIds);
  //logd(`kronos facilityIds: `, facilityIds);
  //logd('Found Kronos facilities for user ', facilityIds);
  if (facilityIds && facilityIds.length) {
    let url = `${GET_PUNCH_TYPES}${facilityIds[0]}/punchtypes`;
    if (user && user.login) {
      url = `${url}?smartops_user=${user.login}`;
      if (kronosInstanceIds && kronosInstanceIds.length) {
        url = `${url}&instance_name=${kronosInstanceIds[0]}`;
      }
      if (selectedUserGroup) {
        url = `${url}&smartops_user_group=${selectedUserGroup}`;
      }
    }
    try {
      const headers = {};
      const response = yield call(apiCall, {url, headers, timeout: timeout});
      //logd('Fetched Kronos response: ', logObject(response));
      yield call(saveValue, INIT_DATA_KEY, JSON.stringify(response || {}));
    } catch (e) {
      logw('Error loading Kronos init data (punches)', e);
    }
  } else {
    logw(`mkronos No facilities configured for this user!`);
  }
}

export default {
  label: 'mKRONOS', // in UM
  title: 'mKronos',
  restartKey: 'restart_mkronos',
  activeTaskKey: 'active_task_mkronos', // {isActive: true/false,...}
  needInitData: true,
  initDataKey: INIT_DATA_KEY,
  urlPath: GET_PUNCH_TYPES.substring(GET_PUNCH_TYPES.indexOf('opsmgt') + 6),
  validationMessageKey: 'validation_mkronos',
  loadInitData,
};
