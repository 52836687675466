import {StyleSheet} from 'react-native';

export const dhlBasicOrange = '#FFCC00';
export const dhlLightOrange = '#FFEEAD';
export const dhlBlack = '#323232';
export const dhlWhiteBasic = '#ffffff';
export const dhlSeparate = '#f2f2f2';
export const dhlRedButton = '#D40511';
export const dhlBlue = '#069BDE';
export const dhlMenu = '#fff0b3';
export const dhlGrey = '#989898';
export const buttonActiveOpacity = 0.8;
export const textColor = '#000';
export const buttonIconColor = '#000';
export const buttonSecondaryUnderlayColor = '#555';
export const fontSizeMedium = 18;

export const buttonText = {
  alignSelf: 'center',
  fontSize: fontSizeMedium,
  color: textColor,
};

export const button = {};

export const buttonContainer = {
  //alignSelf: 'stretch',
};

export const buttonIconStyle = {
  fontSize: 20,
  color: buttonIconColor,
};

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    minWidth: '100%',
    backgroundColor: dhlSeparate,
  },
  appContainer: {
    flex: 1,
    flexDirection: 'row',
    marginBottom: 5,
    backgroundColor: dhlSeparate,
    flexWrap: 'wrap',
  },
  mainContainer: {
    flex: 1,
    backgroundColor: dhlWhiteBasic,
    padding: 3,
  },
  plusButton: {
    position: 'absolute',
    bottom: 85, // 5 + height of menu
    right: 5,
    borderWidth: 1,
    borderColor: 'rgba(0,0,0,0.2)',
    alignItems: 'center',
    justifyContent: 'center',
    width: 50,
    height: 50,
    backgroundColor: dhlRedButton,
    borderRadius: 100,
  },
});
