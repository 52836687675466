import React, {useState} from 'react';
import {
  dhlWhiteBasic,
  Selector,
  navigationPopAction,
  navigationPushAction,
  getCurrentScreenSelector as getCurrentScreen,
} from 'smartops-shared';
import {connect} from 'react-redux';
import {storeSelectedUserGroupAction, storeUserGroupsAction, userGroupSelectedAction} from '../flows/auth';
import {logi} from '../features/logging';
import {ScaledSheet} from 'react-native-size-matters';
import {reinitializationAttemptAction} from '../flows/login';
import {isDeviceOnline, showToast} from 'features/platformSpecific';
import I18n from '../features/I18n';
import Metrics from 'features/metrics';

const SelectUserGroupScreen = props => {
  const {data, selectActive, checkRunningApps = false, runningAppsKeys} = props;
  const [selected, setSelected] = useState(props.selected);
  let previousSelected = selected;
  const onSelectCb = async item => {
    logi(`onSelectCb item: `, item);
    isDeviceOnline().then(isOnline => {
      if (isOnline) {
        if (selectActive) {
          if (checkRunningApps && runningAppsKeys && runningAppsKeys.length > 0) {
            props.navigationPushAction('dataWillBeLostScreen', {
              selectedUserGroupId: item.id,
            });
            return;
          } else if (checkRunningApps) {
            Metrics.trackEvent(Metrics.EventType.UserGroupSwitch, {usergroup_id: item.id});
            Metrics.storeUserGroupId(item.id);
            logi(`reinitializationAttemptAction`);
            item && props.reinitializationAttemptAction(item.id);
          } else {
            logi(`storeSelectedUserGroupAction`);
            item && props.storeSelectedUserGroupAction(item.id);
          }
        } else {
          item && props.storeUserGroupsAction(item.id);
        }

        if (props.currentScreen === 'selectUserGroupScreen') {
          props.navigationPopAction();
          props.userGroupSelectedAction();
        }
      } else {
        showToast(I18n.t('fileTransport.offlineModeError'));
        setTimeout(() => setSelected(null), 10);
        setTimeout(() => setSelected(previousSelected), 20);
      }
    });
  };
  return (
    <Selector
      data={data}
      selectedItem={selected}
      onSelect={item => onSelectCb && onSelectCb(item)}
      containerStyle={styles.container}
    />
  );
};

const styles = ScaledSheet.create({
  container: {
    backgroundColor: dhlWhiteBasic,
  },
});

function mapStateToProps(state, props) {
  const data =
    state.auth.userGroups &&
    state.auth.userGroups.map(ug => ({
      id: ug.id,
      name: ug.description,
    }));
  const selectedItem = data && data.find(g => g.id === props.selectedGroupId);
  return {
    data: data || [],
    selected: selectedItem,
    runningAppsKeys: state.apps.runningAppsKeys,
    currentScreen: getCurrentScreen(state),
  };
}

export default connect(mapStateToProps, {
  userGroupSelectedAction,
  storeUserGroupsAction,
  reinitializationAttemptAction,
  storeSelectedUserGroupAction,
  navigationPopAction,
  navigationPushAction,
  getCurrentScreen,
})(SelectUserGroupScreen);
