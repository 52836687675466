import React from 'react';
import {buttonSecondaryUnderlayColor, button, buttonText} from './commonStyles';
import {AppButton} from './AppButton';

const SecondaryButton = ({onPress, title, icon, containerViewStyle}) => {
  return (
    <AppButton
      underlayColor={buttonSecondaryUnderlayColor}
      onPress={onPress}
      title={title}
      icon={icon}
      buttonStyle={button}
      textStyle={buttonText}
      containerViewStyle={containerViewStyle}
    />
  );
};

export {SecondaryButton};
