import DeviceInfo from 'react-native-device-info';
import Moment from 'moment';
import moment from 'moment/min/moment-with-locales';
import {logi} from '../features/logging';

let tzOffset; // in minutes

export function getTzOffset() {
  if (!tzOffset) {
    try {
      const date = new Date();
      tzOffset = -date.getTimezoneOffset();
      logi(`Detected timezone offset in minutes from js Date is ${tzOffset}`);
      const tzName = DeviceInfo.getTimezone();
      tzOffset = Moment.tz(tzName).utcOffset();
      logi(`Detected timezone name from DeviceInfo is ${tzName} with offset ${tzOffset}`);
    } catch (err) {
      logi('Error detecting tz offset, using 0.' + err.message);
      tzOffset = 0;
    }
  }
  return tzOffset;
}

export function parseDateTime(val) {
  return new Date(new Date(...getParsedDate(val)).getTime() + getTzOffset() * 60000);
}

export function getParsedDate(date) {
  date = String(date).split(' ');
  const days = String(date[0]).split('-');
  const hours = String(date[1]).split(':');
  return [
    parseInt(days[0], 10),
    parseInt(days[1], 10) - 1,
    parseInt(days[2], 10),
    parseInt(hours[0], 10),
    parseInt(hours[1], 10),
    parseInt(hours[2], 10),
  ];
}

const parseLocaleForMoment = language => {
  if (language === 'en_US') return 'en';
  if (language === 'en_GB') return language.replace(`_`, `-`).toLowerCase();
  if (language.indexOf('_') === -1) return language;
  else return language.substr(0, language.indexOf('_'));
};

export const formatDateTime = (date, is24HourFormat = true, deviceLang) => {
  let lang = parseLocaleForMoment(deviceLang);
  if (lang === 'in') {
    lang = 'id';
  } else if (lang === 'iw') {
    lang = 'he';
  } else if (lang === 'ji') {
    lang = 'yi';
  }
  moment.locale(lang);
  const momentDate = moment(date).format('L');
  const momentTime = is24HourFormat ? moment(date).format('HH:mm') : moment(date).format('h:mm a');
  const formattedDate = `${momentDate} ${momentTime}`;
  return formattedDate;
};
