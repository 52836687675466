import {call, put, select, take} from 'redux-saga/effects';
import {getUserAppsSelector, getUserSelector} from './selectors';
import {LOG_SSO, logw} from './logging';
import {apiCall} from '../utils/Oauth';
import {logObject} from '../utils/Utils';
import {NativeModules} from 'react-native';
import {GET_POST_APP_SETTINGS, GET_POST_WMS_SSO, POST_WMS_PASSWORD, POST_WMS_PASSWORD_KEY} from '../config';
import {
  KEY_APP_SETTINGS,
  KEY_LOCKSCREEN_SHOWUP,
  KEY_USER_PASSWORDS,
  KEY_WMS_SSO_INIT_DATA,
  loadValue,
  saveValue,
} from './sharedPrefs';
import I18n from 'features/I18n';
import {
  getWmsData,
  storePasswordDurationAction,
  storeWebPaths,
  storeWmsSsoDataAction,
  storeWmsSsoMetaDataAction,
} from '../flows/app';
import {isEmpty} from 'lodash';
import DeviceInfo from 'react-native-device-info';
import Base64 from '../utils/Base64';
import EncryptedStorage from 'react-native-encrypted-storage';
import {uuidv4} from 'react-native-multiprocess-preferences';
import {decodePassword, encodePassword, writeAutoRemovableFile} from './security';
import {getAppSettingsValue} from '../flows/apps';
import {getSelectedUserGroup, getSmartScanCountry, getVelocityScanCountry} from '../flows/auth';
import {isWeb, showToast} from './platformSpecific';
import {splitToMultipleFilesAccordingToTheirPaths} from '../utils/UtilsTS';
import {loadSettings} from '../flows/settings';

//Constants
export const SMART_SCAN = 'smart_scan';
export const VELOCITY_SCAN = 'velocity_scan';

// Actions
const SET_PASS_IN_PROGRESS = 'SET_PASS_IN_PROGRESS';
const SET_PASS_API_MESSAGE = 'SET_PASS_API_MESSAGE';
const STORE_WMS_SHOW_PASSWORD = 'STORE_WMS_SHOW_PASSWORD';
const STORE__SMART_SHOW_PASSWORD = 'STORE__SMART_SHOW_PASSWORD';
const CREATE_FILESYSTEM_FILE = 'CREATE_FILESYSTEM_FILE';

const INITIAL_STATE = {
  settingNewPassInProgress: false,
  newPassApiResultMessage: null,
  wmsShowPassword: true,
  smartShowPassword: true,
};

// Reducer
export default function wmsSsoReducer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case SET_PASS_IN_PROGRESS:
      return {...state, settingNewPassInProgress: action.payload};
    case SET_PASS_API_MESSAGE:
      return {...state, newPassApiResultMessage: action.payload};
    case STORE_WMS_SHOW_PASSWORD:
      return {...state, wmsShowPassword: action.payload};
    case STORE__SMART_SHOW_PASSWORD:
      return {...state, smartShowPassword: action.payload};
    default:
      return state;
  }
}

// action creators
export const setPassInProgress = value => ({type: SET_PASS_IN_PROGRESS, payload: value});
export const setPassApiMessage = value => ({type: SET_PASS_API_MESSAGE, payload: value});
export const storeWmsShowPasswordAction = payload => ({type: STORE_WMS_SHOW_PASSWORD, payload});
export const storeSmartShowPasswordAction = payload => ({type: STORE__SMART_SHOW_PASSWORD, payload});
export const createWmsFilesystemFileAction = payload => ({type: CREATE_FILESYSTEM_FILE, payload});

export function* fetchAndStoreAppSettings() {
  const userApps = yield select(getUserAppsSelector);
  const userAppsKeys = userApps == null ? [] : Object.keys(userApps);
  userAppsKeys.push('portal');
  const result = yield call(fetchAppSettings, userAppsKeys);
  LOG_SSO.debug(`fetchAndStoreAppSettings result: `, result);
  if (result.isOk) {
    yield call(saveAppSettings, result.body);
  }
}

export function* fetchAndStoreWmsSso(userLogin) {
  const userApps = yield select(getUserAppsSelector);
  const userAppsKeys = Object.keys(userApps);
  if (userAppsKeys.includes(SMART_SCAN) || userAppsKeys.includes(VELOCITY_SCAN)) {
    //Check if device is rooted
    const isRooted = yield call(NativeModules.ParentNativeTools.isDeviceRooted);
    LOG_SSO.debug(`fetchAndStoreWmsSso isRooted: ${isRooted}`);
    if (isRooted) {
      showToast(I18n.t('errors.deviceRootedError'));
      return;
    }
    const result = yield call(fetchWmsSso, userLogin);
    const passKeyResult = yield call(getPasswordKeyForUser, userLogin);
    let passwordKey;
    LOG_SSO.debug(`fetchAndStoreWmsSso result: `, result);
    LOG_SSO.debug(`fetchAndStoreWmsSso passKeyResult: `, passKeyResult);
    if (passKeyResult.isOk) {
      passwordKey = Base64.atob(passKeyResult.body.base64); //Use key to decrypt passwords in init
    } else {
      yield call(wmsSsoProcessing, {}); //Should behave as if the init failed
      showToast(I18n.t('errors.wmsInitFailed'));
      return;
    }
    if (result.isOk && Array.isArray(result.body)) {
      yield call(wmsSsoProcessing, []); //In case that the user has access but there are no connections configured
    } else if (result.isOk) {
      const dataToUse = yield call(decryptAndReplacePasswords, result.body, passwordKey);
      yield call(wmsSsoProcessing, dataToUse);
    } else {
      yield call(wmsSsoProcessing, {}); //In case that the user has access but the data fetch failed
    }
  }
}

function* decryptAndReplacePasswords(data, decryptionKey) {
  const idPasswordPair = {};
  const scanners = ['wms_scanner', 'smart_scanner'];
  if (data && Object.keys(data).length > 0) {
    for (let scanner of scanners) {
      if (data[scanner] && data[scanner].identity_providers && data[scanner].identity_providers[0]) {
        if (
          data[scanner].identity_providers[0].identities &&
          Array.isArray(data[scanner].identity_providers[0].identities)
        ) {
          for (const identity of data[scanner].identity_providers[0].identities) {
            if (identity.password) {
              const id = uuidv4();
              let decodedPass;
              try {
                decodedPass = yield call(decodePassword, identity.password, decryptionKey);
              } catch (e) {
                LOG_SSO.warn('Password decoding failed', e);
              }
              if (decodedPass) {
                idPasswordPair[id] = decodedPass;
              } else {
                identity.error = 'errors.passwordDecryptionFailed';
              }
              identity.password = id;
            } else {
              // Add id field, to be used when adding password to this identity
              identity.id = uuidv4();
            }
          }
        }
      }
    }
  }
  yield call(storePasswordsToEncryptedStorage, idPasswordPair);
  return data;
}

function* createPasswordFieldWithId(id, clearError = false) {
  const data = yield select(getWmsData);
  const scanners = ['wms_scanner', 'smart_scanner'];
  if (data && Object.keys(data).length > 0) {
    for (let scanner of scanners) {
      if (data[scanner] && data[scanner].identity_providers && data[scanner].identity_providers[0]) {
        if (
          data[scanner].identity_providers[0].identities &&
          Array.isArray(data[scanner].identity_providers[0].identities)
        ) {
          for (const identity of data[scanner].identity_providers[0].identities) {
            if (identity.id === id && !clearError) {
              identity.password = id;
            } else if (id != null && identity.password === id && clearError) {
              delete identity.error;
            }
          }
        }
      }
    }
  }
  LOG_SSO.debug(`createPasswordFieldWithId 2 id ${id} data: `, data);
  yield call(wmsSsoProcessing, data); //update persistent storage, redux and metadata
}

function* storePasswordsToEncryptedStorage(passwordsObj) {
  try {
    yield call(EncryptedStorage.setItem, KEY_USER_PASSWORDS, JSON.stringify(passwordsObj));
  } catch (error) {
    // There was an error on the native side
    logw(`Error in storePasswordsToEncryptedStorage: `, error);
  }
}

export function* setNewPasswordById(passwordId, newPassword) {
  try {
    const passwordsStr = yield call(EncryptedStorage.getItem, KEY_USER_PASSWORDS);
    const passwords = JSON.parse(passwordsStr) || {};
    if (passwords) {
      passwords[passwordId] = newPassword;
      yield call(storePasswordsToEncryptedStorage, passwords);
    }
  } catch (error) {
    // There was an error on the native side
    logw(`Error in setNewPasswordById: `, error);
  }
}

function* fetchAppSettings(appsArr) {
  const url = GET_POST_APP_SETTINGS;
  let response = {};
  try {
    const timeoutInSeconds = yield call(getAppSettingsValue, 'app_settings_init.timeout');
    const timeout = timeoutInSeconds ? timeoutInSeconds * 1000 : 30000;
    const headers = {'Content-Type': 'application/json'};
    const body = {app_label: appsArr};
    const options = {url, headers, method: 'POST', body: JSON.stringify(body), addStatus: true, timeout};
    response = yield call(apiCall, options);
    LOG_SSO.debug(`Fetched app settings: `, logObject(response));
  } catch (e) {
    logw('Error loading app settings data', e);
  }
  if (response && response.status === 200 && response.body && Object.keys(response.body).length > 0) {
    LOG_SSO.debug(`fetchAppSettings response: `, response);
    return {body: response.body, isOk: true};
  } else {
    LOG_SSO.debug(`Error with App Settings response: `, response);
    showToast(I18n.t('errors.appSettingsFailed'));
    return {response, isOk: false};
  }
}

function* saveAppSettings(appSettings) {
  yield call(saveValue, KEY_APP_SETTINGS, JSON.stringify(appSettings));
  yield call(loadSettings);
  const webLockDisplayDuration = appSettings.portal ? appSettings.portal['lockscreen_showup'] || 600 : 600; // Seconds
  const duration = appSettings.portal ? appSettings.portal['wms_password.visibility_duration'] || 10 : 10;
  const webPathKeys = appSettings.portal ? Object.keys(appSettings.portal).filter(k => k.endsWith('_lite_path')) : [];
  const webPaths = Object.keys(appSettings.portal)
    .filter(key => webPathKeys.includes(key))
    .reduce((obj, key) => {
      obj[key] = appSettings.portal[key];
      return obj;
    }, {});
  yield put(storeWebPaths(webPaths || []));
  yield put(storePasswordDurationAction(duration));
  yield call(storeShowPass, appSettings);
  yield call(saveValue, KEY_LOCKSCREEN_SHOWUP, webLockDisplayDuration + '');
  if (isWeb) {
    // Update weblock in order to reload the LOCKSCREEN_SHOWUP value
    const event = new CustomEvent('user_logged_in', {
      detail: {
        userLoggedIn: true,
      },
    });
    window.dispatchEvent(event);
  }
}

function* storeShowPass(appSettings) {
  let showWmsPassword = true;
  let showSmartPassword = true;
  if (appSettings && appSettings.portal) {
    if (appSettings.portal['wms_scanner.sso_show_pwd_on'] != null) {
      showWmsPassword = JSON.parse(appSettings.portal['wms_scanner.sso_show_pwd_on']);
    }
    if (appSettings.portal['smart_scanner.sso_show_pwd_on'] != null) {
      showSmartPassword = JSON.parse(appSettings.portal['smart_scanner.sso_show_pwd_on']);
    }
  }
  if (typeof showWmsPassword !== 'boolean') {
    showWmsPassword = true;
  }
  if (typeof showSmartPassword !== 'boolean') {
    showSmartPassword = true;
  }
  yield put(storeWmsShowPasswordAction(showWmsPassword));
  yield put(storeSmartShowPasswordAction(showSmartPassword));
}

function* saveWmsSsoInitData(wmsSso) {
  yield call(saveValue, KEY_WMS_SSO_INIT_DATA, wmsSso ? JSON.stringify(wmsSso) : wmsSso);
}

export function* readWmsSsoDataAndStoreToRedux() {
  yield call(readWmsSsoInitDataAndStoreToRedux);
  yield call(readPasswordDurationAndStoreToRedux);
  yield call(readShowPasswordBooleanAndStoreToRedux);
}

function* readWmsSsoInitDataAndStoreToRedux() {
  const storedData = yield call(readWmsSsoData);
  yield put(storeWmsSsoDataAction(storedData));
  yield call(createWmsSsoMetadata, storedData);
  LOG_SSO.debug('Wms Sso init data stored to redux', logObject(storedData));
}

function* readPasswordDurationAndStoreToRedux() {
  const appSettings = yield call(readAppSettings);
  const duration =
    appSettings && appSettings.portal ? appSettings.portal['wms_password.visibility_duration'] || 10 : 10;
  yield put(storePasswordDurationAction(duration));
}

function* readShowPasswordBooleanAndStoreToRedux() {
  const appSettings = yield call(readAppSettings);
  yield call(storeShowPass, appSettings);
}

function* readWmsSsoData() {
  const stringValue = yield call(loadValue, KEY_WMS_SSO_INIT_DATA);
  return stringValue ? JSON.parse(stringValue) : null;
}

function* readAppSettings() {
  const stringValue = yield call(loadValue, KEY_APP_SETTINGS);
  return stringValue ? JSON.parse(stringValue) : null;
}

function* wmsSsoProcessing(data) {
  yield call(saveWmsSsoInitData, data);
  yield put(storeWmsSsoDataAction(data)); //Store to redux
  yield call(createWmsSsoMetadata, data);
}

function* createWmsSsoMetadata(data) {
  const metadata = {
    smartConnectionDetailsFailed: false,
    wmsConnectionDetailsFailed: false,
    smartConnectionsNumber: 0,
    wmsConnectionNumber: 0,
    smartAccountsNumber: 0,
    wmsAccountsNumber: 0,
    smartError: null,
    wmsError: null,
    smartConnectionAssigned: true,
    wmsConnectionAssigned: true,
  };
  if (data && Array.isArray(data)) {
    //Default values are already set
  } else if (data && Object.keys(data).length > 0) {
    //if (data.wms_scanner == null) metadata.wmsConnectionAssigned = false;
    //if (data.smart_scanner == null) metadata.smartConnectionAssigned = false;
    if (data.wms_scanner && data.wms_scanner.identity_providers && data.wms_scanner.identity_providers[0]) {
      if (data.wms_scanner.identity_providers[0].error_code === 'ERR03') {
        metadata.wmsConnectionDetailsFailed = true;
      }
      if (
        data.wms_scanner.identity_providers[0].identities &&
        Array.isArray(data.wms_scanner.identity_providers[0].identities)
      ) {
        const wmsConnectionsSet = new Set();
        let accountCounter = data.wms_scanner.identity_providers[0].identities.length;
        data.wms_scanner.identity_providers[0].identities.forEach(identity => {
          if (identity.connection_name) {
            wmsConnectionsSet.add(identity.connection_name);
          }
          if (identity.error) {
            metadata.wmsError = identity.error;
          }
        });
        metadata.wmsConnectionNumber = wmsConnectionsSet.size;
        metadata.wmsAccountsNumber = accountCounter;
      }
    }

    if (data.smart_scanner && data.smart_scanner.identity_providers && data.smart_scanner.identity_providers[0]) {
      if (data.smart_scanner.identity_providers[0].error_code === 'ERR03') {
        metadata.smartConnectionDetailsFailed = true;
      }
      if (
        data.smart_scanner.identity_providers[0].identities &&
        Array.isArray(data.smart_scanner.identity_providers[0].identities)
      ) {
        const smartConnectionsSet = new Set();
        let accountCounter = data.smart_scanner.identity_providers[0].identities.length;
        data.smart_scanner.identity_providers[0].identities.forEach(identity => {
          if (identity.connection_name) {
            smartConnectionsSet.add(identity.connection_name);
          }
          if (identity.error) {
            metadata.smartError = identity.error;
          }
        });
        metadata.smartConnectionsNumber = smartConnectionsSet.size;
        metadata.smartAccountsNumber = accountCounter;
      }
    }
  }
  yield put(storeWmsSsoMetaDataAction(metadata));
}

function* fetchWmsSso(userLogin) {
  let response = {};
  try {
    const timeoutInSeconds = yield call(getAppSettingsValue, 'wms_sso_init.timeout');
    const timeout = timeoutInSeconds ? timeoutInSeconds * 1000 : 30000;
    const headers = {'Content-Type': 'application/json'};
    const selectedUserGroupId = yield select(getSelectedUserGroup);
    const smartScanCountry = yield select(getSmartScanCountry);
    const velocityScanCountry = yield select(getVelocityScanCountry);
    const body = {
      smartops_user: userLogin,
      ...{smartops_user_group: selectedUserGroupId},
      ...{smart_scan_country: smartScanCountry},
      ...{velocity_scan_country: velocityScanCountry},
    };
    const options = {
      url: GET_POST_WMS_SSO,
      headers,
      method: 'POST',
      body: JSON.stringify(body),
      addStatus: true,
      timeout,
    };
    response = yield call(apiCall, options);
    LOG_SSO.debug(`Fetched wms-sso: `, logObject(response));
  } catch (e) {
    logw('Error loading wms-sso data', e);
  }
  if (response?.status === 200 && response.body && Object.keys(response.body).length > 0) {
    LOG_SSO.debug(`fetchWmsSso response: `, response);
    return {body: response.body, isOk: true};
  } else if (response?.status === 200 && response.body && Object.keys(response.body).length === 0) {
    LOG_SSO.debug(`fetchWmsSso response is empty: `, response);
    return {body: [], isOk: true};
  } else {
    LOG_SSO.debug(`Error with wms-sso response: `, response);
    showToast(I18n.t('errors.wmsInitFailed'));
    return {response, isOk: false};
  }
}

export function* setNewWmsPassword({payload}) {
  yield put(setPassInProgress(true));
  const {newPassword, identityData, appData} = payload;
  const {identity, isSmartScanner} = identityData;
  LOG_SSO.debug(`setNewWmsPassword identityData: `, identityData);
  LOG_SSO.debug(`setNewWmsPassword appData: `, appData);
  const user = yield select(getUserSelector);

  const passKeyResult = yield call(getPasswordKeyForUser, user.login);
  let passwordKey;
  LOG_SSO.debug(`setNewWmsPassword passKeyResult.isOk ${passKeyResult.isOk}`);
  if (passKeyResult.isOk) {
    passwordKey = Base64.atob(passKeyResult.body.base64);
  } else {
    //Show retry
    if (passKeyResult.error && passKeyResult.error.includes('timeout')) {
      yield put(setPassApiMessage(I18n.t('api.noResponseError')));
    } else if (passKeyResult.error && passKeyResult.error.includes('Network request failed')) {
      yield put(setPassApiMessage(I18n.t('errors.connectionNotAvailable')));
    } else {
      yield put(setPassApiMessage(I18n.t('api.serverError')));
    }
    yield put(setPassInProgress(false));
    return;
  }

  const multipleConnectionsWithSameNameBool = multipleConnectionsWithSameName(identity.connection_name, appData);
  LOG_SSO.debug(`multipleConnectionsWithSameNameBool: `, multipleConnectionsWithSameNameBool);
  const isConnectionNameAvailable = !!identity.connection_name;
  let bodyToSend = [];
  /*
   * rootObj {
   *   assignment: firstAssignmentObj {
   *                     assignment: secondAssignmentObj
   *               }
   * }
   * */
  const rootObj = {
    username: user.login,
    attribute_level: !isConnectionNameAvailable ? 'app_attribute' : 'attribute_attribute',
    attribute_label: 'wms_password',
    value: yield call(encodePassword, newPassword, passwordKey),
  };
  if (!isConnectionNameAvailable) {
    rootObj.app_label = isSmartScanner ? SMART_SCAN : VELOCITY_SCAN;
  }
  let firstAssignmentObj;
  let secondAssignmentObj;
  if (isConnectionNameAvailable && !multipleConnectionsWithSameNameBool) {
    firstAssignmentObj = {
      attribute_level: 'role_attribute',
      role_label: isSmartScanner ? 'smart_scanner_user' : 'velocity_scan_user',
      attribute_label: isSmartScanner ? 'smart_scanner_connection' : 'wms_scanner_connection',
      value: identity.connection_name,
    };
    rootObj.assignment = firstAssignmentObj;
  }
  if (isConnectionNameAvailable && multipleConnectionsWithSameNameBool) {
    secondAssignmentObj = {
      attribute_level: 'role_attribute',
      role_label: isSmartScanner ? 'smart_scanner_user' : 'velocity_scan_user',
      attribute_label: isSmartScanner ? 'smart_scanner_connection' : 'wms_scanner_connection',
      value: identity.connection_name,
    };
    firstAssignmentObj = {
      attribute_level: 'attribute_attribute',
      attribute_label: 'wms_user_id_multi',
      value: identity.username,
      assignment: secondAssignmentObj,
    };
    rootObj.assignment = firstAssignmentObj;
  }
  bodyToSend.push(rootObj);

  const url = POST_WMS_PASSWORD;
  LOG_SSO.debug(`bodyToSend: `, bodyToSend);
  let response = {};
  let isTimeoutError = false;
  try {
    const headers = {'Content-Type': 'application/json'};
    const options = {
      url,
      headers,
      method: 'POST',
      body: JSON.stringify(bodyToSend),
      addStatus: true,
      canUseRefreshToken: true,
    };
    response = yield call(apiCall, options);
    LOG_SSO.debug(`Set password response: `, logObject(response));
  } catch (e) {
    logw('Error settings password', e);
    if (e.message.includes('timeout')) {
      isTimeoutError = true;
    }
  }
  if (response && response.status === 200 && response.body) {
    yield put(setPassApiMessage('success'));
    //Update existing pass, and handle if it does not exist
    if (!identityData.identity.password && identityData.identity.id) {
      //create field password in the data (for this identity) and add the id as value
      LOG_SSO.debug(`calling createPasswordFieldWithId`);
      yield call(createPasswordFieldWithId, identityData.identity.id);
    } else {
      //Clear any errors for this identity
      yield call(createPasswordFieldWithId, identityData.identity.password, true);
    }
    yield call(setNewPasswordById, identityData.identity.password || identityData.identity.id, newPassword);
  } else {
    LOG_SSO.debug(`Error with set password response: `, response);
    if (response && response.status == null) {
      if (isTimeoutError) {
        yield put(setPassApiMessage(I18n.t('api.noResponseError')));
      } else {
        yield put(setPassApiMessage(I18n.t('errors.connectionNotAvailable')));
      }
    } else if (response && response.status >= 400 && response.status < 500) {
      yield put(setPassApiMessage(I18n.t('api.serverError')));
    } else if (response && response.status >= 500) {
      yield put(setPassApiMessage(I18n.t('api.serverError')));
    }
  }
  yield put(setPassInProgress(false));
}

function multipleConnectionsWithSameName(connectionName, data) {
  let connectionNameCounter = 0;
  if (data && Array.isArray(data)) {
    for (let identity of data) {
      if (identity.connection_name === connectionName) {
        connectionNameCounter++;
      }
    }
  }
  return connectionNameCounter >= 2;
}

export function* getPasswordKeyForUser(userLogin) {
  const url = POST_WMS_PASSWORD_KEY;
  let response = {};
  let error;
  try {
    const timeoutInSeconds = yield call(getAppSettingsValue, 'wms_sso_key.timeout');
    const timeout = timeoutInSeconds ? timeoutInSeconds * 1000 : 30000;
    const headers = {'Content-Type': 'application/json'};
    const body = {smartops_user: userLogin};
    const options = {url, headers, method: 'POST', body: JSON.stringify(body), addStatus: true, timeout};
    response = yield call(apiCall, options);
    LOG_SSO.debug(`Fetched password key: `, logObject(response));
  } catch (e) {
    logw('Error fetching password key', e);
    error = e.message;
  }
  if (response && response.status === 200 && response.body && Object.keys(response.body).length > 0) {
    LOG_SSO.debug(`getPasswordKeyForUser response: `, response);
    return {body: response.body, isOk: true};
  } else {
    LOG_SSO.debug(`Error with password key response: `, response);
    showToast(I18n.t('errors.keyFetchFailed'));
    return {response, isOk: false, error: error};
  }
}

export function* prepareWmsSsoFileForSharingWatcher() {
  // SO-2852
  while (true) {
    LOG_SSO.debug(`prepareWmsSsoFileForSharingWatcher scanner is ready`);
    const action = yield take(CREATE_FILESYSTEM_FILE);
    const scanner = action.payload;
    const scannerInfo = yield getScannerFileInfo(scanner);
    if (scannerInfo == null) continue;
    const {
      wmsSsoData,
      macAddress,
      removeAllPasswords,
      removeAllUsernames,
      storageLocation,
      filenameToUse,
      fileDuration,
    } = scannerInfo;
    LOG_SSO.debug(
      `removeAllPasswords ${removeAllPasswords} removeAllUsernames ${removeAllUsernames} filenameToUse ${filenameToUse}`,
    );
    let fileToShare = {};
    const passwordsStr = yield call(EncryptedStorage.getItem, KEY_USER_PASSWORDS);
    const passwords = JSON.parse(passwordsStr) || {};
    let fullFilenameLocation;
    let isSeparatePathPerConn = false;
    if (wmsSsoData[scanner] != null) {
      fileToShare.identity_providers = [];
      wmsSsoData[scanner].identity_providers.map(ip =>
        fileToShare.identity_providers.push({
          name: ip.name,
          identities: ip.identities
            .map(ipi => {
              if (!isSeparatePathPerConn && ipi.path != null) {
                isSeparatePathPerConn = true;
              }
              let id = {
                ...ipi,
                password: undefined,
                ...(removeAllUsernames && {username: undefined}),
                ...(!removeAllPasswords &&
                  ipi.password &&
                  passwords[ipi.password] && {password: passwords[ipi.password]}),
                ...(ipi.use_device_mac_address && {terminal_id: macAddress}),
                use_device_mac_address: undefined,
                connection_name: undefined,
                id: undefined,
                error: undefined,
              };
              Object.keys(id).forEach(k => id[k] == null && delete id[k]);
              return id;
            })
            .filter(i => !isEmpty(i)),
        }),
      );
      let filename;

      if (scanner === 'wms_scanner') {
        filename = `${filenameToUse ? filenameToUse : 'wms_scanner_sso'}.json`;
      } else {
        filename = `${filenameToUse ? filenameToUse : 'smart_scanner_sso'}.json`;
      }
      LOG_SSO.debug(`prepareWmsSsoFileForSharingWatcher fileDuration: ${fileDuration} fileToShare: `, fileToShare);
      if (isSeparatePathPerConn) {
        const files = splitToMultipleFilesAccordingToTheirPaths(fileToShare);
        files.forEach(file => {
          const path = file.path;
          fullFilenameLocation = `${path}/${filename}`;
          delete file['path'];
          writeAutoRemovableFile(fullFilenameLocation, fileDuration * 1000, JSON.stringify(file));
        });
      } else {
        //previous implementation
        fullFilenameLocation = `${storageLocation}/${filename}`;
        writeAutoRemovableFile(fullFilenameLocation, fileDuration * 1000, JSON.stringify(fileToShare));
      }
    }
  }
}

export function* getScannerFileInfo(scanner) {
  LOG_SSO.debug(`prepareWmsSsoFileForSharingWatcher scanner `, scanner);
  const wmsSsoData = yield call(readWmsSsoData); //Can be null or empty object
  const appSettings = yield call(readAppSettings); //Can be null
  if (wmsSsoData == null || isEmpty(wmsSsoData) || appSettings == null) {
    LOG_SSO.debug(
      `prepareWmsSsoFileForSharingWatcher missing wmsSsoData or appSettings, skipping WMS SSO file preparation`,
    );
    return null;
  }
  let shareFile = true;
  if (scanner === 'smart_scanner' && appSettings.portal[`${scanner}.wms_sso_file_sharing_on`] != null) {
    shareFile = JSON.parse(appSettings.portal[`${scanner}.wms_sso_file_sharing_on`]);
  } else if (scanner === 'wms_scanner' && appSettings.portal[`${scanner}.sso_file_sharing_on`] != null) {
    shareFile = JSON.parse(appSettings.portal[`${scanner}.sso_file_sharing_on`]);
  }
  LOG_SSO.debug(`prepareWmsSsoFileForSharingWatcher shareFile: `, shareFile, typeof shareFile);
  if (shareFile === false) {
    return null; //Do not share the file
  }
  const macAddress = yield DeviceInfo.getMacAddress();
  let removeAllPasswords = false;
  let removeAllUsernames = false;
  let storageLocation,
    filenameToUse,
    fileDuration = 10;
  if (
    appSettings.portal[`${scanner}.sso_file_pwd_on`] &&
    JSON.parse(appSettings.portal[`${scanner}.sso_file_pwd_on`]) === false
  ) {
    removeAllPasswords = true;
  }
  if (
    appSettings.portal[`${scanner}.sso_file_username_on`] &&
    JSON.parse(appSettings.portal[`${scanner}.sso_file_username_on`]) === false
  ) {
    removeAllUsernames = true;
  }
  LOG_SSO.debug(
    `prepareWmsSsoFileForSharingWatcher removeAllPasswords: ${removeAllPasswords}, removeAllUsernames: ${removeAllUsernames}`,
  );
  if (scanner === 'smart_scanner' && appSettings.portal[`${scanner}.wms_sso_file_name`] != null) {
    filenameToUse = appSettings.portal[`${scanner}.wms_sso_file_name`];
  } else if (scanner === 'wms_scanner' && appSettings.portal[`${scanner}.sso_file_name`] != null) {
    filenameToUse = appSettings.portal[`${scanner}.sso_file_name`];
  }

  if (scanner === 'smart_scanner' && appSettings.portal[`${scanner}.wms_sso_file_destination_path`] != null) {
    storageLocation = appSettings.portal[`${scanner}.wms_sso_file_destination_path`];
  } else if (scanner === 'wms_scanner' && appSettings.portal[`${scanner}.sso_file_destination_path`] != null) {
    storageLocation = appSettings.portal[`${scanner}.sso_file_destination_path`];
  }
  if (!storageLocation || storageLocation.trim().length === 0) {
    showToast(I18n.t('wmsSettings.pathNotDefined'));
    return null;
  }
  if (scanner === 'smart_scanner' && appSettings.portal[`${scanner}.wms_sso_file_duration`] != null) {
    fileDuration = JSON.parse(appSettings.portal[`${scanner}.wms_sso_file_duration`]);
  } else if (scanner === 'wms_scanner' && appSettings.portal[`${scanner}.sso_file_duration`] != null) {
    fileDuration = JSON.parse(appSettings.portal[`${scanner}.sso_file_duration`]);
  }
  return {
    wmsSsoData,
    macAddress,
    removeAllPasswords,
    removeAllUsernames,
    storageLocation,
    filenameToUse,
    fileDuration,
  };
}
