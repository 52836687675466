import {call, select} from 'redux-saga/effects';
import {apiCall} from '../../utils/Oauth';
import {logd, logi} from '../logging';
import {saveValue} from '../sharedPrefs';
// eslint-disable-next-line no-unused-vars
import {extractIdsOfGivenTypeFromDl, logObject} from '../../utils/Utils';
import I18n from 'features/I18n';
import {ACTIVE_ENV} from '../../configs/active-env';
import {isEmpty} from 'lodash';
import {getSelectedUserGroup} from '../../flows/auth';
import {showToast} from 'features/platformSpecific';

const INIT_DATA_KEY = 'near_miss';
const GET_NEAR_MISS_DATA_URL = `${ACTIVE_ENV.gatewayUrl}/opsmgt/v1/initwatch/nearmiss?`;

function* loadInitData(user, roles) {
  logi('Fetching Logics init data');
  const facilityIds = extractIdsOfGivenTypeFromDl(roles, 'Facility');
  if (facilityIds.length === 0 || facilityIds.length > 1) {
    logi(`nearmiss facilityIds.length:`, facilityIds.length);
    logi(`nearmiss facilityIds:`, facilityIds);
  }
  const selectedUserGroup = yield select(getSelectedUserGroup);
  const headers = {'X-messagename': 'OPSINIT', 'x-direction': 'umv2'};
  const facility = facilityIds[0]; //If the user has multiple Facilities, it's wrong configuration
  try {
    let url = `${GET_NEAR_MISS_DATA_URL}facility=${facility}&user=${user.login}&language_code=${user.language}`;
    if (selectedUserGroup) {
      url = `${url}&user_group=${selectedUserGroup}`;
    }
    const response = yield call(apiCall, {url, headers});
    if (response && !isEmpty(response)) {
      //logd('Fetched Near Miss init data ', logObject(response));
      yield call(saveValue, INIT_DATA_KEY, JSON.stringify(response));
    } else {
      showToast(I18n.t('errors.nearMissInitFailed'));
      logi('Error Near Miss init data response object is empty: ', response);
    }
  } catch (e) {
    showToast(I18n.t('errors.nearMissInitFailed'));
    logi('Error loading Near Miss init data', e);
  }
}

export default {
  label: 'mLOGICS', // in UM
  title: 'mLogics',
  restartKey: 'restart_mlogics',
  activeTaskKey: 'active_task_mlogics', // {isActive: true/false,...}
  needInitData: true,
  initDataKey: INIT_DATA_KEY,
  urlPath: GET_NEAR_MISS_DATA_URL.substring(GET_NEAR_MISS_DATA_URL.indexOf('opsmgt') + 6),
  validationMessageKey: 'validation_mlogics',
  loadInitData,
};
