import user from './user.json';
import applications from './applications.json';
import tokens from './tokens.json';
import kioskConfig from './kiosk_config.json';
import bbs from './bbs.json';
import cs from './cs.json';
import gemba from './gemba.json';
import indapr from './indapr.json';
import mpts from './mpts.json';
import mwlm from './mwlm.json';
import punchtypes from './punchtypes.json';
import reportinglocation from './reportinglocation.json';
import weblinks from './weblinks.json';
import indaprRequestCount from './indapr_request_count.json';
import user_gql from './user_gql.json';
// eslint-disable-next-line no-unused-vars
import user_gql_groups from './user_gql_groups.json';
import change_group_resp from './change_group_resp.json';
import applications_gql from './applications_gql.json';
import files from './files.json';
import file from './file.json';
import appSettingsResponse from './app_settings_response.json';
import wmsSsoInit from './wms_sso_init_resp.json';
import {ACTIVE_ENV} from '../configs/active-env';
import {logd} from 'features/logging';
import {KEY_APP_SETTINGS, KEY_USER_JSON_RESP} from '../features/sharedPrefs';

export const isInternetAvailable = async () => true;

export const loadValueMock = async key => {
  logd(`Mocked load ${key}`);
  switch (key) {
    case 'locale':
      return 'cs';
    case 'username':
      return user.login;
    case 'firstName':
      return user.firstname;
    case 'lastName':
      return user.lastname;
    case 'userApps':
      return JSON.stringify(user.roles);
    case KEY_APP_SETTINGS:
      return JSON.stringify(appSettingsResponse);
    case 'mwlm':
      return JSON.stringify(mwlm);
    case 'weblinks': // INIT_DATA_KEY
      return JSON.stringify(weblinks);
    case KEY_USER_JSON_RESP:
      return JSON.stringify(user_gql);
    // case 'indApr':
    //     // return JSON.stringify(initData);
    default:
      return undefined;
  }
};

function getResponseForUrl(url) {
  const base = ACTIVE_ENV.newGatewayUrl + '/smartops';
  const newUM = base + '/um';
  if (url.match(/\/opsmgt\/v1\/initwatch\/translation\/language\/cs\/timestamp\//g)) {
    return cs;
  }
  switch (url) {
    case `${base}/um/oauth/token?grant_type=password`:
    case `${base}/um/auth/oauth/token?grant_type=password`:
    case `${newUM}/auth/oauth/token?grant_type=password`:
      return tokens;
    case `${base}/um/api/userDetails`:
      return user;
    case `${base}/um/api/applications`:
      return applications;
    case `${base}/opsmgt/v1/initwatch/cdm/facilities/AU_0053/weblinks`:
      return weblinks;
    case `${base}/opsmgt/v1/initwatch/logicsfacilities/AU_0053/reportinglocation`:
      return reportinglocation;
    case `${base}/opsmgt/v1/initwatch/kronos/facilities/AU_0053/punchtypes`:
      return punchtypes;
    case `${base}/opsmgt/v1/initwatch/wlm/uomreferenceid/facilities/KE_0040/warehouse/WMD1`:
      return mwlm;
    case `${base}/opsmgt/v1/initwatch/mpts/instance_name/PTS_UAT/source_system_set_name/SMARTOPS_0001`:
      return mpts;
    case `${base}/opsmgt/v1/initwatch/logics/facilities/AU_0053/users/pm/gemba`:
      return gemba;
    case `${base}/opsmgt/v1/initwatch/logics/facilities/AU_0053/users/pm/bbs`:
      return bbs;
    case `${base}/opsmgt/v1/initwatch/indapr/facilities/KE_0040/warehouse/WMD5`:
      return indapr;
    case `${base}/opsmgt/v1/wlm/numberindirectrequests/facilities/KE_0040/warehouse/WMD5/users/pm/role/manager`:
      return indaprRequestCount;
    case `${newUM}/users/graphql`:
      return user_gql; //user_gql_groups //for multiple groups test
    case `${newUM}/core/graphql`:
      return applications_gql;
    case `${base}/opsmgt/v1/initwatch/file-transport`:
      return files;
    case `${base}/opsmgt/v1/file-transport/112233`:
      return file;
    case `${base}/opsmgt/v1/initwatch/app-configuration`:
      return appSettingsResponse;
    case `${base}/opsmgt/v1/initwatch/wms-sso`:
      return wmsSsoInit;
    case `${base}/opsmgt/v1/wms-sso/wms-password`:
      return change_group_resp;
    case `${base}/opsmgt/healthcheck`:
      return {};
    default:
      console.warn(`Response from ${url} was not mocked - returning empty result`);
      return {};
  }
}

export const fetchMock = async (url, options) => {
  logd(`Mocking call to ${url}`);
  return {
    status: 200,
    headers: [],
    json: async () => getResponseForUrl(url),
  };
};

export const promiseApiCall = async options => {
  await new Promise(resolve => setTimeout(resolve, 100));
  return fetchMock(options.url);
};

export const getKioskConfig = () => kioskConfig;

export const saveValueMock = async (key, value) => logd(`Mocked store ${key}`, value);

/*

Offline mode:
  - home goes to login
  - menu only About page
  - emergency apps by order, then normal_apps by order
  - Offline label in top bar
Online mode
  - UM online apps as usual
  - Kiosk folder
    - emergency apps without online apps (emergency.id = um_label)
    - followed by normal_apps
 */
