const authorizationsElement = `authorizations{
            role{
                label
                name
                application{
                    label
                }
            }
            application{
                label
            }
            attributes {
                value
                allValue
                attribute {
                    id
                    label
                    name
                    type
                }
            }
        }`;

export const userDetails = login => `query {
    userByLogin(login: "${login}") {
        id
        authType
        passwordType
        login
        userDetail{
            email
            firstName
            lastName
            country{
                id
                name
                code
            }
            language{
                code
                name
            }
        }
        ${authorizationsElement}
        assignedGroups{
          id
          attributes {
            value
            attribute {
                label
            }
          }
          group{
            id
            name
            description
            ${authorizationsElement}
          }
        }
        showPasswordWillExpireNotification
        daysToPasswordExpiration
        changePasswordAtNextLogin
    }
}`;

export const applications = `query {
    allApplications{
        id
        label
        logo
        name
        order
        url
        mobile
    }
}`;

const attributeValueName = (attributeId, valueIds) => {
  let choiceIds = [...valueIds.filter(v => v != null)];
  return {
    attributeId,
    choiceIds,
  };
};

const attributesValueNamesBody = (attributeValues = []) => {
  let arr = [];
  attributeValues?.forEach(av => arr.push(attributeValueName(av.attributeId, av.valueIds)));
  return arr;
};

export const attributeValueNames = attributeValues => ({
  variables: {requested: attributesValueNamesBody(attributeValues)},
  query: `query ($requested: [AttributeValuesInput]) {
            attributeValues(input: $requested) {
              attributeId
              choices {
                id
                code
                name
              }
            }
          }`,
});

export const changePinBody = (oldPass, newPass, login) => `mutation {
    changePIN(data: {login: "${login}", currentPassword: "${oldPass}", newPassword: "${newPass}"})
}`;

export const changePasswordBody = (oldPass, newPass, login) => `mutation {
    changePassword(data: {login: "${login}", currentPassword: "${oldPass}", newPassword: "${newPass}"})
}`;
