import {call, put, take} from 'redux-saga/effects';
import {getAppSettingsValue} from './apps';
import {setTimeoutForNotification} from '../App';

const SET_NOTIFICATION_ATTRIBUTES = 'SET_NOTIFICATION_ATTRIBUTES';
const SET_UM_CHANGE_NOTIFICATION_ATTRIBUTES = 'SET_UM_CHANGE_NOTIFICATION_ATTRIBUTES';
const SET_SHOW_NOTIFICATION = 'SET_SHOW_NOTIFICATION';
const RELOAD_UM_CHANGE_NOTIFICATION_VALUES = 'RELOAD_UM_CHANGE_NOTIFICATION_VALUES';
const DISABLE_NOTIFICATIONS = 'DISABLE_NOTIFICATIONS';

const DEFAULT_STATE = {
  notificationMessageFromDate: undefined,
  notificationMessageToDate: undefined,
  notificationShowFromDate: undefined,
  notificationShowUntilDate: undefined,
  displayNotification: false,
  showNotification: false,

  umChangeDisplayNotification: false,
  umChangeNotificationMessageFromDate: undefined,
  umChangeNotificationMessageToDate: undefined,
  umChangeNotificationShowFromDate: undefined,
  umChangeNotificationShowUntilDate: undefined,
};

// reducer
export default function notificationReducer(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case SET_NOTIFICATION_ATTRIBUTES:
      let {displayNotification, notificationShowFromDate, notificationShowUntilDate} = action.payload;
      let showNotification = false;
      if (displayNotification) {
        const now = new Date();
        const from = new Date(notificationShowFromDate);
        const to = new Date(notificationShowUntilDate);
        if (now < to && now > from) {
          showNotification = true;
        }
      }
      return {...state, ...action.payload, showNotification: showNotification};
    case SET_UM_CHANGE_NOTIFICATION_ATTRIBUTES:
      let {umChangeDisplayNotification, umChangeNotificationShowFromDate, umChangeNotificationShowUntilDate} =
        action.payload;
      let showNotif = false;
      if (umChangeDisplayNotification) {
        const now = new Date();
        const from = new Date(umChangeNotificationShowFromDate);
        const to = new Date(umChangeNotificationShowUntilDate);
        if (now < to && now > from) {
          showNotif = true;
          setTimeoutForNotification(to);
        }
      }
      return {...state, ...action.payload, umChangeDisplayNotification: showNotif};
    case SET_SHOW_NOTIFICATION:
      return {...state, showNotification: action.payload};
    case DISABLE_NOTIFICATIONS:
      return {...state, showNotification: false, umChangeDisplayNotification: false};
    default:
      return state;
  }
}

export const disableNotificationsAction = () => ({type: DISABLE_NOTIFICATIONS});
export const setNotificationAttributesAction = payload => ({type: SET_NOTIFICATION_ATTRIBUTES, payload});
export const setUmChangeNotificationAttributesAction = payload => ({
  type: SET_UM_CHANGE_NOTIFICATION_ATTRIBUTES,
  payload,
});
export const setShowNotification = payload => ({type: SET_SHOW_NOTIFICATION, payload});

export function* loadAppSettingsNotificationValues() {
  const displayNotificationStr = yield call(getAppSettingsValue, 'login_notification.display');
  const displayNotification = displayNotificationStr != null ? JSON.parse(displayNotificationStr) : false;
  const notificationMessageFromDate = yield call(getAppSettingsValue, 'login_notification.message_from'); //'from' date to add to message
  const notificationMessageToDate = yield call(getAppSettingsValue, 'login_notification.message_to'); //'to' date to add to message
  const notificationShowFromDate = yield call(getAppSettingsValue, 'login_notification.display_from'); //From when to display notification
  const notificationShowUntilDate = yield call(getAppSettingsValue, 'login_notification.display_to'); //Until when to display notification

  yield put(
    setNotificationAttributesAction({
      displayNotification,
      notificationMessageFromDate,
      notificationMessageToDate,
      notificationShowFromDate,
      notificationShowUntilDate,
    }),
  );
}

export function* umChangeNotificationValuesWatcher() {
  while (true) {
    yield take(RELOAD_UM_CHANGE_NOTIFICATION_VALUES);
    yield call(loadAppSettingsUmChangeNotificationValues);
  }
}

export function* loadAppSettingsUmChangeNotificationValues() {
  const displayNotificationStr = yield call(getAppSettingsValue, 'um_change_notification.display');
  const umChangeDisplayNotification = displayNotificationStr != null ? JSON.parse(displayNotificationStr) : false;
  const umChangeNotificationMessageFromDate = yield call(getAppSettingsValue, 'um_change_notification.message_from'); //'from' date to add to message
  const umChangeNotificationMessageToDate = yield call(getAppSettingsValue, 'um_change_notification.message_to'); //'to' date to add to message
  const umChangeNotificationShowFromDate = yield call(getAppSettingsValue, 'um_change_notification.display_from'); //From when to display notification
  const umChangeNotificationShowUntilDate = yield call(getAppSettingsValue, 'um_change_notification.display_to'); //Until when to display notification

  yield put(
    setUmChangeNotificationAttributesAction({
      umChangeDisplayNotification,
      umChangeNotificationMessageFromDate,
      umChangeNotificationMessageToDate,
      umChangeNotificationShowFromDate,
      umChangeNotificationShowUntilDate,
    }),
  );
}
