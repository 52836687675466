import {promiseApiCall as enqueueFromLib, getIMEI, uuidv4} from 'react-native-multiprocess-preferences';
import DeviceInfo from 'react-native-device-info';
import {logd, logw} from 'features/logging';
import {
  saveValue,
  loadValue,
  KEY_METRICS_EVENT_SESSION_ID,
  KEY_LOGIN,
  KEY_API_GW_URL_CLEAN,
  KEY_USER_GROUP_ID,
  KEY_GROUP_IDS_FACILITIES_MAP,
  KEY_GROUP_IDS_COUNTRIES_MAP,
} from 'features/sharedPrefs';
import {getUniqueDeviceId, isQa, isWeb} from 'features/platformSpecific';
import {apiCallPromise} from 'src/utils/Oauth';
import {appVersion} from '../../configs/version';

const getSOURL = async () => {
  const gateway = await loadValue(KEY_API_GW_URL_CLEAN); //This is needed for web, native could use the placeholder APIGEE_GW_URL_ROOT
  return `${gateway}/smartops/um/auth/api/v1/usagelog`;
};

const EventType = {
  // screen lock
  LockScreen: 'lock_screen',
  // regular login
  Login: 'login',
  // usergroup switch
  UserGroupSwitch: 'usergroup_switch',
  // automatic logout
  AutomaticLogout: 'automatic_logout',
  // open app
  OpenApp: 'open_app',
  // open kiosk app
  OpenKioskApp: 'open_kiosk_app',
  // standard logout in case that we are offline
  Logout: 'logout',
};
const EventTypeValues = Object.values(EventType);

const getLoginEventAttributes = async (session_id = undefined, isLockScreen = false) => {
  const device_model = DeviceInfo.getModel();
  const device_manufacturer = await DeviceInfo.getManufacturer();
  let device_mac = undefined;
  let android_version = undefined;
  let imei = undefined;
  if (!isWeb) {
    android_version = await DeviceInfo.getApiLevel();
    device_mac = await getUniqueDeviceId();
    imei = await getIMEI().then(result => {
      if (result && result.length !== 0 && result[0] != null) {
        return result.join(', ');
      }
      return '';
    });
  }
  const version = !isWeb ? DeviceInfo.getVersion() : isQa() ? `${appVersion}-qa` : appVersion;
  return {
    event_type: isLockScreen ? EventType.LockScreen : EventType.Login, // Type of login (Regular or unlock screen for Lite)
    event_time: new Date().toISOString(),
    ...(isWeb && {user_agent: navigator.userAgent}), // Browser’s User-Agent (for Lite only)
    ...(!isWeb && {device_model}), // Device model (for Android)
    ...(!isWeb && {device_manufacturer}), // Device manufacturer (for Android)
    ...(!isWeb && {device_mac}), // Device MAC (for Android)
    ...(!isWeb && {android_version}), // Android version
    ...(!isWeb && {imei}), // IMEI (for Android)
    version, // Parent version
  };
};

const getSessionId = async () => {
  return await loadValue(KEY_METRICS_EVENT_SESSION_ID);
};

const storeSessionId = async sessionId => {
  logd(`Metrics storing session_id: '${sessionId}'`);
  return await saveValue(KEY_METRICS_EVENT_SESSION_ID, sessionId);
};

const getUserGroupId = async () => {
  return await loadValue(KEY_USER_GROUP_ID);
};

const storeUserGroupId = userGroupId => {
  saveValue(KEY_USER_GROUP_ID, userGroupId);
};

const trackEvent = async (
  eventType, // : EventType,
  optionalProperties,
) => {
  if (!EventTypeValues.includes(eventType)) {
    throw new Error(`trackEvent error: unknown eventType ${eventType}, use one of ${EventTypeValues.join(', ')}`);
  }
  const sessionId = await getSessionId();
  if (sessionId == null) {
    logd(`Metrics tracking event: '${eventType}', no sessionId stored, ignoring event`);
    return;
  }

  const username = await loadValue(KEY_LOGIN);
  if (!username) {
    logd(`Metrics tracking event: '${eventType}', no username stored, ignoring event`);
    return;
  }

  if (eventType === EventType.UserGroupSwitch) {
    //add countries, facilities
    let groupsAndFacilities = await loadValue(KEY_GROUP_IDS_FACILITIES_MAP);
    let groupsAndCountries = await loadValue(KEY_GROUP_IDS_COUNTRIES_MAP);
    if (groupsAndFacilities != null) {
      groupsAndFacilities = JSON.parse(groupsAndFacilities);
    }
    if (groupsAndCountries != null) {
      groupsAndCountries = JSON.parse(groupsAndCountries);
    }
    optionalProperties = {
      ...optionalProperties,
      ...(!!groupsAndCountries &&
        groupsAndCountries[optionalProperties.usergroup_id] && {
          country: groupsAndCountries[optionalProperties.usergroup_id].toString(),
        }),
      ...(!!groupsAndFacilities &&
        groupsAndFacilities[optionalProperties.usergroup_id] && {
          facility: groupsAndFacilities[optionalProperties.usergroup_id].toString(),
        }),
    };
  }

  if (optionalProperties) {
    Object.keys(optionalProperties).forEach(k => {
      if (!['usergroup_id', 'facility', 'country', 'app_label', 'event_time'].includes(k)) {
        throw new Error(`trackEvent error: unknown key in optionalProperties: ${k}`);
      }
    });
  }

  const event = {
    username,
    event_type: eventType,
    session_id: sessionId,
    event_time: new Date().toISOString(),
    ...optionalProperties,
  };

  const metricsPostURL = await getSOURL();
  if (!metricsPostURL) {
    logd(`Metrics tracking event: '${eventType}', no URL stored, ignoring event`);
    return;
  }
  let options = {
    url: metricsPostURL,
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(event),
    addToken: true,
    method: 'POST',
    priority: 5,
    wantResponse: 'no',
  };
  logd(`Metrics tracking event: '${eventType}', URL: '${metricsPostURL}' data: ${JSON.stringify(event)}`);
  if (isWeb) {
    try {
      return await apiCallPromise(options);
    } catch (e) {
      logw(`Metrics tracking event error: `, e);
    }
  } else {
    const uuid = uuidv4();
    return await enqueueFromLib({...options, uuid});
  }
};

export default {
  EventType,
  getLoginEventAttributes,
  getSessionId,
  storeSessionId,
  getUserGroupId,
  storeUserGroupId,
  trackEvent,
};
