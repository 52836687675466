import {call, all, put} from 'redux-saga/effects';
import {apiCall} from '../utils/Oauth';
import {logd, logi, logw} from './logging';
import {logObject} from '../utils/Utils';
import {KEY_AVAILABLE_FILES, loadValue, saveValue} from './sharedPrefs';
import I18n from 'features/I18n';
import * as RNFS from 'react-native-fs';
import DeviceInfo from 'react-native-device-info';
import {storeFilesAction} from '../flows/app';
import {logoutAttemptAction} from '../flows/login';
import {AuthenticationException} from '../exception/CustomExceptions';
import {showToast} from './platformSpecific';
import {ROOT_DHLLINK} from '../config';
import {loadKioskApps} from '../flows/kiosk';

/*

const sampleStored = {
    lastSync: new Date(),
    isOk: true,
    errorMessage: null,
    isDownloading: false,
    files: [
        {
            id: 1,
            updated: "2020-03-26T01:00:00Z",
            version: 1585184400000,
            display_name: "Velocity Configuration China",
            name: "file.js",
            destination: "/storage/emulated/0",
            configured: true,
            isOk: false,
            errorMessage: "File download failed",
            isDownloading: false
        }
    ],
};
*/
export function* handleCdmFiles(login, userGroupIds) {
  const fetchedMetaDataFiles = yield call(fetchMetaData, login, userGroupIds);
  const storedMetaData = yield call(readLocalMetadata);
  if (!storedMetaData.files) {
    storedMetaData.files = [];
  }
  //logd('Local metadata', storedMetaData);
  const newMetadata = {
    lastSync: new Date(),
    files: storedMetaData.files,
    isOk: true,
    errorMessage: null,
    isDownloading: false,
  };
  if (fetchedMetaDataFiles !== undefined) {
    //logi('Fetched metadata from CDM', fetchedMetaDataFiles);
    const requiredToBeFetched = computeListOfMissingOrObsoleteFiles(fetchedMetaDataFiles, storedMetaData.files);
    logd('Files to be fetched', requiredToBeFetched);
    const downloadedFiles = yield call(fetchFiles, requiredToBeFetched);
    const downloadedFilesWithoutError = downloadedFiles.filter(it => it.isOk);
    if (requiredToBeFetched.length !== downloadedFilesWithoutError.length) {
      logw(`Only ${downloadedFilesWithoutError.length} files were downloaded out of ${requiredToBeFetched.length}`);
    }
    newMetadata.files = mergeListOfLocallyAvailableFiles(storedMetaData.files, downloadedFiles);
    //logd('Including fresh files the new locally available files are', newMetadata.files);
    logi('Trying to delete available files (locally cached) before sync from public area');
    yield call(deletePublicOccurrences, storedMetaData.files);
    logd('So all files need be installed', fetchedMetaDataFiles);
    yield call(installFiles, fetchedMetaDataFiles, newMetadata.files);
    //logd('New local metadata after copying', newMetadata);
    newMetadata.files.forEach(f => (f.configured = indexById(fetchedMetaDataFiles, f) >= 0));
    //logd('New local metadata after marking what is configured (required, even with error)', newMetadata);
  } else {
    // SO-3572 - public files are now not removed on response failure
    newMetadata.isOk = false;
    newMetadata.errorMessage = I18n.t('errors.fileMetaDataFailed');
  }
  yield put(storeFilesAction(newMetadata));
  yield call(writeLocalMetadata, newMetadata);
}

export function* readLocalMetadataAndStoreToRedux() {
  const storedMetaData = yield call(readLocalMetadata);
  yield put(storeFilesAction(storedMetaData));
  logd('Metadata stored to redux', logObject(storedMetaData));
}

function* readLocalMetadata() {
  const stringValue = yield call(loadValue, KEY_AVAILABLE_FILES);
  return stringValue ? JSON.parse(stringValue) : {};
}

function* writeLocalMetadata(theList) {
  yield call(saveValue, KEY_AVAILABLE_FILES, JSON.stringify(theList));
}

function computeListOfMissingOrObsoleteFiles(fetchedMetaData, storedAvailable) {
  const result = [];
  fetchedMetaData.forEach(requestedFile => {
    const localIndex = indexByIdAndVersion(storedAvailable, requestedFile);
    if (localIndex === -1 || storedAvailable[localIndex].isOk === false) {
      result.push(requestedFile);
    }
  });
  return result;
}

function mergeListOfLocallyAvailableFiles(storedAvailable, downloadedFiles) {
  const result = [...storedAvailable];
  downloadedFiles.forEach(downloadedFile => {
    // if downloaded file exists in stored list, update the item. If not, add it
    const existingIndex = indexById(result, downloadedFile);
    if (existingIndex >= 0) {
      result[existingIndex] = downloadedFile;
    } else {
      result.push(downloadedFile);
    }
  });
  return result;
}

function buildFileMetaDataRequest(login, userGroupIds, model, manufacturer) {
  return {
    smartops_user: login,
    device_model: model,
    device_manufacturer: manufacturer,
    user_groups: userGroupIds,
  };
}

function* logoutWithToast() {
  logi('Response 401, expired token, force logout');
  showToast(I18n.t('errors.authExpired'));
  yield put(logoutAttemptAction());
}

function* fetchMetaData(login, userGroupIds) {
  const model = DeviceInfo.getModel();
  const manufacturer = yield call(DeviceInfo.getManufacturer);
  const body = buildFileMetaDataRequest(login, userGroupIds, model, manufacturer);
  const url = `${ROOT_DHLLINK}/opsmgt/v1/initwatch/file-transport`;
  logd('File metadata request body', body);
  try {
    const headers = {'Content-Type': 'application/json'};
    const response = yield call(apiCall, {url, headers, method: 'POST', body: JSON.stringify(body), addStatus: true});
    logd('Fetched file transfer metadata ', logObject(response.body));
    if (response.body.files) {
      return response.body.files.map(f => ({...f, version: new Date(f.updated).getTime()}));
    } else {
      if (response.status === 400 && response.body?.code === 'ERR05') {
        logi('Response 400/ERR05 not reported to user');
      } else if (response.status === 401) {
        logi('401 status error while loading file init data, logging out, response: ', response);
        yield call(logoutWithToast);
      } else {
        logi('Error loading file init data, response: ', response);
        showToast(I18n.t('errors.fileMetaDataFailed'));
      }
    }
  } catch (e) {
    if (e instanceof AuthenticationException) {
      logw('AuthenticationException during loading of file init data, logging out: ', e);
      yield call(logoutWithToast);
    } else {
      logw('Error loading file init data', e);
      showToast(I18n.t('errors.fileMetaDataFailed'));
    }
  }
  return undefined;
}

function createLocalName(file) {
  return `${RNFS.DocumentDirectoryPath}/${file.id}`;
}

function createPublicName(file) {
  const destination = (file.destination || '/').endsWith('/') ? file.destination : file.destination + '/';
  return `${destination}${file.name}`;
}

function* fetchFiles(filesToBeFetched) {
  const allEffects = filesToBeFetched.map(file => call(fetchFile, file));
  return yield all(allEffects);
}

function* fetchFile(fileToBeFetched) {
  const url = `${ROOT_DHLLINK}/opsmgt/v1/file-transport/${fileToBeFetched.id}`;
  let response = {};
  let errorMessage = null;
  try {
    const headers = {};
    response = yield call(apiCall, {url, headers});
    logd(`Fetched file ${fileToBeFetched.id}`, logObject(response));
  } catch (e) {
    if (e instanceof AuthenticationException) {
      yield call(logoutWithToast);
    } else {
      logw('Error loading file data', e);
    }
  }
  if (response.file) {
    // store file
    const fullFileName = createLocalName(fileToBeFetched);
    logi(`Writing file ${fullFileName} with encoded length ${response.file.length}`);
    try {
      yield call(RNFS.writeFile, fullFileName, response.file, 'base64');
      return {...fileToBeFetched, isOk: true, errorMessage};
    } catch (e) {
      showToast(I18n.t('errors.fileWriteFailed', {name: fileToBeFetched.display_name}));
      errorMessage = I18n.t('errors.theFileWriteFailed');
    }
  } else {
    showToast(I18n.t('errors.fileFetchFailed', {name: fileToBeFetched.display_name}));
    errorMessage = I18n.t('errors.theFileFetchFailed');
  }
  return {...fileToBeFetched, isOk: false, errorMessage};
}

function* deletePublicOccurrences(filesToDelete) {
  for (const file of filesToDelete) {
    const fileName = createPublicName(file);
    const exists = yield call(RNFS.exists, fileName);
    if (exists) {
      try {
        yield call(RNFS.unlink, fileName);
        logi(`File ${fileName} deleted`);
      } catch (err) {
        logw(`Error deleting file ${fileName}`, err.message);
      }
    } else {
      logi(`File ${fileName} doesn't exist, so no delete attempt`);
    }
  }
}

function* installFiles(filesToInstall, availableFiles) {
  for (const file of filesToInstall) {
    const availableFileIndex = indexByIdAndVersion(availableFiles, file);
    if (availableFileIndex >= 0 && availableFiles[availableFileIndex].isOk === true) {
      const publicFileName = createPublicName(file);
      const localFileName = createLocalName(file);
      const destination = publicFileName.substr(0, publicFileName.lastIndexOf('/'));
      try {
        logi(`Creating directory: ${destination}`);
        yield call(RNFS.mkdir, destination);
        yield call(RNFS.copyFile, localFileName, publicFileName);
        logi(`File ${localFileName} copied to ${publicFileName}`);
        // Reload kiosk file SO-3656
        if (publicFileName.endsWith('kiosk_config.json')) {
          logi(`Reloading kiosk configuration because of File Transfer download`);
          yield call(loadKioskApps);
        }
      } catch (e) {
        logw(`Error copying file ${localFileName} to ${publicFileName}`, e.message);
        showToast(I18n.t('errors.fileWriteFailed', {name: file.display_name}));
        availableFiles[availableFileIndex].isOk = false;
        availableFiles[availableFileIndex].errorMessage = I18n.t('errors.theFileWriteFailed');
      }
    } else {
      logw(`Skipping copy of file ${file.display_name} as it's not locally available or has fetch error`);
    }
  }
}

export function* checkFile(login, userGroupIds, fileId) {
  const storedMetaData = yield call(readLocalMetadata);
  const currentFiles = storedMetaData.files.filter(f => f.id === fileId);
  if (currentFiles.length !== 1) {
    logw(`Reloaded file with id ${fileId} not found`);
    return;
  }
  const currentFile = currentFiles[0];
  logi('Going to check file', currentFile);
  const model = DeviceInfo.getModel();
  const manufacturer = yield call(DeviceInfo.getManufacturer);
  const body = {
    file_id: fileId,
    file_updated: currentFile.updated,
    smartops_user: login,
    device_model: model,
    device_manufacturer: manufacturer,
    user_groups: userGroupIds,
  };
  const url = `${ROOT_DHLLINK}/opsmgt/v1/file-transport/user-file-check`;
  logd('User file check request body', body);
  let fetchedDetail = null;
  let updatedFile = {};
  try {
    const headers = {'Content-Type': 'application/json'};
    const response = yield call(apiCall, {url, headers, method: 'POST', body: JSON.stringify(body), addStatus: true});
    logd('User file check response ', logObject(response.body));
    if (response.body.id) {
      fetchedDetail = response.body;
    } else {
      logw(`Reloaded file with id ${fileId} empty response/missing id`);
    }
  } catch (e) {
    if (e instanceof AuthenticationException) {
      yield call(logoutWithToast);
    } else {
      logw(`Reloaded file with id ${fileId} fetch exception`, e);
    }
  }
  if (!fetchedDetail) {
    updatedFile = {
      ...currentFile,
      isOk: false,
      errorMessage: I18n.t('errors.theFileFetchFailed'),
      isDownloading: false,
    };
  } else {
    if (!fetchedDetail.configured) {
      updatedFile = null; // to be removed
      logi(`Reloaded file with id ${fileId} not configured, removing`);
      showToast(I18n.t('fileTransport.fileRemoved'));
    } else {
      if (fetchedDetail.outdated) {
        const updated = fetchedDetail.updated || new Date().toISOString();
        updatedFile = {
          id: fileId,
          updated: updated,
          version: new Date(updated).getTime(),
          display_name: fetchedDetail.display_name,
          name: fetchedDetail.name,
          destination: fetchedDetail.destination,
          isOk: true,
          errorMessage: null,
          isDownloading: false,
          configured: true,
        };
        logi(`Reloaded file with id ${fileId} outdated, replacing with response data`, updatedFile);
        const fullFileName = createLocalName(fetchedDetail);
        logd(`Writing file ${fullFileName} with encoded length ${fetchedDetail.file.length}`);
        try {
          yield call(RNFS.writeFile, fullFileName, fetchedDetail.file, 'base64');
        } catch (e) {
          updatedFile.errorMessage = I18n.t('errors.theFileWriteFailed');
          updatedFile.isOk = false;
        }
        if (updatedFile.isOk) {
          // try to delete old version of file from public area and copy new one
          yield call(deletePublicOccurrences, [currentFile]);
          yield call(installFiles, [updatedFile], [updatedFile]);
          logi(`After public copy attempt the file is`, updatedFile);
        }
      } else {
        if (!currentFile.isOk) {
          logi(`Reloaded file with id ${fileId} up to date but had error, downloading content`);
          // SO-2645: file is configured and up to date from metadata point of view, but had error
          // so content probably was not downloaded or could not be written. Download content again
          const fetchedFile = yield call(fetchFile, currentFile);
          if (fetchedFile.isOk) {
            yield call(installFiles, [fetchedFile], [fetchedFile]);
            logi(`After public copy attempt the file is`, fetchedFile);
          }
          updatedFile = {
            ...fetchedFile,
            isDownloading: false,
          };
        } else {
          logi(`Reloaded file with id ${fileId} up to date, no changes`);
          // just try to delete old version of file from public area and copy new one
          // up to date
          updatedFile = {
            ...currentFile,
            isDownloading: false,
          };
          yield call(deletePublicOccurrences, [updatedFile]);
          yield call(installFiles, [updatedFile], [updatedFile]);
          logi(`After public copy attempt the file is`, updatedFile);
        }
      }
    }
  }
  const upToDateStoredMetaData = yield call(readLocalMetadata); // could change in the meantime
  const newFiles = upToDateStoredMetaData.files
    .filter(f => updatedFile !== null || f.id !== fileId)
    .map(f => (f.id === fileId ? updatedFile : f));

  const newMetadata = {...upToDateStoredMetaData, files: newFiles};
  yield put(storeFilesAction(newMetadata));
  yield call(writeLocalMetadata, newMetadata);
}

const indexByMatcher = (list, item, matcher) => {
  if (!list || !list.length || !item) {
    return -1;
  }
  let existingIndex = -1;
  for (let i = 0; i < list.length; i++) {
    if (matcher(list[i], item)) {
      existingIndex = i;
      break;
    }
  }
  return existingIndex;
};

const indexById = (list, item) => indexByMatcher(list, item, (a, b) => a.id === b.id);
const indexByIdAndVersion = (list, item) =>
  indexByMatcher(list, item, (a, b) => a.id === b.id && a.version === b.version);
