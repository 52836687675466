import {call, select} from 'redux-saga/effects';
import {apiCall} from '../../utils/Oauth';
import {logd, logi, logw} from '../logging';
import {saveValue} from '../sharedPrefs';
// eslint-disable-next-line no-unused-vars
import {extractIdsOfGivenTypeFromDl, logObject} from '../../utils/Utils';
import I18n from 'features/I18n';
import {ACTIVE_ENV} from '../../configs/active-env';
import {pickStrongestRoleLabel} from './procconf';
import {getAppSettingsValue} from '../../flows/apps';
import {getSelectedUserGroup} from '../../flows/auth';
import {showToast} from 'features/platformSpecific';

const INIT_DATA_KEY = 'perf_rev';
const GET_PERF_REV_INIT_DATA_URL = `${ACTIVE_ENV.gatewayUrl}/opsmgt/v2/initwatch/perfrev?facility=`;

function* loadInitData(user, roles) {
  logi('Fetching perf_rev init data');
  // extract all data limitation ids from all roles
  const facilityIds = extractIdsOfGivenTypeFromDl(roles, 'Facility');
  const warehouseIds = extractIdsOfGivenTypeFromDl(roles, 'WLM Warehouse');
  const selectedUserGroup = yield select(getSelectedUserGroup);
  const timeoutInSeconds = yield call(getAppSettingsValue, 'perf_rev_init.timeout');
  const timeout = timeoutInSeconds ? timeoutInSeconds * 1000 : 60000;
  const langCode = user.language || 'en';
  const userLogin = user.login;
  const role = pickStrongestRoleLabel(roles);
  if (facilityIds && facilityIds.length && warehouseIds && warehouseIds.length && langCode) {
    let url = `${GET_PERF_REV_INIT_DATA_URL}${facilityIds[0]}&warehouse=${warehouseIds[0]}&user=${userLogin}&role=${role}&language_code=${langCode}`;
    if (selectedUserGroup) {
      url = `${url}&user_group=${selectedUserGroup}`;
    }
    try {
      const headers = {'X-messagename': 'OPSINIT', 'x-direction': 'umv2'};
      const response = yield call(apiCall, {url, headers, timeout: timeout});
      //logd('Fetched perf_rev init data ', logObject(response));
      yield call(saveValue, INIT_DATA_KEY, JSON.stringify(response));
    } catch (e) {
      logw('Error loading perf_rev init data', e);
      showToast(I18n.t('errors.perfRevDataFailed'));
    }
  } else {
    showToast(I18n.t('errors.perfRevDataFailed'));
  }
}

export default {
  label: 'perf_rev', // in UM
  title: 'Performance Review',
  restartKey: 'restart_perf_rev',
  activeTaskKey: 'active_task_perf_rev', // {isActive: true/false,...}
  needInitData: true,
  initDataKey: INIT_DATA_KEY,
  urlPath: GET_PERF_REV_INIT_DATA_URL.substring(GET_PERF_REV_INIT_DATA_URL.indexOf('opsmgt') + 6),
  validationMessageKey: 'validation_perf_rev',
  loadInitData,
};
