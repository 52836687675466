import {ACTIVE_ENV as GRADLE_SELECTED_ENV, RELEASE_DATE} from './configs/active-env';

// config is now handled during gradle build
// for local development, make sure that src/configs/active-env.js has correct data
// but note this file is overwritten with every gradlew run

// start local run even separately:
//  a) yarn start
//  b) gradlew installQaDebug
// or with cli
// react-native run-android --variant installQaDebug

const ACTIVE_ENV = GRADLE_SELECTED_ENV;
export const getReleaseDate = () => RELEASE_DATE;

export const OAUTH_SECRET_USERNAME = ACTIVE_ENV.newClientId;
export const OAUTH_SECRET_PASSWORD = ACTIVE_ENV.newClientSecret;
export const ROOT_DHLLINK = ACTIVE_ENV.newGatewayUrl + '/dhllink';
export const ROOT_SMARTOPS = ACTIVE_ENV.newGatewayUrl + '/smartops';

export const UM_URL_ROOT = ROOT_SMARTOPS + '/um';

export const GQL_UM_USER_URL = `${UM_URL_ROOT}/users/graphql`;
export const GQL_UM_CORE_URL = `${UM_URL_ROOT}/core/graphql`;
export const GQL_UM_PIN_URL = `${UM_URL_ROOT}/password/graphql`;

export const OAUTH_GRANT_TOKEN_URL = `${UM_URL_ROOT}/oauth/token?grant_type=password`;
export const OAUTH_REFRESH_TOKEN_URL = `${UM_URL_ROOT}/oauth/token?grant_type=refresh_token`;
export const OAUTH_REVOKE_TOKEN_URL = `${UM_URL_ROOT}/auth/api/revokeToken`;

export const GET_USER_TRANSLATIONS = `${ROOT_DHLLINK}/opsmgt/v1/initwatch/translation/language/`;
export const GET_BR_FOR_GROUP = `${ROOT_DHLLINK}/opsmgt/v1/smartops-version`;
export const GET_POST_APP_SETTINGS = `${ROOT_DHLLINK}/opsmgt/v1/initwatch/app-configuration`;
export const GET_POST_WMS_SSO = `${ROOT_DHLLINK}/opsmgt/v1/initwatch/wms-sso`;
export const POST_WMS_PASSWORD = `${ROOT_DHLLINK}/opsmgt/v1/wms-sso/wms-password`;
export const POST_WMS_PASSWORD_KEY = `${ROOT_DHLLINK}/opsmgt/v1/wms-sso/wms-key`;
export const POST_LOG_API_URL = `${ROOT_DHLLINK}/opsmgt/v1/smartops-log`;

export const UI_ATTRIBUTE_WEARABLE = '13507';
export const UI_ATTRIBUTE_TABLET = '13508';

export const REQUEST_TIMEOUT = 6000;

export const KIOSK_FOLDER = 'kiosk';

export const TOKEN_REFRESH_FAILED_BROADCAST = 'com.dhl.smartops.queue.service.TOKEN_REFRESH_FAILED';

