import {take, put} from 'redux-saga/effects';
import {navigationPushAction} from 'smartops-shared';

// Actions
export const STORE__SCANNING = 'STORE__SCANNING';
export const STORE__SCANNED_VALUE = 'STORE__SCANNED_VALUE';
export const START__SCANNING = 'START__SCANNING';

const INITIAL_STATE = {
  scanning: true,
  value: '',
};

// Reducer
export default function scannerReducer(state = INITIAL_STATE, action = {}) {
  //logd(`Action: ${action.type}, payload ${JSON.stringify(action.payload)}`);
  switch (action.type) {
    case STORE__SCANNING:
      return {...state, scanning: action.payload};
    case STORE__SCANNED_VALUE:
      return {...state, value: action.payload};
    default:
      return state;
  }
}

// selectors
export const selectScannedValue = state => state.value;

// flows
export function* startScanningWatcher() {
  while (true) {
    yield take(START__SCANNING);
    yield put(storeScannedValue(''));
    yield put(storeScanning(true));
    yield put(navigationPushAction('pageScanner'));
  }
}

// Action Creators
export function storeScannedValue(value) {
  return {type: STORE__SCANNED_VALUE, payload: value};
}

export function storeScanning(value) {
  return {type: STORE__SCANNING, payload: value};
}

export function startScanning() {
  return {type: START__SCANNING};
}
