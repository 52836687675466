import React from 'react';
import {Text, View, ActivityIndicator} from 'react-native';
import AppWidget from './AppWidget';
import {ScaledSheet} from 'react-native-size-matters';
import {useSelector} from 'react-redux';
import {getApplicationNotifications} from '../flows/applicationNotification';
import {ApplicationNotifications} from '../utils/types';

export const CounterWidget = (props: any) => {
  const applicationNotifications: ApplicationNotifications = useSelector(getApplicationNotifications);
  // @ts-ignore
  const notification = applicationNotifications[props.app.key];
  const loading = notification ? notification.loading : false;
  const countOfNotifications = notification && !notification.error ? notification.countOfNotifications : 'N/A';

  return (
    <AppWidget {...props}>
      <View style={[localStyles.container, loading ? null : localStyles.textWrap]}>
        {loading ? (
          <ActivityIndicator size={20} />
        ) : (
          <Text
            style={localStyles.text}
            accessibilityLabel={props.app.key === 'inc_mng' ? 'numberOfIncidents' : 'numberOfIndirectRequests'}>
            {countOfNotifications}
          </Text>
        )}
      </View>
    </AppWidget>
  );
};

const localStyles = ScaledSheet.create({
  container: {
    width: '30@ms0.2',
    height: '30@ms0.2',
    alignSelf: 'flex-start',
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: '1%',
    marginVertical: '5@ms0.2',
  },
  textWrap: {
    backgroundColor: 'red',
    borderRadius: 20,
  },
  text: {
    fontSize: '12@ms0.2',
    color: 'white',
    fontWeight: 'bold',
  },
});
