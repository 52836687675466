export default {
  label: 'kiosk_browser', // in UM
  title: 'Kiosk Browser',
  restartKey: 'restart_kiosk_browser',
  activeTaskKey: 'active_task_kiosk_browser', // {isActive: true/false,...}
  initDataKey: 'dummy',
  urlPath: 'dummy',
  validationMessageKey: 'dummy',
  needInitData: false,
};
