import {uniq} from 'lodash';

export function wait(ms) {
  return new Promise(resolve => {
    setTimeout(() => resolve(), ms);
  });
}

export function logObject(object) {
  let objectStr = 'undefined';
  if (object === null) {
    objectStr = 'null';
  } else if (typeof object === 'object') {
    if (Array.isArray(object)) {
      objectStr = `[${object.length}]`;
    } else {
      objectStr = Object.keys(object)
        .map(item => (Array.isArray(object[item]) ? `${item}[${object[item].length}]` : item))
        .join(', ');
      objectStr = `{${objectStr}}`;
    }
  }
  return objectStr;
}

export const extractIdsOfGivenTypeFromDl = (roles, type) => {
  const ids = roles
    .flatMap(role => role.userDataLimitations || [])
    .filter(dl => dl.type === type)
    .map(dl => dl.objectId);
  return uniq(ids);
};

export function convertUserAppsFromUmFormatToSharedPrefsFormat(userApps) {
  const rolesInFormatThatMakesSense = {}; // added with indApr
  for (const authorization of userApps) {
    if (authorization.role && authorization.role.application) {
      const appLabel = authorization.role.application.label;
      if (!rolesInFormatThatMakesSense[appLabel]) {
        rolesInFormatThatMakesSense[appLabel] = [];
      }
      let roleElement;
      const existingRoleElement = rolesInFormatThatMakesSense[appLabel].filter(
        role => role.label === authorization.role.label,
      );
      if (!existingRoleElement || existingRoleElement.length === 0) {
        const newRoleElement = {name: authorization.role.name, label: authorization.role.label};
        rolesInFormatThatMakesSense[appLabel].push(newRoleElement);
        roleElement = newRoleElement;
      } else {
        roleElement = existingRoleElement[0];
      }
      if (authorization.attributes && authorization.attributes.length) {
        if (!roleElement.userDataLimitations) {
          roleElement.userDataLimitations = [];
        }
        authorization.attributes
          .map(attr => ({
            type: attr.attribute.label,
            objectId: attr.value,
            allValue: attr.allValue,
          }))
          .forEach(attr => roleElement.userDataLimitations.push(attr));
      }
    }
  }
  //logd('User roles', rolesInFormatThatMakesSense);
  // convert to original crazy format with arrays and objects everywhere
  const roles = Object.keys(rolesInFormatThatMakesSense).map(appLabel => {
    const resultObject = {};
    const userApp = rolesInFormatThatMakesSense[appLabel];
    resultObject[appLabel] = [{roles: userApp}];
    return resultObject;
  });
  // logd('Roles in crazy format:', roles);
  // logd('Roles in format that makes sense:', rolesInFormatThatMakesSense);
  return {roles, rolesInFormatThatMakesSense};
}

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const isBrowserLocale24h = () =>
  !new Intl.DateTimeFormat(navigator.language, {hour: 'numeric'}).format(0).match(/AM/);
