import {all, call, put, select, takeLatest} from 'redux-saga/effects';

import {
  ApplicationNotificationAction,
  ApplicationNotifications,
  ApplicationNotificationsState,
  UserApplications,
} from '../utils/types';
import {getAppsDetailsSelector, getAppSettingsValue} from './apps';
import {isNaN, isString} from 'lodash';
import {logd, logw} from '../features/logging';
import {ROOT_DHLLINK, TOKEN_REFRESH_FAILED_BROADCAST} from '../config';
import {apiCall} from '../utils/Oauth';
import {extractIdsOfGivenTypeFromDl} from '../utils/Utils';
import {getUserAppsSelector} from '../features/selectors';
import {getLogicsEmployeeIdSelector, getSelectedUserGroup, getUserLoginSelector} from './auth';
import {KEY_LAST_LOGIN, KEY_SELECTED_USERGROUP_ID, loadValue} from '../features/sharedPrefs';
import appIndApr from '../features/apps/indapr';
import moment from 'moment/moment';
import {getIsAppOnline} from './app';
import {getSettingsSelector, ParentSettings} from './settings';
import {App} from '../types';
import I18n from '../features/I18n';
import {logoutAttemptAction} from './login';
import {isWeb} from '../features/platformSpecific';
import {DeviceEventEmitter} from 'react-native';

// actions
const SET_CACHE_VALIDITY = 'SET_CACHE_VALIDITY';
const SET_NOTIFICATION = 'SET_NOTIFICATION';
const RESET_STATE_VALID_UNTIL = 'RESET_STATE_VALID_UNTIL';
const RELOAD_NOTIFICATIONS = 'RELOAD_NOTIFICATIONS';
const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS';

// constants
const JSON_HEADER = {'Content-Type': 'application/json'};
const DEFAULT_CACHE_VALIDITY_IN_SECONDS = 10;

const DEFAULT_NOTIFICATION_STATE = {
  loading: false,
  error: false,
  active: false,
  countOfNotifications: 0,
};

const DEFAULT_STATE_OF_NOTIFICATIONS: ApplicationNotifications = {
  inc_mng: DEFAULT_NOTIFICATION_STATE,
  indApr: DEFAULT_NOTIFICATION_STATE,
  '306090': DEFAULT_NOTIFICATION_STATE,
};

const initialState: ApplicationNotificationsState = {
  cacheValidityInSeconds: DEFAULT_CACHE_VALIDITY_IN_SECONDS,
  notifications: DEFAULT_STATE_OF_NOTIFICATIONS,
  stateValidUntilAt: undefined,
};

// reducer
export const applicationNotificationReducer = (state = initialState, action: ApplicationNotificationAction) => {
  switch (action.type) {
    case SET_CACHE_VALIDITY:
      return {...state, cacheValidityInSeconds: action.payload.cacheValidityInSeconds};
    case SET_NOTIFICATION:
      const appKey = action.payload.appKey!;
      return {...state, notifications: {...state.notifications, [appKey]: action.payload.notification!}};
    case RESET_STATE_VALID_UNTIL:
      const notFetchFromApiUntilAt = new Date(Date.now() + state.cacheValidityInSeconds * 1000);
      return {...state, stateValidUntilAt: notFetchFromApiUntilAt};
    case CLEAR_NOTIFICATIONS:
      return {...state, notifications: DEFAULT_STATE_OF_NOTIFICATIONS};
    default:
      return state;
  }
};

/// flows
export default function* applicationNotificationFlows() {
  yield takeLatest(RELOAD_NOTIFICATIONS, function* () {
    yield loadApplicationsNotifications(false);
  });
}

/// functions
export function* setStateValidityForApplicationsNotifications() {
  try {
    const cacheValidityFromSettings: string | undefined = yield call(
      getAppSettingsValue,
      'notification_panel.cache_validity',
    );

    const isNumber = cacheValidityFromSettings && !isNaN(cacheValidityFromSettings);
    const cacheValidity: number = isNumber
      ? Number.parseInt(cacheValidityFromSettings, undefined)
      : DEFAULT_CACHE_VALIDITY_IN_SECONDS;
    logd("Setting 'notification_panel.cache_validity' with value: " + cacheValidity);

    yield put(setCacheValidity(cacheValidity));
  } catch (e: any) {
    logw('setStateValidityForApplicationsNotifications failed with exception: ' + e);
  }
}

function isDisplayable(key: string, settings: ParentSettings, appsDetails: App[] | undefined): boolean {
  const isVisibilitySettingOn = settings[key + '.notification_visibility'] === 'true';
  logd(`Key '${key}' isVisibilitySettingInCdmOn: ` + isVisibilitySettingOn);
  const isAppPresentInUserApps = appsDetails ? appsDetails?.filter(it => it.key === key)?.length > 0 : false;
  logd(`Key '${key}' isAppPresentInUserApps: ` + isAppPresentInUserApps);
  return isVisibilitySettingOn && isAppPresentInUserApps;
}

export function* clearApplicationNotifications() {
  yield put(clearNotifications());
}

export function* loadApplicationsNotifications(ignoreCacheValidity: boolean) {
  const stateValidUntilAt: Date | undefined = yield select(getStateValidUntilAt);
  if (!ignoreCacheValidity && stateValidUntilAt && new Date() <= stateValidUntilAt!) {
    logd('Skipping fetching of applications notifications, data are still valid');
    return;
  }

  const isOnline: boolean | undefined = yield select(getIsAppOnline);
  if (true !== isOnline) {
    logd('Skipping fetching of applications notifications, app is not online');
    return;
  }

  logd('Fetching of applications notifications has started');
  const userApps: UserApplications | undefined = yield select(getUserAppsSelector);
  const selectedGroup: string | undefined = yield select(getSelectedUserGroup);
  const userName: string = yield select(getUserLoginSelector);
  const logicsEmployeeId: string | undefined = yield select(getLogicsEmployeeIdSelector);

  /// Load notifications count from different services if enabled
  const settings: ParentSettings = yield select(getSettingsSelector);
  const effectsForFetchingNotifications: Generator<any, void, any>[] = [];
  const appsDetails: App[] | undefined = yield select(getAppsDetailsSelector);

  if (isDisplayable('inc_mng', settings, appsDetails)) {
    logd('set loading inc_mng');
    yield put(setLoading('inc_mng'));
    effectsForFetchingNotifications.push(loadRequestCountIncMng(userName, selectedGroup, userApps));
  }

  if (isDisplayable('indApr', settings, appsDetails)) {
    logd('set loading indApr');
    yield put(setLoading('indApr'));
    effectsForFetchingNotifications.push(loadRequestCountIndirectApproval(userName));
  }

  if (isDisplayable('306090', settings, appsDetails)) {
    logd('set loading 306090');
    yield put(setLoading('306090'));
    effectsForFetchingNotifications.push(loadRequestCountHourlyReviews(logicsEmployeeId));
  }

  yield all(effectsForFetchingNotifications);
  yield put(resetValueNotFetchFromApiUntilAt());
}

// selector
export const getStateValidUntilAt = (state: any): Date | undefined => {
  return state.applicationNotification.stateValidUntilAt;
};

export const getApplicationNotifications = (state: any): ApplicationNotifications => {
  return state.applicationNotification.notifications;
};

export const getCountOfApplicationNotifications = (state: any): number => {
  return getNotificationStates(state)
    .filter(it => !it.loading && it.active)
    .map(it => it.countOfNotifications)
    .reduce((previousValue, currentValue) => previousValue + currentValue, 0);
};

export const areNotificationsLoading = (state: any): boolean => {
  return getNotificationStates(state).filter(it => it.loading && it.active).length > 0;
};

export const areAllNotificationsInactive = (state: any): boolean => {
  const notificationStates = getNotificationStates(state);
  return notificationStates.filter(it => !it.active).length === notificationStates.length;
};

function getNotificationStates(state: any) {
  return [
    state.applicationNotification.notifications.indApr,
    state.applicationNotification.notifications.inc_mng,
    state.applicationNotification.notifications['306090'],
  ];
}

// Action Creators
export const setCacheValidity = (cacheValidity: number) => ({
  type: SET_CACHE_VALIDITY,
  payload: {cacheValidityInSeconds: cacheValidity},
});

export const reloadApplicationsNotifications = () => ({
  type: RELOAD_NOTIFICATIONS,
  payload: {},
});

export const clearNotifications = () => ({
  type: CLEAR_NOTIFICATIONS,
  payload: {},
});

export const setLoading = (appKey: string) => ({
  type: SET_NOTIFICATION,
  payload: {
    appKey: appKey,
    notification: {
      active: true,
      loading: true,
      error: false,
      countOfNotifications: 0,
    },
  },
});

export const loadApplicationsNotificationsAction = () => ({
  type: RELOAD_NOTIFICATIONS,
  payload: {},
});

export const resetValueNotFetchFromApiUntilAt = () => ({
  type: RESET_STATE_VALID_UNTIL,
  payload: {},
});

// Api calls for counts
function* loadRequestCountIncMng(
  login: string,
  selectedUserGroup: string | undefined,
  userApplications: UserApplications | undefined,
) {
  const appKey = 'inc_mng';
  const roles = userApplications ? userApplications.inc_mng : [];
  const facilities: string[] | undefined = roles && extractIdsOfGivenTypeFromDl(roles, 'Facility');
  const facilityId = facilities && facilities.length > 0 ? facilities[0] : undefined;
  const url = `${ROOT_DHLLINK}/opsmgt/${
    `v1/incmng/number-incidents?user=${login}` +
    (isString(facilityId) ? `&facility_id=${facilityId}` : ``) +
    (isString(selectedUserGroup) ? `&user_group=${selectedUserGroup}` : ``)
  }`;

  try {
    // @ts-ignore
    const response = yield call(apiCall, {url, headers: JSON_HEADER, timeout: 20000});
    yield mapResponseToState(response.number_of_overdue_incidents, response, appKey);
  } catch (e: any) {
    mapResponseCatchErr(e, appKey);
  }
}

function* loadRequestCountIndirectApproval(login: string) {
  const appKey = 'indApr';

  // @ts-ignore
  const initData = yield loadValue(appIndApr.initDataKey);
  if (initData) {
    const initObject = JSON.parse(initData);
    // @ts-ignore
    const usergroup = yield loadValue(KEY_SELECTED_USERGROUP_ID);
    // @ts-ignore
    const userAppsString = yield loadValue('userApps');
    let strongestRole: string | null = '';
    if (userAppsString) {
      const userApps = JSON.parse(userAppsString);
      if (userApps && userApps.indApr) {
        strongestRole = pickStrongestRoleLabel(userApps.indApr);
      }
    }

    const configValues = {
      facility_id: initObject.facility_id,
      warehouse_id: initObject.warehouse_id,
      role: strongestRole,
      userGroup: usergroup,
    };

    const url = `${ROOT_DHLLINK}/opsmgt/${
      `v1/wlm/numberindirectrequests/facilities/${configValues.facility_id}/warehouse/${configValues.warehouse_id}/users/${login}/role/${configValues.role}` +
      (configValues.userGroup != null ? `?smartops_user_group=${configValues.userGroup}` : ``)
    }`;

    try {
      // @ts-ignore
      const response = yield call(apiCall, {url, headers: JSON_HEADER, timeout: 20000});
      const countOfNotifications = response.number_of_opened_indirect_requests;
      yield mapResponseToState(countOfNotifications, response, appKey);
    } catch (e: any) {
      yield mapResponseCatchErr(appKey, e);
    }
  } else {
    yield mapResponseToState(0, null, appKey);
  }
}

function* loadRequestCountHourlyReviews(logicsEmployeeId: string | undefined) {
  const appKey = '306090';
  if (logicsEmployeeId) {
    const url = `${ROOT_DHLLINK}/opsmgt/v1/reviews?smartops_user_logics_id=${logicsEmployeeId}`;
    try {
      // @ts-ignore
      const response = yield call(apiCall, {url, headers: JSON_HEADER, timeout: 20000});
      const countOfNotifications = response ? response.filter((r: any) => r.review_status === 0).length : 0;
      yield mapResponseToState(countOfNotifications, response, appKey);
    } catch (e) {
      yield mapResponseCatchErr(appKey, e);
    }
  } else {
    yield mapResponseToState(0, null, appKey);
  }
}

function* mapResponseCatchErr(appKey: string, e: any) {
  logw('Fetching notification failed for app: ' + appKey + ' with err: ' + JSON.stringify(e));
  yield put({
    type: SET_NOTIFICATION,
    payload: {
      appKey: appKey,
      notification: {
        active: true,
        loading: false,
        error: true,
        countOfNotifications: 0,
      },
    },
  });

  if (e && e.error === I18n.t('errors.authExpired')) {
    if (isWeb) {
      yield put(logoutAttemptAction(I18n.t('errors.authExpired')));
    } else {
      DeviceEventEmitter.emit(TOKEN_REFRESH_FAILED_BROADCAST);
    }
  }
}

function pickStrongestRoleLabel(roles: any[]): string | null {
  if (roles && Array.isArray(roles)) {
    const roleNames = roles.map(role => role.label);
    for (const role of ['manager', 'supervisor', 'usergroupmember']) {
      if (roleNames.includes(role)) {
        return role;
      }
    }
  }
  return null;
}

function* mapResponseToState(countOfNotifications: number, response: any, appKey: string) {
  if (countOfNotifications !== undefined) {
    logd('Fetched notifications for: ' + appKey + ', countOfNotifications: ' + countOfNotifications);

    yield put({
      type: SET_NOTIFICATION,
      payload: {
        appKey: appKey,
        notification: {
          active: true,
          loading: false,
          error: false,
          countOfNotifications: countOfNotifications,
        },
      },
    });
  } else {
    logw('Unexpected API response: ' + response + ' for appKey' + appKey + ' ', response);
    yield put({
      type: SET_NOTIFICATION,
      payload: {
        appKey: appKey,
        notification: {
          active: true,
          loading: false,
          error: true,
          countOfNotifications: 0,
        },
      },
    });
  }
}
