import React from 'react';
import {Button} from 'react-native-elements';
import {buttonActiveOpacity, buttonContainer, buttonIconStyle} from './commonStyles';

const AppButton = ({onPress, title, icon, buttonStyle, textStyle, containerViewStyle}) => {
  return (
    <Button
      raised
      activeOpacity={buttonActiveOpacity}
      onPress={onPress}
      title={title}
      icon={Object.assign({style: buttonIconStyle}, icon)}
      buttonStyle={buttonStyle}
      textStyle={textStyle}
      containerViewStyle={[buttonContainer, containerViewStyle]}
    />
  );
};

export {AppButton};
