import {call, select} from 'redux-saga/effects';
import {apiCall} from '../../utils/Oauth';
import {logd, logi, logw} from '../logging';
import {saveValue} from '../sharedPrefs';
// eslint-disable-next-line no-unused-vars
import {extractIdsOfGivenTypeFromDl, logObject} from '../../utils/Utils';
import I18n from 'features/I18n';
import {ACTIVE_ENV} from '../../configs/active-env';
import {getAppSettingsValue} from '../../flows/apps';
import {getSelectedUserGroup} from '../../flows/auth';
import {showToast} from 'features/platformSpecific';

const INIT_DATA_KEY = 'proc_conf';
const GET_PROC_CONF_INIT_DATA_URL = `${ACTIVE_ENV.gatewayUrl}/opsmgt/v2/initwatch/procconf/facility/`;

function* loadInitData(user, roles) {
  logi('Fetching ProcConf init data');
  // extract all data limitation ids from all roles
  const facilityIds = extractIdsOfGivenTypeFromDl(roles, 'Facility');
  const warehouseIds = extractIdsOfGivenTypeFromDl(roles, 'WLM Warehouse');
  const selectedUserGroup = yield select(getSelectedUserGroup);
  const timeoutInSeconds = yield call(getAppSettingsValue, 'proc_conf_init.timeout');
  const timeout = timeoutInSeconds ? timeoutInSeconds * 1000 : 60000;
  const langCode = user.language || 'en';
  const userLogin = user.login;
  const role = pickStrongestRoleLabel(roles);
  if (facilityIds && facilityIds.length && warehouseIds && warehouseIds.length && langCode) {
    let url = `${GET_PROC_CONF_INIT_DATA_URL}${facilityIds[0]}/warehouse/${warehouseIds[0]}?user=${userLogin}&role=${role}&language_code=${langCode}`;
    if (selectedUserGroup) {
      url = `${url}&user_group=${selectedUserGroup}`;
    }
    try {
      const headers = {'X-messagename': 'OPSINIT', 'x-direction': 'umv2'};
      const response = yield call(apiCall, {url, headers, timeout: timeout});
      //logd('Fetched ProcConf init data ', logObject(response));
      yield call(saveValue, INIT_DATA_KEY, JSON.stringify(response));
    } catch (e) {
      logw('Error loading ProcConf init data', e);
      showToast(I18n.t('errors.procConfDataFailed'));
    }
  } else {
    showToast(I18n.t('errors.procConfDataFailed'));
  }
}

export function pickStrongestRoleLabel(roles) {
  if (roles && Array.isArray(roles)) {
    const roleNames = roles.map(role => role.label);
    for (const role of ['manager', 'supervisor', 'user_group_owner','multiplier']) {
      if (roleNames.includes(role)) {
        switch (role) {
          case 'manager':
            return 'Manager';
          case 'supervisor':
            return 'Supervisor';
          case 'user_group_owner':
            return 'User_Group_Owner';
          case 'multiplier':
            return 'Multiplier';
          default:
            return null;
        }
      }
    }
  }
  return null;
}

export default {
  label: 'proc_conf', // in UM
  title: 'Process Confirmations',
  restartKey: 'restart_proc_conf',
  activeTaskKey: 'active_task_proc_conf', // {isActive: true/false,...}
  needInitData: true,
  initDataKey: INIT_DATA_KEY,
  urlPath: GET_PROC_CONF_INIT_DATA_URL.substring(GET_PROC_CONF_INIT_DATA_URL.indexOf('opsmgt') + 6),
  validationMessageKey: 'validation_proc_conf',
  loadInitData,
};
