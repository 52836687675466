import React, {Component} from 'react';
//Screens imports
import LoginScreen from './screens/LoginScreen';
import SplashScreen from './screens/SplashScreen';
import ScannerScreen from './screens/ScannerScreen';
import AboutScreen from './screens/AboutScreen';
import MainPage from './screens/MainPage';
import ChooseAppsPage from './screens/ChooseAppsPage';
import MenuScreen from './screens/MenuScreen';
import SetPasswordScreen from './screens/SetPasswordScreen';
import SettingsScreen from './screens/SettingsScreen';
import WmsSettingsScreen from './screens/WmsSettingsScreen';
import PrivacyNotificationScreen from './screens/PrivacyNotificationScreen';
import SelectUserGroupScreen from './screens/SelectUserGroupScreen';
import UserGroupManagementScreen from './screens/UserGroupManagementScreen';
import AccessDetailsScreen from './screens/AccessDetailsScreen';
import DataWillBeLostScreen from './screens/DataWillBeLostScreen';
import FileTransport from './screens/FileTransport';
import LogScreen from './screens/LogScreen.js';
import {forUnlocked} from 'features/lockUtils';

// Router imports
import {createConnectedRouter, navigationPopAction, navigationPopToAction, NavigationProvider} from 'smartops-shared';
//TODO: dist -> change to correct import
import {PageDefinitionType} from 'smartops-shared/dist/types';
import {
  AppState,
  AppStateStatus,
  BackHandler,
  DeviceEventEmitter,
  Keyboard,
  LogBox,
  NativeModules,
  View,
} from 'react-native';
import {connect, Provider} from 'react-redux';
import store from './store';
import I18n from 'features/I18n';
// @ts-ignore
import {QueueIndicator} from 'react-native-multiprocess-preferences';
import {checkRunningAppsStatusAction} from './flows/apps';
import {appActivatedAction, setIsAppOnline, setKeyboardIsOpenAction} from './flows/app';
import {logoutAttemptAction} from './flows/login';
import {initParentLogging, logd, logi} from 'features/logging';

//Icons fonts
import iconFontFA from 'react-native-vector-icons/Fonts/FontAwesome.ttf';
import iconFontEn from 'react-native-vector-icons/Fonts/Entypo.ttf';
import iconFontMC from 'react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf';
import iconFontFA5ProReg from 'react-native-vector-icons/Fonts/FontAwesome5_Pro_Regular.ttf';
import iconFontFA5ProSolid from 'react-native-vector-icons/Fonts/FontAwesome5_Pro_Solid.ttf';
import iconFontFA5ProLight from 'react-native-vector-icons/Fonts/FontAwesome5_Pro_Light.ttf';
import NetworkConnectionIndicator from './component/NetworkConnectionIndicator';
import {ToastContainer} from 'react-toastify';
import {isDeviceOnline, isWeb} from 'features/platformSpecific';
import {injectStyle} from 'react-toastify/dist/inject-style';
import {KEY_BR_SWITCH_SCREEN, KEY_BUSINESS_RELEASE, KEY_LOGIN, loadValue, saveValue} from 'features/sharedPrefs';
import Metrics from 'features/metrics';
import {TOKEN_REFRESH_FAILED_BROADCAST} from './config';
import {debounce} from 'lodash';
import BottomBanner from './component/BottomBanner';
import {disableNotificationsAction} from './flows/notification';
import ConfiguredTopBar from './component/ConfiguredTopBar';
import ConfiguredBottomBar from './component/ConfiguredBottomBar';
import NotificationsScreen from './screens/NotificationsScreen';

isWeb && injectStyle();

LogBox.ignoreLogs(['EventEmitter.removeListener', 'new NativeEventEmitter']);

// @ts-ignore
const ConnectedRouter = createConnectedRouter(connect);

let appState = 'background';

export const currentScreen = () => store.getState().navigation.stack[0].page;
const isUserLogged = () => !!store?.getState()?.auth?.user?.login;

export const switchBRVersion = async (url: string, currentBR: string): Promise<void> => {
  await saveValue(KEY_BR_SWITCH_SCREEN, currentScreen());
  const targetUrl = url.substring(0, url.lastIndexOf('parent') + 6) + '/' + currentBR;
  logi(`switchBRVersion currentScreen(): ${currentScreen()} targetUrl: `, targetUrl);
  // @ts-ignore
  window.allowRedirect = true;
  window.location.assign(targetUrl);
};

export const switchBRVersionToDefault = async (): Promise<void> => {
  await saveValue(KEY_BR_SWITCH_SCREEN, currentScreen());
  await saveValue(KEY_BUSINESS_RELEASE, null); //Clear the existing value, otherwise handlePageshow will revert us back
  const url = window.location.toString();
  const targetUrl = url.split('/parent')[0] + '/parent';
  logi(`switchBRVersionToDefault targetUrl: `, targetUrl);
  // @ts-ignore
  window.allowRedirect = true;
  window.location.assign(targetUrl);
};

// missing onEnter and onExit props, wrong titles yet
const pages: {[index: string]: PageDefinitionType & any} = {
  splashScreen: {component: SplashScreen, topBar: false, bottomBar: false},
  pageLogin: {component: LoginScreen, topBar: false, bottomBar: false},
  pageScanner: {component: ScannerScreen, topBar: false, bottomBar: false},
  pageMain: {component: MainPage},
  pageChoose: {component: ChooseAppsPage, titleKey: 'sceneTitles.selectApps'},
  aboutScreen: {component: AboutScreen, titleKey: 'sceneTitles.about', showBack: true, hideMenu: true},
  logScreen: {component: LogScreen, titleKey: 'log.title', showBack: true, hideMenu: true},
  menuScreen: {component: MenuScreen, topBar: false},
  setPassScreen: {component: SetPasswordScreen, topBar: false, bottomBar: false},
  pageSettings: {component: SettingsScreen, titleKey: 'menu.settings', hideMenu: true, showBack: true},
  fileTransport: {component: FileTransport, titleKey: 'fileTransport.title', hideMenu: true, showBack: true},
  wmsSettingsScreen: {component: WmsSettingsScreen, titleKey: 'settings.wmsSettings', hideMenu: true, showBack: true},
  privacyNotificationScreen: {component: PrivacyNotificationScreen, hideMenu: true, topBar: false, bottomBar: false},
  selectUserGroupScreen: {
    component: SelectUserGroupScreen,
    titleKey: 'login.selectUserGroup',
    hideMenu: true,
    showBack: true,
  },
  userGroupManagementScreen: {
    component: UserGroupManagementScreen,
    titleKey: 'userGroup.userGroupManagement',
    hideMenu: true,
    showBack: true,
  },
  accessDetailsScreen: {
    component: AccessDetailsScreen,
    titleKey: 'userGroup.accessDetails',
    hideMenu: true,
    showBack: true,
  },
  dataWillBeLostScreen: {
    component: DataWillBeLostScreen,
    titleKey: 'userGroup.dataWillBeLostTitle',
    hideMenu: true,
    showBack: true,
  },
  notificationsScreen: {
    component: NotificationsScreen,
    titleKey: 'notifications.button',
  },
};

interface IProps {
  // must not be empty!
  something: any;
}

// State Types
interface IState {
  NumberOfWaitingMessages: number;
}

export async function setTimeoutForNotification(hideNotificationOn: any) {
  // @ts-ignore
  const diff = hideNotificationOn - new Date();
  setTimeout(function () {
    store.dispatch(disableNotificationsAction());
  }, diff);
}

export default class App extends Component<IProps, IState> {
  private logoutInProgress: boolean;
  private backPressSubscriptions: Set<any>;
  private keyboardDidShowListener?: any;
  private keyboardDidHideListener?: any;

  constructor(props: IProps) {
    super(props);
    // @ts-ignore
    window.allowRedirect = false;
    this.state = {NumberOfWaitingMessages: 0};
    this.backPressSubscriptions = new Set();
    /* When refresh token fails in child app parent is sending com.dhl.smartops.queue.service.TOKEN_REFRESH_FAILED broadcast
                                            and 2 things happen:
                                            - all child applications are closed
                                            - parent is logged out
                                            So logout in parent is running (revoke tokens call) and in the same time app activate event arrives (because active child app was closed)
                                            Then appActivate handler checks if user is logged in (success - token not cleared yet) but during its execution logout
                                            handler also runs and clears token.
                                            To remove this races condition of logout/activate handlers we use following flag logoutInProgress set on the broadcast
                                            and app activate handler is not started at all (because login screen will be displayed at the end anyway)
                                            The flag is cleared on App render with login screen as current scene
                                            */
    this.logoutInProgress = false;
  }

  setNumberOfWaitingMessages = (newNumber: number): void => this.setState({NumberOfWaitingMessages: newNumber});

  debounceLogoutListener = debounce(
    async () => {
      logi('Parent js received refresh fail event, logout user');
      this.logoutInProgress = true;
      await Metrics.trackEvent(Metrics.EventType.AutomaticLogout);
      store.dispatch(logoutAttemptAction(I18n.t('errors.authExpired')));
      await Metrics.storeSessionId(undefined);
    },
    300,
    {leading: true},
  );

  componentDidMount = (): void => {
    initParentLogging().then(() => logd('Logging DB initialized'));
    if (!isWeb) {
      AppState.addEventListener('change', this._handleAppStateChange);
      AppState.addEventListener('memoryWarning', this._handleAppStateMemoryWarning);
      DeviceEventEmitter.removeAllListeners('hardwareBackPress');
      DeviceEventEmitter.addListener('hardwareBackPress', () => {
        // let invokeDefault = true;
        const subscriptions: any[] = [];

        this.backPressSubscriptions.forEach(sub => subscriptions.push(sub));

        for (let i = 0; i < subscriptions.reverse().length; i += 1) {
          if (subscriptions[i]()) {
            // invokeDefault = false;
            break;
          }
        }
      });

      this.keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', this._keyboardDidShow);
      this.keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', this._keyboardDidHide);
      this.backPressSubscriptions.add(this.handleHardwareBack);

      DeviceEventEmitter.addListener(TOKEN_REFRESH_FAILED_BROADCAST, this.debounceLogoutListener);
      DeviceEventEmitter.addListener('com.dhl.smartops.queue.service.USER_LOGOUT', () => {
        logi('Parent js received logout request from user');
        this.logoutInProgress = true;
        store.dispatch(logoutAttemptAction());
      });
      DeviceEventEmitter.addListener('com.dhl.smartops.parent.ONLINE_STATUS_CHANGED', event => {
        logi('Online status changed to ' + event.value);
        store.dispatch(setIsAppOnline(event.value));
      });
    } else {
      const currentUrl = window.location.toString();
      if (currentUrl.endsWith('/logout')) {
        store.dispatch(logoutAttemptAction());
      }
      window.addEventListener('beforeunload', this.handleLeavePage);
      window.addEventListener('pageshow', this.handlePageshow);
    }
    store.dispatch(appActivatedAction());
  };

  /**
   * https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload#example
   * Only show message if the user is logged in. Sometimes it doesn't work immediately after a page reload.
   * @param e
   */
  handleLeavePage = (e: any): void => {
    // @ts-ignore
    logi(`handleLeavePage isUserLogged: ${isUserLogged()} , window?.allowRedirect: ${window?.allowRedirect}`);
    // @ts-ignore
    if (isUserLogged() && !window?.allowRedirect) {
      //e.preventDefault();
      //e.returnValue = '';
    } else {
      delete e['returnValue'];
    }
  };

  handlePageshow = async (): Promise<void> => {
    const url = window.location.toString();
    const login = await loadValue(KEY_LOGIN);
    logi(`handlePageshow url: ${url} login: ${login}`);
    if (url.includes('parent')) {
      const defaultBR = url.substring(url.lastIndexOf('parent') + 7).split('/')[0];
      //if (defaultBR == null || defaultBR == '') defaultBR = '2022.123'; // Use for testing in localhost, because of no redirect to default
      const currentBR = await loadValue(KEY_BUSINESS_RELEASE);
      logi(`handlePageshow currentBR: ${currentBR}, `, typeof currentBR);
      logi(`handlePageshow defaultBR: ${defaultBR}, `, typeof defaultBR);
      if (login != null && currentBR != null && currentBR != '' && defaultBR != currentBR) {
        //This should not happen if we are not logged in!
        logi(`handlePageshow switching BR defaultBR: ${defaultBR} currentBR: ${currentBR}`);
        await switchBRVersion(url, currentBR);
      } else if (
        (currentBR == null || currentBR == 'null') &&
        defaultBR != null &&
        defaultBR != '' &&
        !defaultBR.includes('logout')
      ) {
        await saveValue(KEY_BUSINESS_RELEASE, defaultBR);
        await switchBRVersion(url, defaultBR);
      }
    }
  };

  componentWillUnmount = (): void => {
    if (!isWeb) {
      // @ts-ignore
      this.keyboardDidShowListener.remove();
      // @ts-ignore
      this.keyboardDidHideListener.remove();
    } else {
      window.removeEventListener('beforeunload', this.handleLeavePage);
      window.removeEventListener('pageshow', this.handlePageshow);
    }
  };

  setNavigation = (): never => {
    //this.setState(oldState => ({navigation: newCallback(oldState.navigation)}));
    throw new Error('Do not use navigation setter from props, use redux actions');
  };

  _keyboardDidShow = (): void => {
    //logi('>> Keyboard Shown');
    if (currentScreen() === 'pageMain') {
      logi('>> Keyboard dismissing');
      //Keyboard.dismiss();
      NativeModules.ParentNativeTools.hideKeyboard();
    } else {
      store.dispatch(setKeyboardIsOpenAction(true));
    }
  };

  _keyboardDidHide = (): void => {
    //logi('>> Keyboard Hidden');
    store.dispatch(setKeyboardIsOpenAction(false));
  };

  debouncedEventTracker = debounce(
    () => {
      Metrics.getUserGroupId().then(usergroup_id => {
        Metrics.trackEvent(Metrics.EventType.OpenApp, {
          app_label: 'portal',
          usergroup_id,
        });
      });
    },
    1000,
    {leading: false},
  );

  _handleAppStateChange: (state: AppStateStatus) => void = nextAppState => {
    logi(`parent _handleAppStateChange currentScreen: ${currentScreen()} nextAppState: ${nextAppState}`);
    if (nextAppState === 'background') {
      logi(`App nextAppState === 'background' setIsAppOnline null`);
      store.dispatch(setIsAppOnline(null));
    }
    if (nextAppState === 'active') {
      isDeviceOnline().then((isOnline: boolean) => {
        logi(`App _handleAppStateChange doPing isOnline: `, isOnline);
        store.dispatch(setIsAppOnline(isOnline)); // broadcast is sent only on change
      });
    }
    if (appState.match(/inactive|background/) && nextAppState === 'active' && !this.logoutInProgress) {
      this.debouncedEventTracker(); //SO-5879 must happen even if device is locked
      forUnlocked(() => {
        store.dispatch(appActivatedAction());
        const screen = currentScreen();
        if (['dataWillBeLostScreen', 'selectUserGroupScreen', 'userGroupManagementScreen'].includes(screen)) {
          logi('_handleAppStateChange checkRunningAppsStatusAction');
          store.dispatch(checkRunningAppsStatusAction());
        }
      }, '_handleAppStateChange');
    }
    appState = nextAppState;
  };

  _handleAppStateMemoryWarning: (state: AppStateStatus) => void = res => logi('_handleAppStateMemoryWarning res', res);

  handleHardwareBack = (): void | boolean => {
    if (currentScreen() === 'pageChoose') {
      store.dispatch(navigationPopToAction('pageMain'));
    }
    if (
      [
        'pageMain',
        'setPassScreen',
        'pageLogin',
        'privacyNotificationScreen',
        'selectUserGroupScreen',
        'userGroupManagementScreen',
      ].includes(currentScreen())
    ) {
      return true;
    }
    if (currentScreen() === 'splashScreen') {
      BackHandler.exitApp();
    }
    store.dispatch(navigationPopAction());
  };

  render(): React.ReactNode {
    return (
      <>
        {isWeb && (
          <style type="text/css">{`
        @font-face {
          font-family: 'MaterialCommunityIcons';
          src: url(${iconFontMC}) format('truetype');
        }
        @font-face {
          font-family: 'FontAwesome';
          src: url(${iconFontFA}) format('truetype');
        }
        @font-face {
          font-family: 'Entypo';
          src: url(${iconFontEn}) format('truetype');
        }
        @font-face {
          font-family: 'FontAwesome5_Pro_Regular';
          src: url(${iconFontFA5ProReg}) format('truetype');
        }
        @font-face {
          font-family: 'FontAwesome5_Pro_Solid';
          src: url(${iconFontFA5ProSolid}) format('truetype');
        }
        @font-face {
          font-family: 'FontAwesome5_Pro_Light';
          src: url(${iconFontFA5ProLight}) format('truetype');
        }
        html, body, #root, #root-view, .r-pointerEvents-12vffkv, .r-12vffkv {
          height: 100%;
        }
      `}</style>
        )}
        <Provider store={store}>
          <View style={{flex: 1}} accessibilityLabel={'root_view'} nativeID={'root-view'}>
            <NavigationProvider value={[store.getState().navigation, this.setNavigation]}>
              {/*@ts-ignore */}
              <ConnectedRouter
                pages={pages}
                topBar={ConfiguredTopBar}
                bottomBar={ConfiguredBottomBar}
                pageProps={this.setNumberOfWaitingMessages}
              />
            </NavigationProvider>
            {!isWeb && <QueueIndicator />}
            {!isWeb && <BottomBanner />}
            {<NetworkConnectionIndicator />}
            {isWeb && <ToastContainer containerId={'toast-container'} limit={1} />}
          </View>
        </Provider>
      </>
    );
  }
}
