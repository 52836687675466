import React from 'react';
import {Image, Text, View, useWindowDimensions} from 'react-native';
import {Icon} from 'react-native-elements';
import LinearGradient from 'react-native-linear-gradient';
import {ScaledSheet} from 'react-native-size-matters';
import {dhlBlack, dhlRedButton} from './commonStyles';
import {isWeb} from 'features/platformSpecific';
import AppOpener from 'features/AppOpener';

const AppWidget = props => {
  const app = props.app;
  const isFavoriteApp = !!props.isFavoriteApp;
  const iconColor = app.color || 'black';
  const isChooseAppsPage = props.isChooseAppsPage || false;
  const appNameToDisplay = app.name === 'SmartScanner' ? 'Smart Scanner' : app.name;
  const {scale, width} = useWindowDimensions();
  const {cont} = ScaledSheet.create({
    cont: {
      marginHorizontal: '1%',
      width: getWidth(scale, width),
      height: '80@ms0.2',
      marginVertical: '5@ms0.2',
      backgroundColor: '#FFFFFF',
      elevation: 5,
    },
  });
  return (
    <View style={cont}>
      <AppOpener style={scaledStyle.touchable} onPress={props.onPress} app={app}>
        <LinearGradient colors={['#FFEEAD', '#FFCC00']} style={scaledStyle.linearGradient}>
          {app.image && (
            <Image
              source={typeof app.image === 'string' ? {uri: app.image} : app.image}
              style={scaledStyle.imageStyle}
              resizeMode={'contain'}
            />
          )}
          {app.icon && <Icon iconStyle={scaledStyle.icon} name={app.icon} type={'font-awesome'} color={iconColor} />}
        </LinearGradient>
        {isChooseAppsPage && (
          <View style={scaledStyle.star} accessibilityLabel={app.key + (isFavoriteApp ? '_active' : '_inactive')}>
            {isFavoriteApp ? (
              <Icon iconStyle={scaledStyle.starIcon} name={'star'} type={'font-awesome'} color={dhlRedButton} />
            ) : (
              <Icon iconStyle={scaledStyle.starIcon} name={'star-o'} type={'font-awesome'} color={dhlBlack} />
            )}
          </View>
        )}
        <View style={scaledStyle.textWrapper}>
          <Text style={scaledStyle.appItemBigText}>{appNameToDisplay}</Text>
        </View>
        {props.children}
      </AppOpener>
    </View>
  );
};

function getWidth(scale, width) {
  const length = (isWeb ? 1 : scale) * width;
  const widthPercentage = length < 1081 ? '48%' : length < 1700 ? '23%' : '18%';
  return widthPercentage;
}

const scaledStyle = ScaledSheet.create({
  container2: {
    // Bug with the last widget being bigger the rest
    marginHorizontal: '1%',
    height: '80@ms0.2',
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: '31%',
    maxWidth: '220@ms0.2',
    minWidth: '120@ms0.2',
    marginVertical: '5@ms0.2',
    backgroundColor: '#FFFFFF',
    elevation: 5,
  },
  icon: {
    fontSize: '70@ms0.2',
  },
  star: {
    position: 'absolute',
    right: 0,
    top: 0,
    paddingRight: '4@ms0.2',
    paddingTop: '4@ms0.2',
    maxWidth: '100%',
    elevation: 10,
  },
  starIcon: {
    fontSize: '20@ms0.2',
  },
  imageStyle: {
    width: '30@ms0.2',
    height: '36@ms0.2',
  },
  appItemBigText: {
    fontSize: '14@ms0.2',
    color: dhlBlack,
  },
  linearGradient: {
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 5,
    width: '35%',
    alignSelf: 'stretch',
  },
  touchable: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  textWrapper: {
    flex: 1,
    alignItems: 'flex-start',
  },
});

export default AppWidget;
