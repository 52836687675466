import {call, select} from 'redux-saga/effects';
import {logd, logi} from '../logging';
import {saveValue} from '../sharedPrefs';
import I18n from 'features/I18n';
import {ACTIVE_ENV} from '../../configs/active-env';
import {apiCall} from '../../utils/Oauth';
import {getSelectedUserGroup} from '../../flows/auth';
import {extractIdsOfGivenTypeFromDl} from '../../utils/Utils';
import {showToast} from 'features/platformSpecific';

const INIT_DATA_KEY = 'incident_management';
const GET_INCMNG_INIT_DATA_URL = `${ACTIVE_ENV.gatewayUrl}/opsmgt/v2/initwatch/incmng?user=`;

function* loadInitData(user, roles) {
  logi('Fetching Incident Reporting init data');
  const selectedUserGroup = yield select(getSelectedUserGroup);
  const facilityIds = extractIdsOfGivenTypeFromDl(roles, 'Facility'); //Roles are from user and his group
  if (user.login) {
    try {
      const headers = {'X-messagename': 'OPSINIT', 'x-direction': 'umv2'};
      let url = `${GET_INCMNG_INIT_DATA_URL}${user.login}`;
      if (selectedUserGroup) {
        url = `${url}&user_group=${selectedUserGroup}`;
      }
      if (facilityIds && facilityIds.length) {
        url = `${url}&facility_id=${facilityIds[0]}`;
      }
      const options = {
        url: url,
        headers,
        method: 'GET',
      };
      const response = yield call(apiCall, options);
      //const response = incmng; //mock data
      //logd('Fetched Incident Reporting init data ', JSON.stringify(response, 0, 2));
      //logd('Fetched Incident Reporting init data ', logObject(response));
      yield call(saveValue, INIT_DATA_KEY, JSON.stringify(response));
    } catch (e) {
      showToast(I18n.t('errors.incmngDataFailed'));
      logi('Error loading Incident Reporting init data', e);
    }
  } else {
    showToast(I18n.t('errors.incmngDataFailed'));
  }
}

export default {
  label: 'inc_mng', // in UM
  title: 'Incident Reporting',
  restartKey: 'restart_inc_mng',
  activeTaskKey: 'active_task_inc_mng', // {isActive: true/false,...}
  needInitData: true,
  initDataKey: INIT_DATA_KEY,
  urlPath: GET_INCMNG_INIT_DATA_URL.substring(GET_INCMNG_INIT_DATA_URL.indexOf('opsmgt') + 6),
  validationMessageKey: 'validation_inc_mng',
  loadInitData,
};
