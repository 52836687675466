import React, {useState} from 'react';
import {Text, TouchableOpacity, View, StyleSheet, ActivityIndicator, ScrollView} from 'react-native';
import {connect} from 'react-redux';
import {dhlBlack, styles} from '../component/commonStyles';
import I18n from 'features/I18n';
import {ScaledSheet} from 'react-native-size-matters';
import FontAwesome5Pro from 'react-native-vector-icons/FontAwesome5Pro';
import {dhlBlue, dhlRedButton, doubleClickSafe} from 'smartops-shared';
import ActionModal from '../component/ActionModal';
import {reDownloadFileAction, reDownloadFilesAction} from '../flows/app';
import {formatDateTime} from '../utils/DateTime';
import {isDeviceOnline, showToast} from 'features/platformSpecific';

const SafeTouchableOpacity = doubleClickSafe(TouchableOpacity);

const FileTransport = props => {
  const {filesMetadata, is24HourFormat, androidLocale} = props;
  const [popupShown, setPopupShown] = useState(false);
  const [popupText, setPopupText] = useState('');
  const [titleKey, setTitleKey] = useState('');
  const filesAll = filesMetadata.isDownloading ? [] : filesMetadata.files;
  const files = filesAll ? filesAll.filter(f => f.configured) : [];
  const sortedFiles = [...files].sort((a, b) =>
    (a.display_name || '').toLowerCase().localeCompare((b.display_name || '').toLowerCase()),
  );
  const hasGlobalError = filesMetadata.isOk === false && filesMetadata.isDownloading !== true;
  const lastDownload = filesMetadata.isDownloading
    ? I18n.t('fileTransport.running')
    : filesMetadata.lastSync && formatDateTime(new Date(filesMetadata.lastSync), is24HourFormat, androidLocale);
  const errorMessage = () => showToast(I18n.t('fileTransport.offlineModeError'));
  const anyDownloadRunning = files.reduce((a, c) => a || c.isDownloading, false);
  const downloadStyle = anyDownloadRunning ? {color: 'grey'} : null;
  const onDownloadAll = () => isDeviceOnline().then(isOnline => isOnline ? props.reDownloadFilesAction() : errorMessage());
  const onDownloadFile = id => () => isDeviceOnline().then(isOnline => isOnline ? props.reDownloadFileAction(id) : errorMessage());
  return (
    <View style={[styles.container, style.container]}>
      <View style={{flexDirection: 'row'}}>
        <View style={{flex: 3}}>
          <Text accessibilityLabel="last_global_download" style={style.text}>
            {I18n.t('fileTransport.lastDownload') + ': ' + lastDownload}
          </Text>
        </View>
        {filesMetadata.isDownloading !== true && (
          <SafeTouchableOpacity
            style={{flex: 2, flexDirection: 'column', alignItems: 'center'}}
            disabled={anyDownloadRunning}
            accessibilityLabel="redownload_all"
            onPress={anyDownloadRunning ? null : onDownloadAll}>
            <FontAwesome5Pro name={'redo'} type={'font-awesome5Pro'} style={[style.icon, downloadStyle]} />
            <Text style={[style.text, downloadStyle]}>{I18n.t('fileTransport.redownloadAll')}</Text>
          </SafeTouchableOpacity>
        )}
      </View>
      {hasGlobalError && (
        <Text accessibilityLabel="global_error" style={style.error}>
          {filesMetadata.errorMessage}
        </Text>
      )}
      <ScrollView>
        {sortedFiles.map((item, i) => {
          return (
            <React.Fragment key={item.id}>
              <View style={[{borderBottomColor: 'black', borderBottomWidth: StyleSheet.hairlineWidth}, style.margin]} />
              <View>
                <Text accessibilityLabel={`file_title_${i}`} style={style.itemTitle}>
                  {item.display_name}
                </Text>
                <View style={{flexDirection: 'row', alignItems: 'center'}}>
                  <View style={{flexDirection: 'column', flex: 2}}>
                    <View style={{flexDirection: 'row'}}>
                      <View style={{flex: 1}}>
                        <Text style={style.text}>{I18n.t('fileTransport.status')}</Text>
                      </View>
                      <View style={{flex: 1}}>
                        <Text
                          accessibilityLabel={`status_${i}`}
                          style={[style.text, !item.isOk && {color: dhlRedButton}]}>
                          {item.isOk ? I18n.t('fileTransport.downloadedStored') : item.errorMessage}
                        </Text>
                      </View>
                    </View>
                    <View style={{flexDirection: 'row'}}>
                      <View style={{flex: 1}}>
                        <Text style={style.text}>{I18n.t('fileTransport.cdmTimestamp')}</Text>
                      </View>
                      <View style={{flex: 1}}>
                        <Text accessibilityLabel={`cdm_timestamp_${i}`} style={style.text}>
                          {formatDateTime(new Date(item.updated), is24HourFormat, androidLocale)}
                        </Text>
                      </View>
                    </View>
                    <View style={{flexDirection: 'row'}}>
                      <View style={{flex: 1}}>
                        <Text style={style.text}>{I18n.t('fileTransport.fileName')}</Text>
                      </View>
                      <View style={{flex: 1}}>
                        <Text
                          style={[style.text, {textDecorationLine: 'underline', color: dhlBlue}]}
                          accessibilityLabel={`file_name_${i}`}
                          onPress={() => {
                            setTitleKey('fileTransport.fileName');
                            setPopupText(item.name);
                            setPopupShown(true);
                          }}
                          numberOfLines={1}>
                          {item.name}
                        </Text>
                      </View>
                    </View>
                    <View style={{flexDirection: 'row'}}>
                      <View style={{flex: 1}}>
                        <Text style={style.text}>{I18n.t('fileTransport.destinationPath')}</Text>
                      </View>
                      <View style={{flex: 1}}>
                        <Text
                          style={[style.text, {textDecorationLine: 'underline', color: dhlBlue}]}
                          accessibilityLabel={`destination_path_${i}`}
                          onPress={() => {
                            setTitleKey('fileTransport.destinationPath');
                            setPopupText(item.destination);
                            setPopupShown(true);
                          }}
                          numberOfLines={1}>
                          {item.destination}
                        </Text>
                      </View>
                    </View>
                  </View>

                  {item.isDownloading ? (
                    <View style={{flex: 1}}>
                      <ActivityIndicator size={'large'} />
                    </View>
                  ) : (
                    <SafeTouchableOpacity
                      style={{flex: 1, flexDirection: 'column', alignItems: 'center'}}
                      disabled={anyDownloadRunning}
                      accessibilityLabel={`redownload_${i}`}
                      onPress={anyDownloadRunning ? null : onDownloadFile(item.id)}>
                      <FontAwesome5Pro
                        name={'arrow-to-bottom'}
                        type={'font-awesome5Pro'}
                        style={[style.icon, downloadStyle]}
                      />
                      <Text style={[style.text, downloadStyle]}>{I18n.t('fileTransport.redownload')}</Text>
                    </SafeTouchableOpacity>
                  )}
                </View>
              </View>
            </React.Fragment>
          );
        })}
      </ScrollView>
      <ActionModal
        titleKey={titleKey}
        visible={popupShown}
        message={popupText}
        showCancelButton={true}
        setVisible={bool => setPopupShown(bool)}
        onCancel={() => setPopupShown(false)}
      />
    </View>
  );
};

const style = ScaledSheet.create({
  container: {
    paddingTop: '10@ms0.2',
    paddingLeft: '6@ms0.2',
  },
  margin: {
    marginRight: '10@ms0.2',
    marginTop: '4@ms0.2',
  },
  text: {
    color: dhlBlack,
    fontSize: '15@ms0.2',
  },
  error: {
    color: dhlRedButton,
    fontSize: '16@ms0.2',
  },
  itemTitle: {
    color: dhlBlack,
    fontWeight: 'bold',
    fontSize: '20@ms0.2',
  },
  icon: {
    fontSize: '26@ms0.2',
    color: dhlBlack,
  },
});

function mapStateToProps(state) {
  return {
    filesMetadata: state.app.filesMetadata,
    is24HourFormat: state.app.is24HourFormat,
    androidLocale: state.app.androidLocale,
  };
}

export default connect(mapStateToProps, {reDownloadFileAction, reDownloadFilesAction})(FileTransport);
