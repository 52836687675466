//REST
export const REST_RESPONSE_OK = 'OK';
export const REST_RESPONSE_BAD_REQUEST = 'BAD_REQUEST';
export const REST_RESPONSE_UNAUTHORIZED = 'UNAUTHORIZED';
export const REST_RESPONSE_NOT_FOUND = 'NOT_FOUND';
export const REST_RESPONSE_SERVER_ERROR = 'SERVER_ERROR';
export const REST_RESPONSE_CONNECTION_ERROR = 'CONNECTION_ERROR';
export const REST_RESPONSE_CONFLICT = 'CONFLICT';
export const REST_RESPONSE_PRECONDITION_FAILED = 'PRECONDITION_FAILED';
export const REST_RESPONSE_PRECONDITION_REQUIRED = 'PRECONDITION_REQUIRED';
export const REST_RESPONSE_BAD_RESPONSE = 'BAD_RESPONSE';
