import {call} from 'redux-saga/effects';
import {apiCall} from '../../utils/Oauth';
import {logi, logw} from '../logging';
import {saveValue} from '../sharedPrefs';
// eslint-disable-next-line no-unused-vars
import {extractIdsOfGivenTypeFromDl} from '../../utils/Utils';
import I18n from 'features/I18n';
import {ACTIVE_ENV} from '../../configs/active-env';
import {showToast} from 'features/platformSpecific';

const INIT_DATA_KEY = 'weblinks';
const URL_PATH = '/opsmgt/v1/initwatch/cdm/facilities/';

function* loadInitData(user, roles) {
  logi('Fetching browser init data');
  // extract all data limitation ids from all roles
  const facilityIds = extractIdsOfGivenTypeFromDl(roles, 'Facility');
  //logd('Found browser facilities for user ', facilityIds);
  if (facilityIds && facilityIds.length) {
    const url = `${ACTIVE_ENV.gatewayUrl}${URL_PATH}${facilityIds[0]}/weblinks`;
    try {
      const headers = {'X-messagename': 'OPSINIT', 'x-direction': 'umv2'};
      const response = yield call(apiCall, {url, headers});
      //logd('Fetched browser weblinks ', logObject(response.weblinks));
      yield call(saveValue, INIT_DATA_KEY + '_root', JSON.stringify(response));
      yield call(saveValue, INIT_DATA_KEY, JSON.stringify(response.weblinks));
    } catch (e) {
      logw('Error loading browser init data', e);
      showToast(I18n.t('errors.browserDataFailed'));
    }
  } else {
    showToast(I18n.t('errors.browserDataFailed'));
  }
}

export default {
  label: 'browser', // in UM
  title: 'Browser',
  restartKey: 'restart_browser',
  activeTaskKey: 'active_task_browser', // {isActive: true/false,...}
  needInitData: true,
  initDataKey: INIT_DATA_KEY,
  urlPath: URL_PATH.substring(URL_PATH.indexOf('opsmgt') + 6),
  validationMessageKey: 'validation_browser',
  loadInitData,
};
