import React from 'react';
import {Text, TextInput, TouchableOpacity, View} from 'react-native';
import {dhlBlack} from './commonStyles';
import {loadValue, saveValue} from '../features/sharedPrefs';
import {ScaledSheet} from 'react-native-size-matters';

export const DevTools = () => {
  const [key, onChangeKey] = React.useState('mwlm');
  const [value, onChangeValue] = React.useState('{}');
  const [message, setMessage] = React.useState('');
  const [loadedValue, setLoadedValue] = React.useState('');
  const writeValue = async () => {
    await saveValue(key, value);
    setMessage('Saved');
  };

  const loadValueFromDb = async () => {
    const res = await loadValue(key);
    setLoadedValue(res);
    return res;
  };
  return (
    <View>
      <Text style={styles.text}>Dev tools</Text>
      <Text style={styles.text}>Modify kv value</Text>
      <TextInput style={styles.input} onChangeText={onChangeKey} value={key} />
      <TextInput style={styles.input2} onChangeText={onChangeValue} multiline={true} value={value} />
      <View style={{flexDirection: 'row', justifyContent: 'space-around'}}>
        <TouchableOpacity style={styles.button} onPress={writeValue}>
          <Text>Write</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.button} onPress={loadValueFromDb}>
          <Text>Show value by key</Text>
        </TouchableOpacity>
      </View>
      <Text selectable={true}>{'Loaded value: ' + loadedValue}</Text>
      <Text>{'Message: ' + message}</Text>
    </View>
  );
};

const styles = ScaledSheet.create({
  text: {
    color: dhlBlack,
    fontSize: 16,
  },
  input: {
    height: '48@ms0.2',
    margin: '12@ms0.2',
    borderWidth: '1@ms0.2',
    padding: '10@ms0.2',
  },
  input2: {
    height: '100@ms0.2',
    margin: '12@ms0.2',
    borderWidth: '1@ms0.2',
    padding: '10@ms0.2',
  },
  button: {
    alignItems: 'center',
    backgroundColor: '#DDDDDD',
    padding: '18@ms0.2',
  },
});
