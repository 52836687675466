import {useDispatch, useSelector} from 'react-redux';
import {getCurrentScreenSelector, navigationPopAction, navigationPopToAction, SimpleTopBar} from 'smartops-shared';
import {getSelectedUserGroup, getUserGroups, userGroupSelectedAction} from '../flows/auth';
import I18n from '../features/I18n';
import {Group} from '../types';
import {getEmergencyModeSelector, getFolderSelector} from '../flows/app';
import EmergencyModeTopBar from './EmergencyModeTopBar';
import {KIOSK_FOLDER} from '../config';

const ConfiguredTopBar = (props: any) => {
  const dispatch = useDispatch();
  // current screen label
  const currentScreen = useSelector(getCurrentScreenSelector);
  // current folder
  const folder = useSelector(getFolderSelector);
  // group name
  const selectedGroupId = useSelector(getSelectedUserGroup);
  const allGroups: Group[] = useSelector(getUserGroups);
  const selectedGroupName = allGroups && allGroups.find(g => g.id === selectedGroupId)?.description;
  // page title
  let pageTitle = props.titleKey && I18n.t(props.titleKey);
  if (!pageTitle && currentScreen === 'aboutScreen' && folder === KIOSK_FOLDER) {
    pageTitle = I18n.t('about.kiosk');
  } else if (folder === KIOSK_FOLDER) {
    pageTitle = I18n.t('sceneTitles.kiosk');
  }
  // back button
  const showBack = props.showBack === true;
  const onBackButtonPressed = () => {
    if (currentScreen === 'selectUserGroupScreen') {
      dispatch(navigationPopAction());
      dispatch(userGroupSelectedAction());
    } else if (currentScreen === 'userGroupManagementScreen') {
      dispatch(navigationPopToAction('menuScreen'));
    } else {
      dispatch(navigationPopAction());
    }
  };
  // emergency mode
  const isEmergencyMode = useSelector(getEmergencyModeSelector);
  return isEmergencyMode && currentScreen === 'pageMain' ? (
    <EmergencyModeTopBar pageTitle={pageTitle} showEmergencyModeText={currentScreen === 'pageMain'} />
  ) : (
    <SimpleTopBar
      groupName={selectedGroupName}
      pageTitle={pageTitle}
      back={showBack}
      onBackButtonPressed={onBackButtonPressed}
    />
  );
};

export default ConfiguredTopBar;
