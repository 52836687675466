import {KEY_LOCKSCREEN_LOCKED, KEY_ACCESS_TOKEN, loadValue} from './sharedPrefs';
import {startLockScreenPlain} from '../flows/login';

export const forUnlocked = (resolve, source = 'forUnlocked') => {
  isLocked().then(locked => {
    if (locked) {
      startLockScreenPlain(source);
    } else {
      resolve();
    }
  });
};

const isLocked = async () => {
  const at = await loadValue(KEY_ACCESS_TOKEN);
  if (at) {
    const lockedString = await loadValue(KEY_LOCKSCREEN_LOCKED);
    if (lockedString === 'true') {
      return true;
    }
  }
  return false;
};
