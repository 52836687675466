import {call} from 'redux-saga/effects';
import {apiCall} from '../../utils/Oauth';
import {logd, logi, logw} from '../logging';
import {saveValue} from '../sharedPrefs';
// eslint-disable-next-line no-unused-vars
import {extractIdsOfGivenTypeFromDl} from '../../utils/Utils';
import I18n from 'features/I18n';
import {ACTIVE_ENV} from '../../configs/active-env';
import {showToast} from 'features/platformSpecific';

const INIT_DATA_KEY = 'tdm_kpi';
const GET_TDM_KPI_INIT_DATA_URL = `${ACTIVE_ENV.gatewayUrl}/opsmgt/v1/initwatch/logics/facilities/`;

function* loadInitData(user, roles) {
  logi('Fetching TdmKpi init data');
  // extract all data limitation ids from all roles
  const facilityIds = extractIdsOfGivenTypeFromDl(roles, 'Facility');
  const langCode = user.language || 'en';
  if (facilityIds && facilityIds.length && langCode) {
    const url = `${GET_TDM_KPI_INIT_DATA_URL}${facilityIds[0]}/tdmkpi?language_code=${langCode}`;
    try {
      const headers = {'X-messagename': 'OPSINIT', 'x-direction': 'umv2'};
      const response = yield call(apiCall, {url, headers, timeout: 60000});
      //logd('Fetched tdm_kpi init data ', logObject(response));
      yield call(saveValue, INIT_DATA_KEY, JSON.stringify(response));
    } catch (e) {
      logw('Error loading tdm_kpi init data', e);
      showToast(I18n.t('errors.tdmKpiDataFailed'));
    }
  } else {
    showToast(I18n.t('errors.tdmKpiDataFailed'));
  }
}

export default {
  label: 'tdm_kpi', // in UM
  title: 'Tdm & KPI',
  restartKey: 'restart_tdmKpi',
  activeTaskKey: 'active_task_tdmKpi', // {isActive: true/false,...}
  needInitData: true,
  initDataKey: INIT_DATA_KEY,
  urlPath: GET_TDM_KPI_INIT_DATA_URL.substring(GET_TDM_KPI_INIT_DATA_URL.indexOf('opsmgt') + 6),
  validationMessageKey: 'validation_tdmKpi',
  loadInitData,
};
