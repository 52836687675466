export const WSCallException = function WSCallException(error, message, statusCode = undefined) {
  this.error = error;
  this.message = message;
  this.statusCode = statusCode;
};

export const AuthenticationException = function AuthenticationException(error, message, statusCode = undefined) {
  this.error = error;
  this.message = message;
  this.statusCode = statusCode;
};

export const ConcurrentException = function ConcurrentException(error, message, statusCode = undefined) {
  this.error = error;
  this.message = message;
  this.statusCode = statusCode;
};
