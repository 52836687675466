import React, {useState} from 'react';
import {Text, TouchableOpacity, View, ScrollView, StyleSheet, ActivityIndicator} from 'react-native';
import {connect} from 'react-redux';
import {dhlBlack, styles} from '../component/commonStyles';
import I18n from 'features/I18n';
import {ScaledSheet} from 'react-native-size-matters';
import FontAwesome5Pro from 'react-native-vector-icons/FontAwesome5Pro';
import {dhlRedButton, doubleClickSafe, navigationPushAction} from 'smartops-shared';
import {getFlex} from 'features/platformSpecific';
import {checkRunningAppsStatusAction} from '../flows/apps';
import {logi} from 'features/logging';
import {formatDateTime} from '../utils/DateTime';
import ActionModal from '../component/ActionModal';

const SafeTouchableOpacity = doubleClickSafe(TouchableOpacity);

const GroupRow = props => {
  const [showUmChangeNotification, setShowUmChangeNotification] = useState(false);
  const {
    index,
    isActive,
    isDefault,
    name,
    disableActionIcons,
    id,
    checkRunningApps = false,
    navigationPushAction,
    checkRunningAppsStatusAction,
    notificationFields,
    is24HourFormat,
    androidLocale,
  } = props;
  const {umChangeNotificationMessageFromDate, umChangeNotificationMessageToDate, umChangeDisplayNotification} =
    notificationFields || {};
  if (!umChangeDisplayNotification && showUmChangeNotification) {
    setShowUmChangeNotification(false);
  }
  const fromDateStr = umChangeNotificationMessageFromDate;
  const toDateStr = umChangeNotificationMessageToDate;
  const fromDate = umChangeDisplayNotification
    ? formatDateTime(new Date(fromDateStr), is24HourFormat, androidLocale)
    : '';
  const toDate = umChangeDisplayNotification ? formatDateTime(new Date(toDateStr), is24HourFormat, androidLocale) : '';
  return (
    <View style={!isActive && {paddingBottom: 20}}>
      <ActionModal
        titleKey={'notification.umChangeTitle'}
        visible={showUmChangeNotification}
        container={styles.umChangeModal}
        message={I18n.t('notification.umChangeMessage', {from: fromDate, to: toDate})}
        showCancelButton={true}
        cancelButtonKey={'common.ok'}
        setVisible={() => setShowUmChangeNotification(false)}
      />
      <View style={{flex: 2, justifyContent: 'center', alignItems: 'flex-start'}}>
        <Text style={[style.text, {fontWeight: 'bold'}]}>
          {isActive ? I18n.t('userGroup.activeGroup') : isDefault ? I18n.t('userGroup.defaultGroup') : ''}
        </Text>
      </View>
      <View style={[{flexDirection: 'row'}, isActive && style.activeGroupRow]}>
        <View style={{flex: 3, alignItems: 'flex-start', justifyContent: 'center'}}>
          <Text style={[style.text, {textAlign: 'left'}]}>{name || I18n.t('userGroup.noGroupSelected')}</Text>
        </View>
        <View style={getFlex(1)}>
          {isActive && (
            <SafeTouchableOpacity
              style={{...getFlex(1), flexDirection: 'column', alignItems: 'center'}}
              accessibilityLabel={`access_details_${index}`}
              onPress={() => navigationPushAction('accessDetailsScreen', {userGroupId: id})}>
              <FontAwesome5Pro name={'universal-access'} type={'font-awesome5Pro'} style={style.icon} />
              <Text style={[style.text]}>{I18n.t('userGroup.accessDetails')}</Text>
            </SafeTouchableOpacity>
          )}
        </View>
        <View style={getFlex(1.4)}>
          <SafeTouchableOpacity
            style={{...getFlex(1), flexDirection: 'column', alignItems: 'center'}}
            disabled={disableActionIcons}
            accessibilityLabel={`access_details_${index}`}
            onPress={() => {
              if (umChangeDisplayNotification) {
                setShowUmChangeNotification(true);
              } else {
                if (checkRunningApps) {
                  checkRunningAppsStatusAction();
                }
                navigationPushAction('selectUserGroupScreen', {
                  selectedGroupId: id,
                  selectActive: isActive,
                  checkRunningApps: checkRunningApps,
                });
              }
            }}>
            <FontAwesome5Pro
              name={isActive ? 'users-cog' : 'users'}
              type={'font-awesome5Pro'}
              style={[style.icon, disableActionIcons && style.disableIcon]}
            />
            <Text style={[style.text]}>
              {I18n.t(isActive ? 'userGroup.setActiveGroup' : 'userGroup.setDefaultGroup')}
            </Text>
          </SafeTouchableOpacity>
        </View>
      </View>
    </View>
  );
};

const UserGroupManagementScreen = props => {
  const {
    userGroups,
    selectedUserGroup,
    groupUpdateInProgress,
    navigationPushAction,
    checkRunningAppsStatusAction,
    notificationFields,
    is24HourFormat,
    androidLocale,
  } = props;
  const activeGroup = userGroups && selectedUserGroup && userGroups.find(g => g.id === selectedUserGroup);
  const defaultGroup = userGroups && userGroups.find(g => g.isDefault);
  const disableActionIcons = userGroups?.length < 2;
  const data = [
    {
      isActive: true,
      isDefault: false,
      name: activeGroup?.description,
      id: activeGroup?.id,
      checkRunningApps: true,
    },
    {
      isActive: false,
      isDefault: true,
      name: defaultGroup?.description,
      id: defaultGroup?.id,
    },
  ];
  return (
    <View style={[styles.container, style.container]}>
      {groupUpdateInProgress ? (
        <View style={{flex: 1, justifyContent: 'center'}}>
          <ActivityIndicator size={'large'} />
        </View>
      ) : (
        <ScrollView>
          {GroupRow({index: 1, disableActionIcons, navigationPushAction, checkRunningAppsStatusAction, ...data[0]})}
          {GroupRow({
            index: 2,
            disableActionIcons,
            navigationPushAction,
            notificationFields,
            is24HourFormat,
            androidLocale,
            ...data[1],
          })}
        </ScrollView>
      )}
    </View>
  );
};

const style = ScaledSheet.create({
  container: {
    paddingTop: '12@ms0.2',
    paddingHorizontal: '10@ms0.2',
  },
  margin: {
    marginRight: '10@ms0.2',
    marginTop: '4@ms0.2',
  },
  activeGroupRow: {
    borderBottomColor: dhlBlack,
    borderBottomWidth: StyleSheet.hairlineWidth,
    marginBottom: '12@ms0.2',
    paddingBottom: '12@ms0.2',
  },
  text: {
    color: dhlBlack,
    fontSize: '15@ms0.2',
    textAlign: 'center',
  },
  error: {
    color: dhlRedButton,
    fontSize: '16@ms0.2',
  },
  itemTitle: {
    color: dhlBlack,
    fontWeight: 'bold',
    fontSize: '20@ms0.2',
  },
  icon: {
    fontSize: '34@ms0.2',
    color: dhlBlack,
  },
  disableIcon: {
    color: 'grey',
  },
  umChangeModal: {
    minHeight: '280@ms0.2',
  },
});

function mapStateToProps(state) {
  return {
    notificationFields: state.notification,
    is24HourFormat: state.app.is24HourFormat,
    androidLocale: state.app.androidLocale,
    userGroups: state.auth.userGroups,
    selectedUserGroup: state.auth.selectedUserGroup,
    groupUpdateInProgress: state.auth.groupUpdateInProgress,
  };
}

export default connect(mapStateToProps, {navigationPushAction, checkRunningAppsStatusAction})(UserGroupManagementScreen);
