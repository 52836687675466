import {call, select} from 'redux-saga/effects';
import {apiCall} from '../../utils/Oauth';
import {logd, logw} from '../logging';
import {saveValue} from '../sharedPrefs';
// eslint-disable-next-line no-unused-vars
import I18n from 'features/I18n';
import {ACTIVE_ENV} from '../../configs/active-env';
import {getAppSettingsValue} from '../../flows/apps';
import {getSelectedUserGroup} from '../../flows/auth';
import {showToast} from 'features/platformSpecific';
import {extractIdsOfGivenTypeFromDl} from '../../utils/Utils';

const INIT_DATA_KEY = '306090';
const WAREHOUSE_KEY = '306090_warehouse';
const FACILITY_KEY = '306090_facility';
const WLM_USER_KEY = '306090_wlm_user';
const GET_REVIEW_30_INIT_DATA_URL = `${ACTIVE_ENV.gatewayUrl}/opsmgt/v1/init/rev?username=`;

function* loadInitData(user, roles) {
  logd('Fetching review_306090 init data');
  const facilityIds = extractIdsOfGivenTypeFromDl(roles, 'Facility');
  const warehouseIds = extractIdsOfGivenTypeFromDl(roles, 'WLM Warehouse');
  const wlmUser = extractIdsOfGivenTypeFromDl(roles, 'WLM User');
  const selectedUserGroup = yield select(getSelectedUserGroup);
  const timeoutInSeconds = yield call(getAppSettingsValue, 'review_306090_init.timeout');
  const timeout = timeoutInSeconds ? timeoutInSeconds * 1000 : 60000;
  const langCode = user.language || 'en';
  const userLogin = user.login;
  const role = roles && Array.isArray(roles) && roles[0]?.label;
  if (facilityIds && facilityIds.length && langCode) {
    let url = `${GET_REVIEW_30_INIT_DATA_URL}${userLogin}&role=${role}&language_code=${langCode}`;
    if (selectedUserGroup) {
      url = `${url}&user_group=${selectedUserGroup}`;
    }
    try {
      const headers = {};
      const response = yield call(apiCall, {url, headers, timeout: timeout});
      yield call(saveValue, FACILITY_KEY, facilityIds[0]);
      if (warehouseIds && warehouseIds.length) {
        yield call(saveValue, WAREHOUSE_KEY, warehouseIds[0]);
      }
      yield call(saveValue, WLM_USER_KEY, Array.isArray(wlmUser) && wlmUser.length ? wlmUser[0] : "false");
      yield call(saveValue, INIT_DATA_KEY, JSON.stringify(response));
    } catch (e) {
      logw('Error loading review_306090 init data', e);
      showToast(I18n.t('errors.review306090DataFailed'));
    }
  } else {
      showToast(I18n.t('errors.review306090DataFailed'));
  }
}

export default {
  label: '306090', // in UM
  title: '306090',
  restartKey: 'restart_306090',
  activeTaskKey: 'active_task_306090', // {isActive: true/false,...}
  needInitData: true,
  initDataKey: INIT_DATA_KEY,
  urlPath: GET_REVIEW_30_INIT_DATA_URL.substring(GET_REVIEW_30_INIT_DATA_URL.indexOf('opsmgt') + 6),
  validationMessageKey: 'validation_306090',
  loadInitData,
};
