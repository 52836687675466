export interface WmsSsoFileToShareI {
  identity_providers: IdentityProviderI[];
  path?: string; //To be added after processing for faster access
}

export interface IdentityProviderI {
  name: string; // It should always be WMS
  identities: IdentityI[];
}

interface IdentityI {
  username: string;
  hostname?: string;
  port?: number;
  path?: string; // If empty, it will be ignored
  terminal_id?: string;
}

export function splitToMultipleFilesAccordingToTheirPaths(fileToShare: WmsSsoFileToShareI): WmsSsoFileToShareI[] {
  const res: WmsSsoFileToShareI[] = [];
  const uniquePathsHolder: string[] = [];
  fileToShare.identity_providers.forEach((ip: IdentityProviderI) => {
    ip.identities.forEach((id: IdentityI) => {
      if (id.path != null && !uniquePathsHolder.includes(id.path)) {
        uniquePathsHolder.push(id.path);
        res.push({
          identity_providers: [
            {
              name: ip.name,
              identities: [id],
            },
          ],
          path: id.path,
        });
      } else if (id.path != null) {
        const file: WmsSsoFileToShareI | undefined = res.find(file => file?.path === id.path);
        if (!!file) {
          file.identity_providers[0].identities.push(id);
        }
      }
    });
  });
  return res;
}

export async function openLiteApp(appKey: string, webPaths: string[]): Promise<void> {
  const pathKey: string = appKey.toLowerCase() + '_lite_path';
  // @ts-ignore
  const pathToAppend = webPaths[pathKey];
  const currentUrl = window.location.toString();
  const targetUrl = currentUrl.replace('parent', pathToAppend);
  // @ts-ignore
  window.allowRedirect = true;
  window.location.assign(targetUrl);
}
