import React, {useEffect} from 'react';
import {Text, Image, Dimensions, useWindowDimensions} from 'react-native';
import {dhlBasicOrange, dhlRedButton, dhlBlack, dhlLightOrange} from '../component/commonStyles';
import LinearGradient from 'react-native-linear-gradient';
import {ScaledSheet} from 'react-native-size-matters';
import {storeIsTabletDetectedAction} from '../flows/app';
import {connect} from 'react-redux';
import dhlLogo from '../static/img/DHLlogo.png';

function isTabletBasedOnRatio({height, width}) {
  if (height <= 480 || width <= 1000) {
    return false;
  }
  const ratio = height / width;
  return ratio <= 1.6;
}

const SplashScreen = props => {
  useEffect(() => {
    const isTablet = isTabletBasedOnRatio(Dimensions.get('window'));
    //logd(`SplashScreen useEffect isTablet: ${isTablet}`);
    props.storeIsTabletDetectedAction(isTablet);
  }, [props]);
  const {height} = useWindowDimensions();
  return (
    <LinearGradient
      colors={[dhlLightOrange, dhlBasicOrange, dhlLightOrange]}
      style={[splashStyles.mainContainer, {flexBasis: height}]}>
      <Image source={dhlLogo} style={splashStyles.imageStyle} />
      <Text style={splashStyles.textStyle}>
        <Text style={{color: dhlRedButton}}>SMART</Text>
        OPERATIONS
      </Text>
    </LinearGradient>
  );
};

export const splashStyles = ScaledSheet.create({
  mainContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  imageStyle: {
    width: '92@ms0.2',
    height: '28@ms0.2',
  },
  textStyle: {
    fontSize: '26@ms0.2',
    color: dhlBlack,
    fontWeight: 'bold',
    fontStyle: 'italic',
  },
});

export default connect(null, {storeIsTabletDetectedAction})(SplashScreen);
