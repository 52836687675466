import React, {Component} from 'react';
import {View, Text, TouchableOpacity, TouchableWithoutFeedback, ScrollView} from 'react-native';
import {connect} from 'react-redux';
import {dhlBlack, dhlBlue, dhlWhiteBasic, styles} from '../component/commonStyles';
import {ScaledSheet} from 'react-native-size-matters';
import OneOfSelect from '../component/OneOfSelect';
import I18n from 'features/I18n';
import {setIsTabletAction} from '../flows/app';
import {dhlRedButton, doubleClickSafe, navigationPushAction} from 'smartops-shared';
import FontAwesome5Pro from 'react-native-vector-icons/FontAwesome5Pro';
import {isWeb} from 'features/platformSpecific';
import {DevTools} from '../component/DevTools';

const selectorOptions = ['settings.tablet', 'settings.wearable'];
const SafeTouchableOpacity = doubleClickSafe(TouchableOpacity);

class SettingsScreen extends Component {
  state = {hiddenClickCount: 0};

  isTabletSelect = item => this.props.setIsTabletAction(item === 'settings.tablet');

  render() {
    const {filesMetadata, wmsData, wmsMetadata, isTablet, appsDetails} = this.props;
    const {
      smartConnectionDetailsFailed,
      smartConnectionsNumber,
      smartAccountsNumber,
      wmsConnectionDetailsFailed,
      wmsConnectionNumber,
      wmsAccountsNumber,
      smartError,
      wmsError,
      smartConnectionAssigned,
      wmsConnectionAssigned,
    } = wmsMetadata;
    const filesAll = filesMetadata ? filesMetadata.files : undefined;
    const files = filesAll ? filesAll.filter(f => f.configured) : undefined;
    const filesTotal = files ? files.length : '?';
    const filesFailed = files ? files.filter(it => it.isOk === false).length : 0;
    const renderFailedFiles = filesFailed > 0;
    const wmsDataNotAvailable = wmsData == null;
    const wmsDataEmptyNoFailure = wmsData && Array.isArray(wmsData) && wmsData.length === 0;
    const wmsDataEmpty = wmsData && !Array.isArray(wmsData) && Object.keys(wmsData).length === 0;
    const isSmartScanAssigned = appsDetails.includes('smart_scan');
    const isWmsScanAssigned = appsDetails.includes('velocity_scan');
    const onHiddenClick = () => {
      this.setState(prev => ({hiddenClickCount: prev.hiddenClickCount + 1}));
    };
    return (
      <ScrollView style={[styles.container, style.container]} contentContainerStyle={{flexGrow: 1}}>
        <TouchableWithoutFeedback onPress={onHiddenClick}>
          <Text style={style.label}>{I18n.t('settings.layoutPreference')}</Text>
        </TouchableWithoutFeedback>
        <OneOfSelect
          onPress={this.isTabletSelect}
          buttonsStyle={style.buttonsStyle}
          options={selectorOptions}
          indexOfSelected={selectorOptions.indexOf(isTablet ? 'settings.tablet' : 'settings.wearable')}
        />

        {!isWeb && (
          <View>
            <Text style={[style.label, {marginTop: 10}]}>{I18n.t('settings.fileTransport')}</Text>
            <View style={{flexDirection: 'row'}}>
              <View
                style={{
                  flexDirection: 'column',
                  justifyContent: 'center',
                  flex: 3,
                }}>
                <Text style={[style.text, {marginTop: 4}]}>{`${filesTotal} ${I18n.t('settings.totalFiles')}`}</Text>
                {renderFailedFiles && (
                  <Text
                    style={[
                      style.text,
                      {
                        marginTop: 2,
                        color: dhlRedButton,
                      },
                    ]}>{`${filesFailed} ${I18n.t('settings.failures')}`}</Text>
                )}
              </View>
              <SafeTouchableOpacity
                style={{flexDirection: 'column', flex: 1.4, alignItems: 'center'}}
                accessibilityLabel="file_details"
                onPress={() => {
                  this.props.navigationPushAction('fileTransport', {back: true});
                }}>
                <FontAwesome5Pro name={'file-search'} type={'font-awesome5Pro'} style={style.icon} />
                <Text style={[style.simpleText, {marginTop: 2, textAlign: 'center'}]}>
                  {I18n.t('settings.fileDetails')}
                </Text>
              </SafeTouchableOpacity>
            </View>

            {!wmsDataNotAvailable && (
              <Text style={[style.label, {marginTop: 10}]}>{I18n.t('settings.wmsSettings')}</Text>
            )}
            {!wmsDataNotAvailable && (
              <View style={{flexDirection: 'row'}}>
                {wmsDataEmpty && (
                  <View
                    style={{
                      flexDirection: 'column',
                      flex: 3,
                      justifyContent: 'center',
                    }}>
                    <Text style={[style.text, {color: dhlRedButton, marginTop: 2}]}>
                      {I18n.t('wmsSettings.wmsAccountLoadingFailed')}
                    </Text>
                  </View>
                )}
                {!wmsDataNotAvailable && !wmsDataEmpty && (
                  <View style={{flexDirection: 'column', flex: 3}}>
                    {smartConnectionAssigned && (
                      <View style={{flexDirection: 'column', justifyContent: 'center'}}>
                        {smartConnectionsNumber > 0 && (
                          <Text style={[style.text, {marginTop: 2}]}>{`${smartConnectionsNumber} ${I18n.t(
                            'wmsSettings.smartScannerConnections',
                          )}`}</Text>
                        )}
                        {smartConnectionsNumber === 0 && smartAccountsNumber > 0 && (
                          <Text style={[style.text, {marginTop: 2}]}>{`${smartAccountsNumber} ${I18n.t(
                            'wmsSettings.smartScannerAccounts',
                          )}`}</Text>
                        )}
                        {smartConnectionsNumber === 0 && smartAccountsNumber === 0 && isSmartScanAssigned && (
                          <Text style={[style.text, {marginTop: 2}]}>
                            {I18n.t('wmsSettings.noSmartAccountAvailable')}
                          </Text>
                        )}
                        {smartConnectionDetailsFailed && (
                          <Text style={style.errorText}>{I18n.t('wmsSettings.connectionsLoadingFailed')}</Text>
                        )}
                        {smartError && <Text style={style.errorText}>{I18n.t(smartError)}</Text>}
                      </View>
                    )}
                    {wmsConnectionAssigned && (
                      <View
                        style={{
                          flexDirection: 'column',
                          justifyContent: 'center',
                          marginTop: 3,
                        }}>
                        {wmsConnectionNumber > 0 && (
                          <Text style={[style.text, {marginTop: 2}]}>{`${wmsConnectionNumber} ${I18n.t(
                            'wmsSettings.wmsScannerConnections',
                          )}`}</Text>
                        )}
                        {wmsConnectionNumber === 0 && wmsAccountsNumber > 0 && (
                          <Text style={[style.text, {marginTop: 2}]}>{`${wmsAccountsNumber} ${I18n.t(
                            'wmsSettings.wmsScannerAccounts',
                          )}`}</Text>
                        )}
                        {wmsConnectionNumber === 0 && wmsAccountsNumber === 0 && isWmsScanAssigned && (
                          <Text style={[style.text, {marginTop: 2}]}>
                            {I18n.t('wmsSettings.noWmsAccountAvailable')}
                          </Text>
                        )}
                        {wmsConnectionDetailsFailed && (
                          <Text style={style.errorText}>{I18n.t('wmsSettings.connectionsLoadingFailed')}</Text>
                        )}
                        {wmsError && <Text style={style.errorText}>{I18n.t(wmsError)}</Text>}
                      </View>
                    )}
                  </View>
                )}
                <SafeTouchableOpacity
                  style={{
                    flexDirection: 'column',
                    flex: 1.4,
                    alignItems: 'center',
                    alignSelf: 'center',
                  }}
                  disabled={wmsDataEmpty || wmsDataNotAvailable || wmsDataEmptyNoFailure}
                  accessibilityLabel="wms_details"
                  onPress={() => this.props.navigationPushAction('wmsSettingsScreen', {back: true})}>
                  <FontAwesome5Pro
                    name={'user-cog'}
                    type={'font-awesome5Pro'}
                    style={
                      !wmsDataEmpty && !wmsDataNotAvailable && !wmsDataEmptyNoFailure ? style.icon : style.disabledIcon
                    }
                  />
                  <Text style={[style.simpleText, {marginTop: 2, textAlign: 'center'}]}>
                    {I18n.t('wmsSettings.wmsDetails')}
                  </Text>
                </SafeTouchableOpacity>
              </View>
            )}
          </View>
        )}
        {this.state.hiddenClickCount > 7 && <DevTools />}
        <View style={{height: 20}} />
      </ScrollView>
    );
  }
}

const style = ScaledSheet.create({
  container: {
    padding: '10@ms0.2',
    paddingRight: 0,
    paddingBottom: '120@ms0.2',
  },
  buttonsStyle: {
    height: '100@ms0.2',
    width: '86%',
    borderRadius: 8,
    backgroundColor: dhlWhiteBasic,
    borderColor: dhlBlue,
  },
  label: {
    color: dhlBlack,
    fontSize: '18@ms0.2',
    fontWeight: 'bold',
    marginLeft: '10@ms0.2',
  },
  text: {
    color: dhlBlack,
    fontSize: '16@ms0.2',
    marginLeft: '10@ms0.2',
  },
  errorText: {
    color: dhlRedButton,
    fontSize: '14@ms0.2',
    marginLeft: '10@ms0.2',
  },
  simpleText: {
    color: dhlBlack,
    fontSize: '16@ms0.2',
  },
  icon: {
    fontSize: '26@ms0.2',
    color: dhlBlack,
  },
  disabledIcon: {
    fontSize: '26@ms0.2',
    color: 'grey',
  },
});

function mapStateToProps(state) {
  return {
    filesMetadata: state.app.filesMetadata,
    appsDetails: state.apps.appsDetails?.map(app => app.key) || [],
    wmsData: state.app.wmsData,
    wmsMetadata: state.app.wmsMetadata || {},
    isTablet: state.app.isTablet,
  };
}

export default connect(mapStateToProps, {setIsTabletAction, navigationPushAction})(SettingsScreen);
