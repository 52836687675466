import React, {useEffect, useState} from 'react';
import I18n from 'features/I18n';
import {connect} from 'react-redux';
import {appActivatedAction, setIsAppOnline} from '../flows/app';
import {isDeviceOnline} from '../features/platformSpecific';
import {getCurrentScreenSelector as getCurrentScreen} from 'smartops-shared';
import {StyleSheet, Text, TouchableOpacity, View} from 'react-native';
import {dhlRedButton} from 'smartops-shared/dist/styles/commonStyles';
import {logd} from '../features/logging';
import store from '../store';

const excludedScreens = ['pageLogin', 'splashScreen', 'privacyNotificationScreen'];

const NetworkConnectionIndicator = (props: any): React.ReactElement => {
  const {currentScreen, isAppOnline} = props;
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const handleIsVisible = () => {
    if (excludedScreens.includes(currentScreen)) {
      setIsVisible(false);
    } else {
      setIsVisible(false === isAppOnline);
    }
  };

  const checkIfOnline = () => {
    isDeviceOnline().then((isOnline: boolean) => {
      logd('checkIfOnline -> setIsAppOnline: ' + isOnline);
      store.dispatch(setIsAppOnline(isOnline));
    });
  };

  useEffect(() => {
    handleIsVisible();
  }, [currentScreen, isAppOnline]);

  return isVisible ? (
    <View style={style.container}>
      <Text style={[style.text]}>{I18n.t('api.connectionError')}</Text>
      <TouchableOpacity onPress={() => checkIfOnline()}>
        <Text style={style.button}>{I18n.t('common.retry')}</Text>
      </TouchableOpacity>
    </View>
  ) : (
    <View></View>
  );
};

function mapStateToProps(state: any) {
  return {
    currentScreen: getCurrentScreen(state),
    isAppOnline: state.app.isAppOnline,
  };
}

const style = StyleSheet.create({
  container: {
    backgroundColor: dhlRedButton,
    flexDirection: 'row',
    position: 'absolute',
    bottom: 60, /// Height of the menu
    width: '100%',
    paddingLeft: '2%',
    paddingTop: 20,
    paddingBottom: 20,
  },
  text: {
    width: '82%',
    color: 'white',
    fontSize: 16,
    flexWrap: 'wrap',
  },
  button: {
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold',
  },
});

export default connect(mapStateToProps, {getCurrentScreen, appActivatedAction})(NetworkConnectionIndicator);
