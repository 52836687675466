import React, {useState} from 'react';
import {View, ScrollView, TouchableOpacity} from 'react-native';
import AppWidget from '../component/AppWidget';
import {Icon} from 'react-native-elements';
import {toggleFavoriteAction} from '../flows/apps';
import {connect} from 'react-redux';
import {styles} from '../component/commonStyles';
import {doubleClickSafe, navigationPushAction} from 'smartops-shared';

const SafeTouchableOpacity = doubleClickSafe(TouchableOpacity);

const ChooseAppsPage = props => {
  const {appsDetails, favoriteApps, setFavoriteApps, navigationPushAction} = props;
  const [favApps, setFavApps] = useState(favoriteApps);
  return (
    <View style={styles.container}>
      <ScrollView>
        <View style={styles.appContainer}>
          {appsDetails?.map(app => {
            return (
              <AppWidget
                key={app.key}
                app={app}
                onPress={() =>
                  setFavApps(favApps.includes(app.key) ? favApps.filter(i => i !== app.key) : [...favApps, app.key])
                }
                isChooseAppsPage={true}
                isFavoriteApp={favApps.includes(app.key)}
              />
            );
          })}
        </View>
      </ScrollView>
      <SafeTouchableOpacity
        style={styles.plusButton}
        onPress={() => {
          setFavoriteApps(favApps);
          navigationPushAction('pageMain');
        }}
        accessibilityLabel={'check_button'}>
        <Icon size={20} name={'check'} color={'white'} type={'font-awesome'} />
      </SafeTouchableOpacity>
    </View>
  );
};

function mapStateToProps(state) {
  return {
    favoriteApps: state.apps.favoriteAppsKeys,
    appsDetails: state.apps.appsDetails,
  };
}

export default connect(mapStateToProps, {setFavoriteApps: toggleFavoriteAction, navigationPushAction})(ChooseAppsPage);
