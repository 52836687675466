import React from 'react';
import {connect} from 'react-redux';
import {View, StyleSheet, ScrollView, Text} from 'react-native';
import {ScaledSheet} from 'react-native-size-matters';
import {styles} from '../component/commonStyles';
import I18n from 'features/I18n';
import {getFlex} from 'features/platformSpecific';
import {dhlBlack} from 'smartops-shared';

const ALL_ATTRIBUTES = 'All attributes';
const getAppNameByKey = (apps, appKey) => apps && apps.find(app => app.key === appKey)?.name;

const attributesView = (attributes, attributeValueNames) => {
  const objMap = {},
    attrsMap = {};
  attributes.forEach(att => {
    if (objMap[att?.attribute?.id] == null) {
      objMap[att?.attribute?.id] = [];
    }
    objMap[att?.attribute?.id].push(att?.value);
    const labelName = I18n.t(`attributeLabels.${att?.attribute?.label}`, {
      defaultValue: att?.attribute?.name || att?.attribute?.label,
    });
    attrsMap[att?.attribute?.id] = {label: labelName, allValue: att?.allValue};
  });
  return Object.keys(objMap).length > 0 ? (
    Object.keys(objMap).map((key, i) => (
      <View key={'attr_' + i} accessibilityLabel={'attr_' + i} style={style.attributeRow}>
        <View style={{...getFlex(1), alignItems: 'flex-start', justifyContent: 'flex-start'}}>
          <Text style={style.text}>{attrsMap[key]?.label || key}</Text>
        </View>
        <View style={getFlex(1.4)} accessibilityLabel={'attrValues_' + i}>
          <Text style={style.text}>
            {objMap[key]
              .map(
                v =>
                  attributeValueNames?.find(attr => attr.attributeId === key)?.choices?.find(c => c.id === v)?.name ||
                  v,
              )
              .join(' | ') ||
              (attrsMap[key]?.allValue && ALL_ATTRIBUTES)}
          </Text>
        </View>
      </View>
    ))
  ) : (
    <></>
  );
};

const AccessDetailsScreen = props => {
  const {apps, userGroups, userGroupId, attributeValueNames} = props;
  const selectedUserGroup = userGroups && userGroups.find(g => g.id === userGroupId);
  const authorizations = selectedUserGroup && selectedUserGroup.authorizations;
  authorizations?.sort((a, b) => {
    if (
      getAppNameByKey(apps, a?.role?.application?.label) == null ||
      getAppNameByKey(apps, b?.role?.application?.label) == null
    ) {
      return a?.role?.application?.label?.toLowerCase().localeCompare(b?.role?.application?.label?.toLowerCase());
    }
    return getAppNameByKey(apps, a?.role?.application?.label)
      .toLowerCase()
      .localeCompare(getAppNameByKey(apps, b?.role?.application?.label).toLowerCase());
  });
  return (
    <View style={[styles.container, style.container]}>
      {authorizations && (
        <ScrollView>
          {authorizations
            .filter(a => getAppNameByKey(apps, a?.role?.application?.label) != null)
            .map((auth, i) => (
              <View
                key={'auth_' + i}
                style={[{flexDirection: 'column'}, i < authorizations.length - 1 && style.divider]}>
                <Text style={style.label}>{getAppNameByKey(apps, auth?.role?.application?.label)}</Text>
                <View
                  accessibilityLabel={'app_attrs'}
                  style={{flexDirection: 'column', ...getFlex(1), alignItems: 'center', width: '100%'}}>
                  {/*First row, always role*/}
                  <View accessibilityLabel={'role_row'} style={style.attributeRow}>
                    <View style={getFlex(1)}>
                      <Text style={style.text}>{I18n.t('userGroup.role')}</Text>
                    </View>
                    <View style={getFlex(1.4)}>
                      <Text style={style.text}>{auth?.role?.name}</Text>
                    </View>
                  </View>
                  {/*The rest of the rows, attributes of the role*/}
                  {attributesView(auth.attributes, attributeValueNames)}
                </View>
              </View>
            ))}
        </ScrollView>
      )}
    </View>
  );
};

const style = ScaledSheet.create({
  container: {
    padding: '10@ms0.2',
  },
  label: {
    color: dhlBlack,
    fontSize: '20@ms0.2',
    fontWeight: 'bold',
  },
  text: {
    color: dhlBlack,
    fontSize: '15@ms0.2',
  },
  appText: {
    color: dhlBlack,
    textAlign: 'center',
    fontSize: '20@ms0.2',
  },
  attributeRow: {
    flexDirection: 'row',
    width: '100%',
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 'auto',
  },
  simpleText: {
    color: dhlBlack,
    fontSize: '12@ms0.2',
  },
  divider: {
    borderBottomColor: 'black',
    borderBottomWidth: StyleSheet.hairlineWidth,
    paddingBottom: '10@ms0.2',
    marginBottom: '10@ms0.2',
    marginTop: '2@ms0.2',
  },
});

function mapStateToProps(state) {
  return {
    apps: state.apps.appsDetails,
    userGroups: state.auth.userGroups,
    attributeValueNames: state.auth.attributeValueNames,
  };
}

export default connect(mapStateToProps, {})(AccessDetailsScreen);
